import axios from 'axios';
const endpoint = process.env.REACT_APP_API;

export class ReporteZ {
	agregarReporteZ(datos) {
		return axios.post(`${endpoint}/agregar_reporte_z.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}
	getReporteZList(IdContribuyente){
		return axios.get(`${endpoint}/get_report_z.php`, { params: {IdContribuyente}})
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		}) 
	}
	deleteReporte({nIdContribuyente, nIdReporte}) {
		return axios.get(`${endpoint}/delete_report_z.php`, { params: {nIdContribuyente, nIdReporte}})
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}
	getReporteZDetails({IdContribuyente, nIdReporte}) {
		return axios.get(`${endpoint}/get_report_z_detallado.php`, { params: {IdContribuyente, nIdReporte}})
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}
	actualizarReporteZ(datos) {
		return axios.patch(`${endpoint}/actualizar_reporte_z.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}
}