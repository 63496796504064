import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { PickList } from "primereact/picklist";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { HttpService } from "../../service/HttpService";
import { getUserData } from "../../service/AuthHelperMethods";
import ShowLoading from "../../components/ShowLoading/ShowLoading";

export const AdmPerfiles = () => {
    let emptyPerfil = {
        id: null,
        nombre: "",
        contribuyente: "",
        sEstatus: "",
    };

    // Contiene todas las opciones principales del menú excepto las de admin
    const [allMenuOptionsSourceValue, setAllMenuOptionsSourceValue] = useState(null);
    const [menuOptionsSelectedTargetValue, setMenuOptionsSelectedTargetValue] = useState([]);
    const [listValue, setListValue] = useState([]);
    
    const [checkboxValue, setCheckboxValue] = useState([]);
    const [checkVisible, setCheckVisible] = useState([]);

    const [httpService] = useState(new HttpService());
    const [userData] = useState(getUserData() || null);
    const [perfiles, setPerfiles] = useState([]);
    const [perfilDialog, setPerfilDialog] = useState(false);
    const [deletePerfilDialog, setDeletePerfilDialog] = useState(false);
    const [deletePerfilesDialog, setDeletePerfilesDialog] = useState(false);
    const [UptEstatusPerfilDialog, setUptEstatusPerfilDialog] = useState(false);
    const [perfil, setPerfil] = useState(emptyPerfil);
    const [selectedPerfiles, setSelectedPerfiles] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [ButtonDisabled, setButtonDisabled] = useState(false);
	const [loadingDialog, setLoadingDialog] = useState(false);

    useEffect(() => {
		setLoadingDialog(true);
        let isSubscribed = true;
        httpService.getGeneralData("perfiles", userData.contribuyente)
		.then((data) => { setPerfiles(data); setLoadingDialog(false);})
		.catch(err => setLoadingDialog(false));

        httpService.getGeneralData("all_menu_options").then((data) => {
            if (isSubscribed) {
                const listValue_aux = [];
                let options = {};
                setListValue(data);
                data.forEach((opMenu, index) => {
                    options = opMenu;
                    options["read"] = 0;
                    options["write"] = 0;
                    listValue_aux.push(options);
                });
            }
        });
        return () => (isSubscribed = false);
    }, [httpService, userData]);

    const openNew = () => {
        setPerfil(emptyPerfil);
        setAllMenuOptionsSourceValue(listValue);
        setMenuOptionsSelectedTargetValue([]);
        setCheckboxValue([]);
        setCheckVisible([]);
        setSubmitted(false);
        setPerfilDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setPerfilDialog(false);
    };

    const hideDeletePerfilDialog = () => {
        setDeletePerfilDialog(false);
    };

    const hideDeletePerfilesDialog = () => {
        setDeletePerfilesDialog(false);
    };

    const savePerfil = () => {
        setSubmitted(true);
        setButtonDisabled(true);
        if (perfil.nombre.trim() && menuOptionsSelectedTargetValue.length > 0) {
            let _perfil = { ...perfil };
            _perfil.contribuyente = userData.contribuyente;
            _perfil.opciones = menuOptionsSelectedTargetValue;
            // Verifico si las opciones tienen valor de R o W
            let noMarcada = false;
            for (let op of _perfil.opciones) {
                if ((op.read === undefined || op.read === 0) && (op.write === undefined || op.write === 0)) {
                    noMarcada = true;
                    break;
                }
            }
            if (!noMarcada) {
                if (perfil.id) {
                    // Actualizo el perfil
                    httpService
                        .adminPerfil(_perfil, "U")
                        .then((res) => {
                            setButtonDisabled(false);
                            if (res.error) {
                                toast.current.show({ severity: "error", summary: "Error", detail: res.message, life: 3000 });
                            } else {
                                // Actualizo perfil en la tabla
                                httpService.getGeneralData("perfiles", userData.contribuyente).then((data) => setPerfiles(data));
                                toast.current.show({ severity: "success", summary: "Exito", detail: res.message, life: 3000 });
                                setPerfilDialog(false);
                                setPerfil(emptyPerfil);
                            }
                        })
                        .catch((err) => {
                            toast.current.show({ severity: "error", summary: "Error", detail: err.message, life: 3000 });
                            setButtonDisabled(false);
                        });
                } else {
                    //Guardo el nuevo perfil en BD
                    httpService
                        .adminPerfil(_perfil, "C")
                        .then((res) => {
                            setButtonDisabled(false);
                            if (res.error) {
                                toast.current.show({ severity: "error", summary: "Error", detail: res.message, life: 3000 });
                            } else {
                                // Actualizo perfil en la tabla
                                httpService.getGeneralData("perfiles", userData.contribuyente).then((data) => setPerfiles(data));
                                toast.current.show({ severity: "success", summary: "Exito", detail: res.message, life: 3000 });
                                setPerfilDialog(false);
                                setPerfil(emptyPerfil);
                            }
                        })
                        .catch((err) => {
                            toast.current.show({ severity: "error", summary: "Error", detail: err.message, life: 3000 });
                            setButtonDisabled(false);
                        });
                }
            } else {
                toast.current.show({ severity: "warn", summary: "Advertencia", detail: "Hay opciones sin un nivel de acceso seleccionado", life: 3000 });
                setButtonDisabled(false);
            }
        } else {
            setButtonDisabled(false);
        }
    };

    const editPerfil = (perfil) => {
        setPerfil({ ...perfil });
        const listValue_aux = [];
        perfil.opciones.forEach((opMenu, index) => {
            let options = {};
            options["id"] = opMenu.nIdMenu + "";
            options["name"] = opMenu.sDescripcion;
            if (opMenu.sAcceso === "R") {
                options["read"] = 1;
                options["write"] = 0;
            } else if (opMenu.sAcceso === "W") {
                options["read"] = 1;
                options["write"] = 1;
            }
            listValue_aux.push(options);
        });
        setMenuOptionsSelectedTargetValue(listValue_aux);

        let arrIdOptions = [];
        let selectedValue = [];
        listValue_aux.forEach((value) => {
            arrIdOptions.push(value.id);
            if (value.read === 1) selectedValue.push("R-" + value.id);
            if (value.write === 1) {
                selectedValue.push("R-" + value.id);
                selectedValue.push("W-" + value.id);
            }
        });
        const filtered = listValue.filter(function (e) {
            return !arrIdOptions.includes(e.id);
        });

        setAllMenuOptionsSourceValue(filtered);
        setCheckboxValue(selectedValue);
        showCheckboxs(listValue_aux);
        setPerfilDialog(true);
    };

    const confirmDeletePerfil = (perfil) => {
        setPerfil(perfil);
        setDeletePerfilDialog(true);
    };

    const deletePerfil = () => {
        let _perfil = { ...perfil };
        if (perfil.id) {
            // Actualizo el perfil
            _perfil.contribuyente = userData.contribuyente;
            httpService
                .adminPerfil(_perfil, "D")
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: res.message,
                            life: 3000,
                        });
                        setPerfil(emptyPerfil);
                        setDeletePerfilDialog(false);
                    } else {
                        // Actualizo perfil en la tabla nEliminado = 1
                        httpService.getGeneralData("perfiles", userData.contribuyente).then((data) => setPerfiles(data));
                        toast.current.show({
                            severity: "success",
                            summary: "Exito",
                            detail: res.message,
                            life: 3000,
                        });
                        setPerfil(emptyPerfil);
                        setDeletePerfilDialog(false);
                    }
                })
                .catch((err) => {
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: err.message,
                        life: 3000,
                    });
                    setButtonDisabled(false);
                });
        }
    };

    const confirmDeleteSelected = () => {
        setDeletePerfilesDialog(true);
    };

    const deleteSelectedPerfiles = () => {
        let _perfiles = { ...selectedPerfiles };
        if (selectedPerfiles.length > 0) {
            // Actualizo el perfil
            httpService
                .adminPerfil(_perfiles, "DL")
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: res.message,
                            life: 3000,
                        });
                        setDeletePerfilesDialog(false);
                        setSelectedPerfiles(null);
                    } else {
                        // Actualizo perfil en la tabla nEliminado = 1
                        httpService.getGeneralData("perfiles", userData.contribuyente).then((data) => setPerfiles(data));
                        toast.current.show({
                            severity: "success",
                            summary: "Exito",
                            detail: res.message,
                            life: 3000,
                        });
                        setDeletePerfilesDialog(false);
                        setSelectedPerfiles(null);
                    }
                })
                .catch((err) => {
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: err.message,
                        life: 3000,
                    });
                    setButtonDisabled(false);
                });
        }
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _perfil = { ...perfil };
        _perfil[`${name}`] = val;
        setPerfil(_perfil);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo Perfil" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedPerfiles || !selectedPerfiles.length} />
            </React.Fragment>
        );
    };

    const codeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">ID</span>
                {rowData.id}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.nombre}
            </>
        );
    };

    const accesoBodyTemplate = (rowData) => {
        let sOpciones = "";
        rowData.opciones.forEach((op) => {
            sOpciones += op.sDescripcion + ", ";
        });
        sOpciones = sOpciones.substring(0, sOpciones.length - 2);
        return (
            <>
                <span className="p-column-title">Acceso</span>
                {sOpciones}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editPerfil(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning p-mr-2" onClick={() => confirmDeletePerfil(rowData)} />
                <Button icon={rowData.sEstatus === "A" ? "pi pi-check-circle" : "pi pi-times-circle"} className={rowData.sEstatus === "A" ? "p-button-rounded p-button-success" : "p-button-rounded p-button-danger"} onClick={() => confirmUptEstatusPerfil(rowData)} />
            </div>
        );
    };

    const confirmUptEstatusPerfil = (perfil) => {
        setPerfil(perfil);
        setUptEstatusPerfilDialog(true);
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Administrar Perfiles</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText id="search" type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const perfilDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" disabled={ButtonDisabled} onClick={savePerfil} />
        </>
    );

    const deletePerfilDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePerfilDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deletePerfil} />
        </>
    );

    const deletePerfilesDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePerfilesDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedPerfiles} />
        </>
    );

    const hideUpdEstatusPerfilDialog = () => {
        setUptEstatusPerfilDialog(false);
    };

    const updEstatusPerfil = () => {
        let _perfil = {
            id: perfil.id,
            contribuyente: perfil.contribuyente,
            sEstatus: perfil.sEstatus,
        };
        if (_perfil.id) {
            // Actualizo el perfil
            httpService
                .adminPerfil(_perfil, "S")
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: res.message,
                            life: 3000,
                        });
                        setPerfil(emptyPerfil);
                        setUptEstatusPerfilDialog(false);
                    } else {
                        // Actualizo perfil en la tabla nEliminado = 1
                        httpService.getGeneralData("perfiles", userData.contribuyente).then((data) => setPerfiles(data));
                        toast.current.show({
                            severity: "success",
                            summary: "Exito",
                            detail: res.message,
                            life: 3000,
                        });
                        setPerfil(emptyPerfil);
                        setUptEstatusPerfilDialog(false);
                    }
                })
                .catch((err) => {
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: err.message,
                        life: 3000,
                    });
                    setButtonDisabled(false);
                });
        }
    };

    const updEstatusPerfilDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideUpdEstatusPerfilDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={updEstatusPerfil} />
        </>
    );

    const optionListWidthChekbox = (item) => {
        return (
            <>
                <div className="p-grid">
                    <div className="p-col-12 p-md-4">{item.name}</div>
                    <div className="p-col-12 p-md-4" id={"accesoR-" + item.id} style={{ display: checkVisible.indexOf("accesoR-" + item.id) !== -1 ? "show" : "none" }}>
                        <div className="p-field-checkbox">
                            <Checkbox id="checkOption1" inputId={"R-" + item.id} name={item.id} value={"R-" + item.id} checked={checkboxValue.indexOf("R-" + item.id) !== -1} onChange={onCheckboxChange} />
                            <span className="p-ml-1">Read</span>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-4" id={"accesoW-" + item.id} style={{ display: checkVisible.indexOf("accesoW-" + item.id) !== -1 ? "show" : "none" }}>
                        <div className="p-field-checkbox">
                            <Checkbox id="checkOption2" inputId={"W-" + item.id} name={item.id} value={"W-" + item.id} checked={checkboxValue.indexOf("W-" + item.id) !== -1} onChange={onCheckboxChange} />
                            <span className="p-ml-1">Write</span>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const onCheckboxChange = (e) => {
        let selectedValue = [...checkboxValue];
        let array_si = e.value.split("-");
        let indexPickList = menuOptionsSelectedTargetValue.findIndex((opcmenu) => opcmenu.id === array_si[1]);

        if (e.checked) {
            selectedValue.push(e.value);
            if (array_si[0] === "R") {
                menuOptionsSelectedTargetValue[indexPickList].read = 1;
            } else if (array_si[0] === "W") {
                selectedValue.push("R-" + array_si[1]);
                menuOptionsSelectedTargetValue[indexPickList].read = 1;
                menuOptionsSelectedTargetValue[indexPickList].write = 1;
            }
        } else {
            selectedValue.splice(selectedValue.indexOf(e.value), 1);
            if (array_si[0] === "R") {
                menuOptionsSelectedTargetValue[indexPickList].read = 0;
            } else if (array_si[0] === "W") {
                menuOptionsSelectedTargetValue[indexPickList].write = 0;
            }
        }
        setCheckboxValue(selectedValue);
    };

    const showCheckboxs = (e) => {
        let visibles = [];
        let listAcceso = e;

        listAcceso.forEach((la) => {
            let item = "accesoR-" + la.id;
            visibles.push(item);
            let item2 = "accesoW-" + la.id;
            visibles.push(item2);
        });

        setCheckVisible(visibles);
    };

    const hideControls = () => {
        return false;
    };
    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={perfiles}
                        selection={selectedPerfiles}
                        onSelectionChange={(e) => setSelectedPerfiles(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        // rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} perfiles"
                        globalFilter={globalFilter}
                        emptyMessage="No se encontraron perfiles."
                        header={header}
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "5%" }}></Column>
                        <Column field="id" header="ID" headerStyle={{ width: "30%", display: "none" }} bodyStyle={{ display: "none" }} body={codeBodyTemplate}></Column>
                        <Column field="nombre" header="Nombre" sortable headerStyle={{ width: "30%" }} body={nameBodyTemplate}></Column>
                        <Column field="opciones" header="Acceso" headerStyle={{ width: "50%" }} body={accesoBodyTemplate}></Column>
                        <Column headerStyle={{ width: "15%" }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog id="d_perfil" visible={perfilDialog} style={{ width: "60vw" }} header="Detalles del Perfil" modal className="p-fluid" footer={perfilDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="nombre">Nombre</label>
                            <InputText id="nombre" value={perfil.nombre} onChange={(e) => onInputChange(e, "nombre")} required autoFocus className={classNames({ "p-invalid": submitted && !perfil.nombre })} />
                            {submitted && !perfil.nombre && <small className="p-invalid">Nombre es requerido.</small>}
                        </div>

                        <div className="p-field">
                            <span className="p-mb-3">Opciones del Menú</span>
                            <div className="p-formgrid p-grid">
                                <div className="p-col-12">
                                    <div className={submitted && menuOptionsSelectedTargetValue.length === 0 ? "card card-invalid" : "card"}>
                                        <PickList
                                            source={allMenuOptionsSourceValue}
                                            target={menuOptionsSelectedTargetValue}
                                            sourceHeader="Opciones"
                                            targetHeader="Acceso a"
                                            itemTemplate={(item) => optionListWidthChekbox(item)}
                                            onChange={(e) => {
                                                setAllMenuOptionsSourceValue(e.source);
                                                setMenuOptionsSelectedTargetValue(e.target);
                                                showCheckboxs(e.target);
                                            }}
                                            sourceStyle={{ height: "200px" }}
                                            targetStyle={{ height: "200px" }}
                                            showSourceControls={hideControls()}
                                            showTargetControls={hideControls()}
                                        ></PickList>
                                    </div>
                                    {submitted && menuOptionsSelectedTargetValue.length === 0 && <small className="p-invalid">Debe escoger al menos una opción.</small>}
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={UptEstatusPerfilDialog} style={{ width: "450px" }} header="Confirmar" modal footer={updEstatusPerfilDialogFooter} onHide={hideUpdEstatusPerfilDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                            {perfil && (
                                <span>
                                    Esta seguro de cambiarle el Estatus a <b>{perfil.nombre}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deletePerfilDialog} style={{ width: "450px" }} header="Confirmar" modal footer={deletePerfilDialogFooter} onHide={hideDeletePerfilDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                            {perfil && (
                                <span>
                                    Está seguro que desea eliminar el perfil: <b>{perfil.nombre}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deletePerfilesDialog} style={{ width: "450px" }} header="Confirmar" modal footer={deletePerfilesDialogFooter} onHide={hideDeletePerfilesDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                            {perfil && <span>Está seguro que desea elimiar el/los perfiles seleccionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
};
