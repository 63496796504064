import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData, configPermisosRW } from '../../service/AuthHelperMethods';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import swal from 'sweetalert';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import ShowLoading from "../../components/ShowLoading/ShowLoading";


const initialFValues = {
    txtFechaOrden: '',
    cboMoneda: '',
    cboProveedor: '',
    txtObservacion: '',
    txtReferenciaV: '',
    txtContribuyente: '',
    sEstatus: '',
    caso: 0,
    id: '',
    txtNroControl: '',
    nIdProveedor_aux: 0,
    nIdUsuario_aux: 0,

    cboFormaDePago: '',
    txtNroSoporte: '',
    cboBanco: '',
    cboMonedaP: '',
    txtMonto: 0,
    txtObservacionPago: '',
    txtFechaPago: ''

};

export default function ConsultaDeCompras() {

	let location = useLocation();
	const [acceso, setAcceso] = useState("");
    const [MonedaOptions, setMonedaOptions] = useState([]);
    const [TipoDocumentosOptions, setTipoDocumentosOptions] = useState([]);
    const [TipoPagoOptions, setTipoPagoOptions] = useState([]);

    const [/* ProveedorOptions */, setProveedorOptions] = useState([]);
    const [confirmarCompraDialog, setConfirmarCompraDialog] = useState(false);

    const toast = useRef(null);
    const [CompraDialog, setCompraDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [userData] = useState(getUserData() || null);
    
    const [httpService] = useState(new HttpService());
    const [compras, setCompras] = useState(null);
    const [caso, setCaso] = useState(true);
    const [BancoOptions, setBancoOptions] = useState([]);
    const [productos, setProductos] = useState([]);
    const [formasDePagoOptions, setFormasDePagoOptions] = useState([]);
    const [pagos, setPagos] = useState([]);
    const [pagosComprasDialog, setPagosComprasDialog] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);


	useEffect(() => {
		let acc = configPermisosRW(location, userData);
		setAcceso(acc);
	}, [location, userData]);

    useEffect(() => {
		setLoadingDialog(true);
        httpService.getOptionSelect("lista_Monedas").then(data => setMonedaOptions(data));
        httpService.getOptionSelect("lista_proveedores", userData.contribuyente, '').then(data => setProveedorOptions(data));
        httpService.getOptionSelect("lista_formasDePago").then(data => setFormasDePagoOptions(data));
        httpService.getOptionSelect("lista_bancos").then(data => setBancoOptions(data));
        httpService.getOptionSelect("lista_tipo_documentos", userData.contribuyente, '').then(data => setTipoDocumentosOptions(data));
        httpService.getOptionSelect("lista_tipo_pagos").then(data => setTipoPagoOptions(data));

        httpService.getTableSelect("tabla_compras", userData.contribuyente, '').then(data =>{ setCompras(data); setLoadingDialog(false);}).catch(err => setLoadingDialog(false));
    }, [httpService, userData]);

    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const [ButtonDisabledPagos, setButtonDisabledPagos] = useState(false);
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("txtRazonSocial" in fieldValues) temp.txtRazonSocial = fieldValues.txtRazonSocial ? "" : "Campo Requerido.";
        if ("txtRifProv" in fieldValues) temp.txtRifProv = fieldValues.txtRifProv ? "" : "Campo Requerido.";
        if ("txtRepresentanteLegal" in fieldValues) temp.txtRepresentanteLegal = fieldValues.txtRepresentanteLegal ? "" : "Campo Requerido.";
        if ("txtTelefono" in fieldValues) temp.txtTelefono = fieldValues.txtTelefono ? "" : "Campo Requerido.";
        if ("txtMovil" in fieldValues) temp.txtMovil = fieldValues.txtMovil ? "" : "Campo Requerido.";
        if ("txtPersonaContacto" in fieldValues) temp.txtPersonaContacto = fieldValues.txtPersonaContacto ? "" : "Campo Requerido.";
        if ("txtEmail" in fieldValues) temp.txtEmail = fieldValues.txtEmail ? "" : "Campo Requerido.";
        if ("txtDireccion" in fieldValues) temp.txtDireccion = fieldValues.txtDireccion ? "" : "Campo Requerido.";
        if ("cboMoneda" in fieldValues) temp.cboMoneda = fieldValues.cboMoneda ? "" : "Campo Requerido.";
        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };


    const validate2 = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboFormaDePago" in fieldValues) temp.cboFormaDePago = fieldValues.cboFormaDePago ? "" : "Campo Requerido.";
        if ("txtMonto" in fieldValues) temp.txtMonto = fieldValues.txtMonto ? "" : "Campo Requerido.";
        if ("txtFechaPago" in fieldValues) temp.txtFechaPago = fieldValues.txtFechaPago ? "" : "Campo Requerido.";
        if ("cboMonedaP" in fieldValues) temp.cboMonedaP = fieldValues.cboMonedaP ? "" : "Campo Requerido.";
        setErrors({...temp,});

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);


    const handleSubmit = async (e) => {
        setSubmitted(true);
        setConfirmarCompraDialog(false);
        setLoadingDialog(true);
        e.preventDefault();
        console.log(values);
        console.log("Confirmando Compra...");
        setButtonDisabled(true);
        values.txtContribuyente = userData.contribuyente;
        values.caso = caso;

        if (values.sEstatus === 'V') {
            setLoadingDialog(false);
            swal({ text: "Compra ya confirmada", icon: "error", timer: "3000" });
            setButtonDisabled(false);
        } else {
            httpService.confirmarCompra(values)
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
                        let temp = { ...errors };
                        setErrors({
                            ...temp,
                        });
                        setLoadingDialog(false);
                        swal({ text: res.message, icon: "error", timer: "6000" });
                    } else {
                        //registro exitoso
                        swal({
                            text: res.message, icon: "success", timer: "4000"
                        });
                        setCompraDialog(false);
                        setLoadingDialog(false);
                        httpService.getTableSelect("tabla_compras", userData.contribuyente, '').then(data => setCompras(data));
                        resetForm();
                    }
                })
                .catch((err) => {
                    setLoadingDialog(false);
                    swal({ text: err.message, icon: "error", timer: "4000" });
                    setButtonDisabled(false);
                });
        }

    };

    const obtenerTasaDeConversion = async (IdContribuyente, nIdMonedaO, nIdMonedaD) => {
        try {
            setButtonDisabled(false);
            const res = await httpService.getTasaDeConversion(IdContribuyente, nIdMonedaO, nIdMonedaD);
            if (res.error) {
                swal({ text: res.message, icon: "error", timer: "3000" });
                return null;
            } else {
                return parseFloat(res.tasaConversion);
            }
        } catch (err) {
            swal({ text: err.message, icon: "error", timer: "3000" });
            return null;
        }
    };

    async function agregarPagoCompra(values) {
        try {
            setButtonDisabledPagos(true);
            const res = await httpService.agregarPagoCompra(values);
            
            if (res.error) {
                let temp = { ...errors };
                setErrors({ ...temp });
                swal({ text: res.message, icon: "error", timer: "3000" });
            } else {
                swal({ text: res.message, icon: "success", timer: "7000" });
    
                const pagos = await httpService.getTableSelect("tabla_pagos_compra", userData.contribuyente, values.id);
                setPagos(pagos);
    
                const compras = await httpService.getTableSelect("tabla_compras", userData.contribuyente, '');
                setCompras(compras);
            }
        } catch (err) {
            swal({ text: err.message, icon: "error", timer: "3000" });
        } finally {
            setButtonDisabledPagos(false);
        }
    }

    const handleSubmitPagos = async (e) => {
        setSubmitted(true);
        hideAgregarPagoDialog();
        console.log(values);

        if (values.sEstatus !== 'V') {
            setPagosComprasDialog(false);
            swal({ text: "Para agregar pagos debe confirmar esta compra", icon: "error", timer: "3000" });
            setButtonDisabled(false);
        }else{
            if (validate2()) {
                setPagosComprasDialog(false);
                values.nIdUsuario_aux = userData.idUsuario;

                if (values.cboMoneda !== values.cboMonedaP) {
                    let IdContribuyente = values.nIdContribuyente
                    let nIdMonedaO = values.cboMoneda
                    let nIdMonedaD = values.cboMonedaP
                    let tasaConversion = 0;
                    let montoConvertido = 0;
                    let monedaConvertida = 0;
                    let signoConvertido = '';

                    if (values.cboMoneda !== 28){
                        nIdMonedaO = values.cboMonedaP
                        nIdMonedaD = values.cboMoneda
                    }

                    tasaConversion = await obtenerTasaDeConversion(IdContribuyente, nIdMonedaO, nIdMonedaD);

                    if (tasaConversion) {

                        montoConvertido = parseFloat(values.txtMonto) / parseFloat(tasaConversion);
                        monedaConvertida = 28;
                        signoConvertido = 'Usd';

                        if (values.cboMoneda !== 28){
                            montoConvertido = parseFloat(values.txtMonto) * parseFloat(tasaConversion);
                            monedaConvertida = 27;
                            signoConvertido = 'Bs.';
                        }
                        
                        const userConfirmed = await swal({
                            title: "Advertencia",
                            text: `Está a punto de agregar un pago en una moneda distinta a la orden. Al hacer la conversión, el pago es de ${montoConvertido.toFixed(2)}
                             ${signoConvertido} a un tasa de ${tasaConversion}`,
                            icon: "warning",
                            buttons: ["Cancelar", "Continuar"],
                            dangerMode: false,
                        });
    
                        if (!userConfirmed) {
                            setButtonDisabled(false);
                            return;
                        }
                        values.txtMonto = montoConvertido;
                        values.cboMonedaP = monedaConvertida;

                    } else {
                        swal({ text: "Error al obtener la tasa de conversión", icon: "error", timer: "3000" });
                        setButtonDisabled(false);
                        return;
                    }
                }

                    await agregarPagoCompra(values);
            }
        }

    };

    const editCompra = (compra) => {
        setCaso(1);
        setValues(compra);
        httpService.getTableSelect("tabla_item_compraCV", userData.contribuyente, compra.id).then(data => setProductos(data));
        httpService.getTableSelect("tabla_pagos_compra", userData.contribuyente, compra.id).then(data => setPagos(data));
        setCompraDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setCompraDialog(false);
    }


    const openNewPagoCompra = () => {
        console.log(values);
        setPagosComprasDialog(true);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Borrar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={ acceso && acceso === "W" ? ( !selectedCompras || !selectedCompras.length) : true } />
            </React.Fragment>
        )
    }

    const leftToolbarTemplateP = () => {
        return (
            <React.Fragment>
                <Button label="Agregar" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={openNewPagoCompra} disabled={ acceso && acceso === "W" ? false : true } />
            </React.Fragment>
        )
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Consulta de Compras</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );


    const confirmConfirmarCompra = () => {

        setConfirmarCompraDialog(true);
    }

    const ComprasDialogFooter = (
        <>
            <Button label="Confirmar Orden" icon="pi pi-add" className="p-button-success p-mr-2" onClick={confirmConfirmarCompra} disabled={ acceso && acceso === "W" ? ButtonDisabled : true } />
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const ProductoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sProducto}
            </>
        );
    }


    const DescripcionBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.txtDescripcion}
            </>
        );
    }

    const CantidadBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtCantidad)} ${rowData.sUoM}`;
    }

    const PrecioUnitBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtPrecioUnit)} ${rowData.sMoneda}`;
    }

    const IgtfBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtIgtf)} ${rowData.sMoneda}`;
    }

    const ImpuestoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sImpuesto}
            </>
        );
    }

    const BaseImponibleBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtBaseImponible)} ${rowData.sMoneda}`;
    }

    const SubTotalBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtSubTotal)} ${rowData.sMoneda}`;
    }

    const TotalBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtTotal)}${rowData.txtMoneda}`;
    }

    const formatCurrency = function (number) {
        var numeral = require('numeral');
        var myNumeral = numeral(number);
        return myNumeral.format('0,0.00');
    };

    const NumeroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nro Orden</span>
                <span><b>{rowData.txtNumero}</b></span>
            </>
        );
    }

    const TipoDocumentoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tipo</span>
                <span><b>{rowData.txtTipoDocumento}</b></span>
            </>
        );
    }

    const FechaCompraTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha</span>
                {rowData.txtFechaCompra}
            </>
        );
    }

    const FechaImpositivaTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Imp.</span>
                {rowData.txtFechaImpositiva}
            </>
        );
    }

    const ProveedorBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Proveedor</span>
                {rowData.txtProveedor}
            </>
        );
    }

    const EstatusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"></span>
                {/* <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.txtEstatus}</span> */}
                <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.txtEstatus}</span>
            </>
        );
    }


    const EstatusPagosBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"></span>
                <span className={`product-badge status-${rowData.inventoryStatusPagos.toLowerCase()}`}>{rowData.txtEstatusPagos}</span>
            </>
        );
    }

    const EstatusRecepcionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"></span>
                <span className={`product-badge status-${rowData.inventoryStatusRecepcion.toLowerCase()}`}>{rowData.txtEstatusRecepcion}</span>
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-search" className="p-button-rounded p-button-success p-mr-2" onClick={() => editCompra(rowData)} />
            </div>
        );
    }

    const [deleteComprasDialog, setDeleteComprasDialog] = useState(false);
    const [selectedCompras, setSelectedCompras] = useState(null);
    const [agregarPagoDialog, setAgregarPagoDialog] = useState(false);

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);



    const hideDeleteCompraDialog = () => {
        setDeleteComprasDialog(false);
    }

    const hideAgregarPagoDialog = () => {
        setAgregarPagoDialog(false);
    }

    const hideDialogPagosCompras = () => {
        setSubmitted(false);
        setPagosComprasDialog(false);
    }

    const confirmDeleteSelected = () => {
        setDeleteComprasDialog(true);
    }

    const confirmAgregarPagos = () => {
        setAgregarPagoDialog(true);
    }

    const deleteSelectedCompras = async (e) => {
        console.log(selectedCompras);
        e.preventDefault();
        httpService.deleteCompras(selectedCompras)
            .then((res) => {
                if (res.error) {
                    let temp = { ...errors };
                    if (res.message.indexOf("Email") >= 0) {
                        temp.txtEmail = res.message;
                    }
                    setErrors({
                        ...temp,
                    });
                    setDeleteComprasDialog(false);
                    swal({ text: res.message, icon: "error", timer: "3000" });
                } else {
                    swal({ text: res.message, icon: "success", timer: "3000" });
                    setDeleteComprasDialog(false);
                    setSelectedCompras(null);
                    httpService.getTableSelect("tabla_compras", userData.contribuyente, '').then(data => setCompras(data));
                }
            })
            .catch((err) => {
                swal({ text: err.message, icon: "error", timer: "3000" });
            });

    }

    const deleteCompraDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCompraDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCompras} />
        </>
    );

    const agregarPagoDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideAgregarPagoDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={handleSubmitPagos} />
        </>
    );


    const PagosComprasDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialogPagosCompras} />
            <Button label="Guardar" icon="pi pi-plus" className="p-button-text" disabled={ButtonDisabledPagos} onClick={confirmAgregarPagos} />
        </>
    );

    const BancosOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div> <img alt={option.title} src={option.flag} width="15px" /> {option.title}</div>
            </div>
        );
    }

    const selectedBancoTemplate = (option, props) => {
        if (option) {
            return (
                <div className="country-item country-item-value">
                    <div> <img alt={option.title} src={option.flag} width="15px" /> {option.title}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const FormaPagoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sFormaDePago}
            </>
        );
    }

    const FechaPagoTemplate = (rowData) => {
        return (
            <>
                {rowData.txtFechaPago}
            </>
        );
    }

    const NroSoporteBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sNroSoporte}
            </>
        );
    }

    const BancoBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sBanco}
            </>
        );
    }

    const MontoPagoBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.deMontoPago)} ${rowData.sMoneda}`;
    }

    const ObservacionBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.sObservacion}
            </>
        );
    }
    const Nsi = () => {
        let total = 0;
        let sMoneda = "";
        for (let mont of productos) {
            total += parseFloat(mont.txtBaseImponible);
            sMoneda = mont.sMoneda;
        }
        return formatCurrency(total) + " " + sMoneda;
    }

    var groupBy = function (miarray, prop) {
        return miarray.reduce(function (groups, item) {
            var val = item[prop];
            groups[val] = groups[val] || { sImpuesto: item.sImpuesto, deImpuesto: 0, deBaseImpon: 0, sPctRetenido: item.sPctRetenido, dePctRetenido:0, deRetenidoISLR: 0, sPctTarifaRetenidaISLR: item.sPctTarifaRetenidaISLR};
            groups[val].deImpuesto += parseFloat(item.deImpuesto);
            groups[val].sMoneda = item.sMoneda;
            groups[val].deBaseImpon += parseFloat(item.txtSubTotalBaseImpo);
            groups[val].dePctRetenido += parseFloat(item.dePctRetenido);
            groups[val].deRetenidoISLR += parseFloat(item.deRetenidoISLR);
            return groups;
        }, {});
    };

    const grupoImpuesto = () => {
        let grImp = groupBy(productos, "sImpuesto");
        let refinado = Object.values(grImp).filter((i) => i.sImpuesto !== "Exento");

        return refinado;
    };

    const grupoPctRetenido = () => {
        let grImp = groupBy(productos, "sPctRetenido");
        let refinado = Object.values(grImp).filter((i) => i.sPctRetenido !== "Exento");

        return refinado;
    };

    const grupoPctRetenidoISLR = () => {
        let grImp = groupBy(productos, "sPctTarifaRetenidaISLR");
        let refinado = Object.values(grImp);

        return refinado;
    };

   const Total = () => {
        let subtotal = 0;
        let dePctRetenido_aux = 0;
        let deRetenidoISLR_aux = 0;
        let total = 0;
        let sMoneda = "";
        for (let mont of productos) {
            subtotal += parseFloat(mont.txtSubTotal);
            dePctRetenido_aux += parseFloat(mont.dePctRetenido);
            deRetenidoISLR_aux += parseFloat(mont.deRetenidoISLR);
            sMoneda = mont.sMoneda;
        }
        total = (subtotal - deRetenidoISLR_aux) - dePctRetenido_aux;
        return formatCurrency(total) + " " + sMoneda;
    }

    const TotalIgtf = () => {
        let total = 0;
        let sMoneda = "";
        for (let mont of productos) {
            total += parseFloat(mont.txtIgtf);
            sMoneda = mont.sMoneda;
        }
        return formatCurrency(total) + " " + sMoneda;
    }

    const TotalPagos = () => {
        let total = 0;
        for (let mont of pagos) {
            total += parseFloat(mont.deMontoPago);
        }
        return formatCurrency(total);
    }

    const TotalCompra = () => {
        let subtotal = 0;
        let dePctRetenido_aux = 0;
        let deRetenidoISLR_aux = 0;
        let total = 0;
        
        for (let mont of productos) {
            subtotal += parseFloat(mont.txtSubTotal);
            dePctRetenido_aux += parseFloat(mont.dePctRetenido);
            deRetenidoISLR_aux += parseFloat(mont.deRetenidoISLR);
        }
        total = (subtotal - deRetenidoISLR_aux) - dePctRetenido_aux;

        return formatCurrency(total);
    }

    const RestaPorPagar = () => {
        let subtotal = 0;
        let dePctRetenido_aux = 0;
        let deRetenidoISLR_aux = 0;
        let totalCompra = 0;
        let totalPagos = 0;
        let total = 0;
        
        for (let mont of productos) {
            subtotal += parseFloat(mont.txtSubTotal);
            dePctRetenido_aux += parseFloat(mont.dePctRetenido);
            deRetenidoISLR_aux += parseFloat(mont.deRetenidoISLR);
        }
        totalCompra = (subtotal - deRetenidoISLR_aux) - dePctRetenido_aux;


        for (let mont of pagos) {
            totalPagos += parseFloat(mont.deMontoPago);
        }

        total = totalCompra - totalPagos;
        return formatCurrency(total);
    }

    let footerGroup = <ColumnGroup>
        <Row>
            <Column footer="Base Imponible:" colSpan={7} footerStyle={{ textAlign: 'right' }} />
            <Column footer={Nsi} footerStyle={{ textAlign: "right" }}/>
        </Row>

        {grupoImpuesto().map((post, i) => (
            <Row key={'tax'+i}>
                <Column footer={`${post.sImpuesto} (B.I. = ${formatCurrency(post.deBaseImpon)} ${post.sMoneda}): `} colSpan={7} footerStyle={{ textAlign: "right" }} />
                <Column footer={formatCurrency(post.deImpuesto) + " " + post.sMoneda} footerStyle={{ textAlign: "right" }} />
            </Row>
        ))}

        {grupoPctRetenidoISLR().map((post, i) => (
            <Row key={'islr'+i}>
                <Column footer={`Porcentaje Retenido ISRL: (${post.sPctTarifaRetenidaISLR}): `} colSpan={7} footerStyle={{ textAlign: "right" }} />
                <Column footer={formatCurrency((-1 * post.deRetenidoISLR)) + " " + post.sMoneda} footerStyle={{ textAlign: "right" }} />
            </Row>
        ))}

        {grupoPctRetenido().map((post, i) => (
            <Row key={'retain'+i}>
                <Column footer={`Porcentaje Retenido IVA (${post.sPctRetenido}): `} colSpan={7} footerStyle={{ textAlign: "right" }} />
                <Column footer={formatCurrency((-1 * post.dePctRetenido)) + " " + post.sMoneda} footerStyle={{ textAlign: "right" }} />
            </Row>
        ))}
        
        <Row>
            <Column footer="Total IGTF:" colSpan={7} footerStyle={{ textAlign: 'right' }} />
            <Column footer={TotalIgtf} footerStyle={{ textAlign: "right" }} />
        </Row>

        <Row>
            <Column footer="TOTAL:" colSpan={7} footerStyle={{ textAlign: 'right' }} />
            <Column footer={Total} footerStyle={{ textAlign: "right" }} />
        </Row>
    </ColumnGroup>;

    let footerGroupPagos = <ColumnGroup>
        <Row>
            <Column footer="Total Pagos:" colSpan={5} footerStyle={{ textAlign: 'right' }} />
            <Column footer={TotalPagos} />
        </Row>
        <Row>
            <Column footer="Total Compra:" colSpan={5} footerStyle={{ textAlign: 'right' }} />
            <Column footer={TotalCompra} />
        </Row>
        <Row>
            <Column footer="Restan por pagar:" colSpan={5} footerStyle={{ textAlign: 'right' }} />
            <Column footer={RestaPorPagar} />
        </Row>
    </ColumnGroup>;

    const hideConfirmarCompraDialog = () => {
        setConfirmarCompraDialog(false);
    }

    const confirmarCompraDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideConfirmarCompraDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={handleSubmit} />
        </>
    );

	const sortDate = (arr, e) => {
		var valores = arr;
		valores.sort(function(a,b){
			var aFecha1 = b[e.field].split("/");
			var aFecha2 = a[e.field].split("/");
			var fecha1 = aFecha1[2] + "-" +   aFecha1[1] + "-" + aFecha1[0];
			var fecha2 = aFecha2[2] + "-" +   aFecha2[1] + "-" + aFecha2[0];
			if (e.order < 0) {
				return new Date(fecha1) - new Date(fecha2)
			} else {
				return new Date(fecha2) - new Date(fecha1);
			}
		  });
		return valores;
	}

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <DataTable ref={dt} value={compras} selection={selectedCompras} onSelectionChange={(e) => setSelectedCompras(e.value)}
                        dataKey="id" paginator rows={10} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} compras"
                        globalFilter={globalFilter} emptyMessage="No existen compras." header={header} >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="txtNumero" header="Nro Orden" sortable body={NumeroBodyTemplate}></Column>
                        <Column field="txtTipoDocumento" header="Tipo" sortable body={TipoDocumentoTemplate}></Column>
                        <Column field="txtFechaCompra" sortable sortFunction={(e) => sortDate(compras, e)} header="Fecha C." body={FechaCompraTemplate} ></Column>
                        <Column field="txtFechaImpositiva" sortable sortFunction={(e) => sortDate(compras, e)} header="Fecha Imp." body={FechaImpositivaTemplate} ></Column>
                        <Column field="txtProveedor" header="Proveedor" sortable body={ProveedorBodyTemplate}></Column>
                        <Column field="txtTotal" header="Total" sortable body={TotalBodyTemplate}></Column>
                        <Column field="txtEstatus" header="Estatus" sortable body={EstatusBodyTemplate}></Column>
                        <Column field="txtEstatusPagos" header="Pagos" sortable body={EstatusPagosBodyTemplate}></Column>
                        <Column field="txtEstatusRecepcion" header="Recepcion" sortable body={EstatusRecepcionBodyTemplate}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={CompraDialog} maximizable modal style={{ width: '50vw' }} header="Detalles del Proveedor" className="p-fluid" footer={ComprasDialogFooter} onHide={hideDialog}>
                        <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
                        <TabView className="tabview-custom">
                            <TabPanel header="Datos Básicos" leftIcon="pi pi-user">
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="cboProveedor">Proveedor</label>
										<InputText disabled name="txtProveedor" value={values.txtProveedor} onChange={handleInputChange} autoFocus />
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtFechaOrden">Fecha de la orden</label>
                                        <InputText disabled name="txtFechaOrden" value={values.txtFechaOrden} onChange={handleInputChange} autoFocus />
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                    <label htmlFor="cboTipoDocumento">Tipo Documento</label>
                                        <Dropdown disabled id="cboTipoDocumento" name="cboTipoDocumento" value={values.cboTipoDocumento} onChange={handleInputChange} optionValue="id" optionLabel="title" options={TipoDocumentosOptions} placeholder="Selecciona uno" ></Dropdown>
                                    </div>
                                    <div className="p-field p-col">
                                    <label htmlFor="cboTipoPago">Tipo Pago</label>
                                        <Dropdown disabled id="cboTipoPago" name="cboTipoPago" value={values.cboTipoPago} onChange={handleInputChange} optionValue="id" optionLabel="title" options={TipoPagoOptions} placeholder="Selecciona uno" ></Dropdown>
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtReferenciaV">Referencia del Vendedor</label>
                                        <InputText disabled name="txtReferenciaV" value={values.txtReferenciaV} onChange={handleInputChange} autoFocus />
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="txtNroControl">Nro Control del Vendedor</label>
                                        <InputText disabled name="txtNroControl" value={values.txtNroControl} onChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && !values.txtNroControl })} />
                                        {submitted && !values.txtNroControl && <small className="p-invalid">{errors.txtNroControl}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-flied p-col">
                                        <label htmlFor="cboMoneda">Moneda</label>
                                        <Dropdown disabled id="cboMoneda" name="cboMoneda" value={values.cboMoneda} onChange={handleInputChange} optionValue="id" optionLabel="title" options={MonedaOptions} placeholder="Selecciona uno" ></Dropdown>
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtObservacion">Observación</label>
                                        <InputTextarea disabled name="txtObservacion" value={values.txtObservacion} onChange={handleInputChange} autoFocus />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Items de la Compra" leftIcon="pi pi-dollar">
                                <div className="p-grid crud-demo">
                                    <div className="p-col-12">
                                        <div className="card">
                                            <DataTable ref={dt} value={productos} dataKey="id" footerColumnGroup={footerGroup}>
                                                <Column field="sProducto" header="Producto" body={ProductoBodyTemplate}></Column>
                                                <Column field="txtDescripcion" header="Descripcion" body={DescripcionBodyTemplate}></Column>
                                                <Column field="txtCantidad" header="Cant." body={CantidadBodyTemplate}></Column>
                                                <Column field="txtPrecioUnit" header="Precio Unit." body={PrecioUnitBodyTemplate}></Column>
                                                <Column field="sImpuesto" header="Alicuota" body={ImpuestoBodyTemplate}></Column>
                                                <Column field="txtIgtf" header="IGTF (3%)" body={IgtfBodyTemplate}></Column>
                                                <Column field="txtBaseImponible" header="Base Imponible" body={BaseImponibleBodyTemplate}></Column>
                                                <Column field="txtSubTotal" header="Sub-Total" body={SubTotalBodyTemplate}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Pagos de la Compra" leftIcon="pi pi-dollar">
                                <div className="p-grid crud-demo">
                                    <div className="p-col-12">
                                        <div className="card">
                                            <Toast ref={toast} />
                                            <Toolbar className="p-mb-4" left={leftToolbarTemplateP} ></Toolbar>
                                            <DataTable ref={dt} value={pagos} dataKey="id" footerColumnGroup={footerGroupPagos}>
                                                <Column field="txtFechaPago" sortable header="Fecha" body={FechaPagoTemplate} ></Column>
                                                <Column field="sFormaDePago" header="Forma Pago" sortable body={FormaPagoBodyTemplate}></Column>
                                                <Column field="sNroSoporte" header="Nro Soporte" sortable body={NroSoporteBodyTemplate}></Column>
                                                <Column field="sBanco" header="Banco" sortable body={BancoBodyTemplate}></Column>
                                                <Column field="sObservacion" header="Observacion" body={ObservacionBodyTemplate}></Column>
                                                <Column field="deMontoPago" header="Monto" sortable body={MontoPagoBodyTemplate}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </TabView>
                    </Dialog>

                    <Dialog visible={pagosComprasDialog} style={{ width: '550px' }} header="Detalle de Pagos" modal className="p-fluid" footer={PagosComprasDialogFooter} onHide={hideDialogPagosCompras}>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtFechaPago">Fecha de la orden</label>
                                <Calendar showIcon showButtonBar locale="es" dateFormat="dd/m/yy" id="txtFechaPago" name="txtFechaPago" value={values.txtFechaPago} onChange={handleInputChange} monthNavigator yearNavigator yearRange="2010:2030" required className={classNames({ 'p-invalid': submitted && !values.txtFechaPago })} />
                                {submitted && !values.txtFechaPago && <small className="p-invalid">{errors.txtFechaPago}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="cboFormaDePago">Forma de Pago</label>
                                <Dropdown id="cboFormaDePago" name="cboFormaDePago" value={values.cboFormaDePago} onChange={handleInputChange} optionValue="id" optionLabel="title" options={formasDePagoOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboFormaDePago })}></Dropdown>
                                {submitted && !values.cboFormaDePago && <small className="p-invalid">{errors.cboFormaDePago}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtNroSoporte">Nro de Soporte (Opcional)</label>
                                <InputText name="txtNroSoporte" value={values.txtNroSoporte} onChange={handleInputChange} autoFocus />
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="cboBanco">Banco de pago (Opcional)</label>
                                <Dropdown id="cboBanco" name="cboBanco" value={values.cboBanco} onChange={handleInputChange} optionValue="id" optionLabel="title" options={BancoOptions} filter showClear filterBy="title" placeholder="Selecciona uno" itemTemplate={BancosOptionTemplate} valueTemplate={selectedBancoTemplate}></Dropdown>
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-flied p-col">
                                <label htmlFor="cboMonedaP">Moneda</label>
                                <Dropdown id="cboMonedaP" name="cboMonedaP" value={values.cboMonedaP} onChange={handleInputChange} optionValue="id" optionLabel="title" options={MonedaOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboMonedaP })}></Dropdown>
                                {submitted && !values.cboMonedaP && <small className="p-invalid">{errors.cboMonedaP}</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="txtMonto">Monto</label>
                                <InputNumber name="txtMonto" mode="decimal" minFractionDigits={2} value={values.txtMonto} onValueChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && !values.txtMonto })} />
                                {submitted && !values.txtMonto && <small className="p-invalid">{errors.txtMonto}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtObservacionPago">Observación</label>
                                <InputTextarea name="txtObservacionPago" value={values.txtObservacionPago} onChange={handleInputChange} autoFocus />
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteComprasDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCompraDialogFooter} onHide={hideDeleteCompraDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Esta Seguro de eliminar la compra seleccionada?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={agregarPagoDialog} style={{ width: '450px' }} header="Confirm" modal footer={agregarPagoDialogFooter} onHide={hideAgregarPagoDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Esta operacion no podrá ser reversada o eliminada. Verifique que los datos sean correctos. ¿Esta Seguro de agregar este pago?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={confirmarCompraDialog} style={{ width: '450px' }} header="Confirm" modal footer={confirmarCompraDialogFooter} onHide={hideConfirmarCompraDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Esta Seguro de Confirmar esta Compra? entrara en los libros de compra y no podra modificarse</span>}
                        </div>
                    </Dialog>

                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
}
