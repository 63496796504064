import React from 'react';
import { getUserData }  from './service/AuthHelperMethods';

export const AppTopbar = (props) => {
    const userData = getUserData() || null;

    return (
        <div className="layout-topbar clearfix">
            <button type="button" className="p-link layout-menu-button" onClick={props.onToggleMenu}>
                <span className="pi pi-bars" />
            </button>
            <div className="layout-topbar-icons ">
                <div className="text-center"><h3>{ userData ? userData.razon_social : ""}</h3></div>
            </div>
        </div>
    );
}
