import axios from 'axios';
const endpoint = process.env.REACT_APP_API;

export class HttpService {
	registrarContribuyente(datos) {
		return axios.post(`${endpoint}/registrar_contribuyente.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	cambiarClaveUsuario(datos) {
		return axios.post(`${endpoint}/cambiar_clave_usuario.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	getOptionSelect(tipoDato, IdContribuyente, tipo_ref){
		return axios.get(`${endpoint}/get_options_select.php`, { params: { tipoDato, IdContribuyente, tipo_ref }})
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	getOptionSelect_WithIdContri(tipoDato, IdContribuyente){
		return axios.get(`${endpoint}/get_options_select.php`, { params: { tipoDato, IdContribuyente }})
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	getOptionContribuyente(tipoDato, IdUsuario){
		return axios.get(`${endpoint}/get_options_select.php`, { params: { tipoDato, IdUsuario }})
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	olvidoDeClave(datos) {
		return axios.post(`${endpoint}/olvido_de_clave.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	registrarNuevoUsuario(datos) {
		return axios.post(`${endpoint}/registrar_nuevo_usuario.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	getTableSelect(tipoDato, IdContribuyente, tipo_ref = null, tipo_ref2 = null){
	return axios.get(`${endpoint}/get_tables_select.php`, { params: { tipoDato, IdContribuyente, tipo_ref, tipo_ref2}})
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		}) 
	}

	getGeneralData(tipoDato, idRef = null){
		return axios.get(`${endpoint}/get_general_data.php`, { params: { tipoDato, idRef }})
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	editarRegistroContribuyente(idContrib, datos) {
		datos["idContrib"] = idContrib;
		return axios.post(`${endpoint}/actualizar_contribuyente.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	updateEstatusUsuario(datos) {
		return axios.post(`${endpoint}/update_estatus_usuario.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	deleteUsuarios(datos) {
		return axios.post(`${endpoint}/delete_usuarios.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	adminPerfil(datos, opcion) {
		datos.tipoUpdate = opcion;
		return axios.post(`${endpoint}/administrar_perfiles.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	getMenuPrincipal(idPerfil, idContribuyente, tipoUsuario){
		return axios.get(`${endpoint}/get_menu.php`, { params: { idPerfil, idContribuyente, tipoUsuario }})
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	registrarNuevoTProducto(datos) {
		return axios.post(`${endpoint}/registrar_nuevo_tipo_producto.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	deleteTProductos(datos) {
		return axios.post(`${endpoint}/delete_tipo_producto.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	updateEstatusTProducto(datos) {
		return axios.post(`${endpoint}/update_estatus_tipo_producto.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	registrarNuevoProducto(datos) {
		const options = {
			onUploadProgress: () => {
			}
		}

		return axios.post(`${endpoint}/registrar_nuevo_producto.php`, datos, options)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	registrarFotoProducto(datos) {
		const options = {
			onUploadProgress: () => {
			}
		}

		return axios.post(`${endpoint}/registrar_foto_producto.php`, datos, options, {headers: {'content-type': 'multipart/form-data'}})
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	deleteProductos(datos) {
		return axios.post(`${endpoint}/delete_producto.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	updateEstatusProducto(datos) {
		return axios.post(`${endpoint}/update_estatus_producto.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}


	registrarCBancariaProv(datos) {
		return axios.post(`${endpoint}/registrar_cuenta_bancaria_prov.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	deleteCB(datos) {
		return axios.post(`${endpoint}/delete_cuentas_bancarias_prov.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	limpiarCB(datos) {
		return axios.post(`${endpoint}/limpiar_CB.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	registrarNuevoProveedor(datos) {
		const options = {
			onUploadProgress: () => {
			}
		}

		return axios.post(`${endpoint}/registrar_nuevo_proveedor.php`, datos, options)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	AsociarCB(datos) {
		return axios.post(`${endpoint}/asociar_CB.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	crearNotificaciones(datos) {
		return axios.post(`${endpoint}/crear_notificaciones_contribuyente.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	deleteProveedores(datos) {
		return axios.post(`${endpoint}/delete_proveedor.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	udpateRif(idContrib, datos) {
		datos["idContrib"] = idContrib;
		return axios.post(`${endpoint}/update_rif_contribuyente.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	updateTokenPush(idUsuario, idContrib, token) {
		const datos = {
			"idUsuario" : idUsuario,
			"idContrib" : idContrib,
			"token" : token, 
			"tipo" : "U"
		}
		// datos["idContrib"] = idContrib;
		return axios.post(`${endpoint}/admin_token_notificaciones.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	desactiveTokenPush(idUsuario, idContrib) {
		const datos = {
			"idUsuario" : idUsuario,
			"idContrib" : idContrib,
			"tipo" : "D"
		}
		// datos["idContrib"] = idContrib;
		return axios.post(`${endpoint}/admin_token_notificaciones.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	sendNotificationsPush(idUsuario, idContrib) {
		const datos = {
			"idUsuario" : idUsuario,
			"idContrib" : idContrib,
		}
		return axios.post(`${endpoint}/send_push_notifications.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}


	sendNotificationsVencidaPush(idUsuario, idContrib, tipo) {
		const datos = {
			"idUsuario" : idUsuario,
			"idContrib" : idContrib,
			"tipo" : tipo,
		}

		return axios.post(`${endpoint}/send_push_notifications_vencidas.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	buscarDatosItemCompra(datos) {
		return axios.post(`${endpoint}/buscar_datos_item_compra.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	agregarItemCompra(datos) {
		return axios.post(`${endpoint}/agreagar_item_compra.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	deleteItemCompra(datos) {
		return axios.post(`${endpoint}/delete_item_compra.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	registrarNuevaCompra(datos) {
		return axios.post(`${endpoint}/registar_nueva_compra.php`, datos)//,options)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	ObtenerIsContable(datos) {
		return axios.post(`${endpoint}/obtener_is_contable.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	getTasaDeConversion(IdContribuyente, nIdMonedaO, nIdMonedaD){
		return axios.get(`${endpoint}/get_tasa_conversion.php`, { params: { IdContribuyente, nIdMonedaO, nIdMonedaD }})
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	asociarItemsCompra(datos) {
		return axios.post(`${endpoint}/asociar_items_compra.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	confirmarCompra(datos) {
		const options = {
			onUploadProgress: () => {
			}
		}

		return axios.post(`${endpoint}/confirmar_compra.php`, datos, options)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	deleteCompras(datos) {
		return axios.post(`${endpoint}/delete_compra.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	adminClientes(datos) {
		return axios.post(`${endpoint}/admin_clientes.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}
	
	deleteClientes(datos) {
		return axios.post(`${endpoint}/delete_clientes.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	agregarPagoCompra(datos) {
		return axios.post(`${endpoint}/agreagar_pago_compra.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	agregarItemFactura(datos) {
		return axios.post(`${endpoint}/agreagar_item_factura.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	deleteItemFactura(datos) {
		return axios.post(`${endpoint}/delete_item_factura.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	recibirProductos(datos) {
		return axios.post(`${endpoint}/recibir_productos.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	registrarPreFactura(datos) {
		return axios.post(`${endpoint}/registrar_pre_factura.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	registrarNuevaVenta(datos) {
		return axios.post(`${endpoint}/registrar_nueva_venta.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	asociarItemsVenta(datos) {
		return axios.post(`${endpoint}/asociar_items_venta.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	registrarTasaConversion(datos) {
		return axios.post(`${endpoint}/registrar_tasa_conversion.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	deleteTasas(datos) {
		return axios.post(`${endpoint}/delete_tasas.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	registrarLogoContribuyente(datos) {
		const options = {
			onUploadProgress: () => {
			}
		}

		return axios.post(`${endpoint}/registrar_logo_contribuyente.php`, datos, options, {headers: {'content-type': 'multipart/form-data'}})
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	registrarCorrelativo(datos) {
		return axios.post(`${endpoint}/registrar_correlativo.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	deleteCorrelativos(datos) {
		return axios.post(`${endpoint}/delete_correlativos.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	updateEstatusCorrelativo(datos) {
		return axios.post(`${endpoint}/update_estatus_correlativo.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	generarComprobanteReten(datos) {
		const options = {
			onUploadProgress: () => {
			}
		}

		return axios.post(`${endpoint}/generar_comprobante_retencion.php`, datos, options)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	getNextCorrelativo(tipoDoc, campoDoc, idContribuyente, serie){
		return axios.get(`${endpoint}/get_next_correlativo.php`, { 
			params: { tipoDoc, campoDoc, idContribuyente, serie }
		})
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	anularVentas(datos) {
		return axios.post(`${endpoint}/anular_ventas.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	generarLibroVentas(datos) {
		return axios.post(`${endpoint}/generar_libro_ventas.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	agregarRetencionVenta(datos) {
		return axios.post(`${endpoint}/agregar_retencion_venta.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	registrarDeclaracion(datos) {
		const options = {
			onUploadProgress: () => {
			}
		}
		return axios.post(`${endpoint}/registrar_declaracion.php`, datos, options, {headers: {'content-type': 'multipart/form-data'}})
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	deleteDeclaraciones(datos) {
		return axios.post(`${endpoint}/delete_declaraciones.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	agregarPagoDeclaracion(datos) {
		return axios.post(`${endpoint}/agreagar_pago_declaracion.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	registrarMF(datos) {
		return axios.post(`${endpoint}/admin_maquinas_fiscales.php`, datos)
		.then(res => {
			const data = res.data;
			return Promise.resolve(data);
			
		}).catch(error => {
			return Promise.reject(error);
		})
	}

	genearLibroDiario(txtContribuyente, txtFechaDesde, txtFechaHasta){
		let datos = {
			"txtContribuyente" : txtContribuyente, 
			"txtFechaDesde" : txtFechaDesde, 
			"txtFechaHasta" : txtFechaHasta
		}
		return axios.post(`${endpoint}/generar_libro_diario.php`, datos)
			.then(res => {
				const data = res.data;
				return Promise.resolve(data);
				
			}).catch(error => {
				return Promise.reject(error);
			}) 
	}
}

