import React, { useState, useEffect } from 'react'
import { Grid, Container, Paper, Avatar, Typography, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { LockOutlined as LockOutlinedIcon } from '@material-ui/icons'
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import { validarUsuario } from '../../service/AuthHelperMethods';
import { useHistory, Link  } from "react-router-dom";


const useStyles = makeStyles(theme => ({
    root: {
				backgroundColor: "#2E6DB4",
				height: "100vh",
				overflow: "auto"
    },
    container: {
        opacity: '0.8',
        marginTop: theme.spacing(10),
				marginBottom: theme.spacing(10),
        [theme.breakpoints.down(400 + theme.spacing(2) + 2)]: {
            marginTop: theme.spacing(5),
						marginBottom: theme.spacing(5),
            width: '100%',
        }
    },
    div: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    div_error: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(3, 0, 2)
    }
}))


const initialFValues = {
    txtUsuario: '',
    txtClave: '',
}

const clearStorage = () => {
    localStorage.clear();
}

export default function Login(props) {

    useEffect(() => {
        clearStorage()
        return () => {

        }
    }, [])

    const history = useHistory();

    const [ButtonDisabled, setButtonDisabled] = useState(false)

    const classes = useStyles()

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('txtUsuario' in fieldValues)
            temp.txtUsuario = fieldValues.txtUsuario ? "" : "Campo Requerido."
        if ('txtClave' in fieldValues)
            temp.txtClave = fieldValues.txtClave ? "" : "Campo Requerido."
        if ('txtUsuario' in fieldValues)
            temp.txtUsuario = (/$^|.+@.+..+/).test(fieldValues.txtUsuario) ? "" : "Email no valido."
        /*         if ('email' in fieldValues)
                    temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : "Email is not valid."
                if ('mobile' in fieldValues)
                    temp.mobile = fieldValues.mobile.length > 9 ? "" : "Minimum 10 numbers required."
                if ('departmentId' in fieldValues)
                    temp.departmentId = fieldValues.departmentId.length !==0 ? "" : "This field is required."
        */
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        errors,
        setErrors,
        handleInputChange
    } = useForm(initialFValues, true, validate);

    const handleSubmit = async e => {
        e.preventDefault()
        if (validate()) {
            setButtonDisabled(true);
            validarUsuario(values)
                .then(res => {
                    setButtonDisabled(false);
                    if (res.error) {
                        let temp = { ...errors }
                        if (res.message.indexOf("Clave") >= 0) {
                            temp.txtClave = res.message
                        } else {
                            temp.txtUsuario = res.message
                        }
                        setErrors({
                            ...temp
                        })
                    } else { //login exitoso 
                        history.push("/seleccionar_contribuyente");
                    }

                })
                .catch(err => {
                    console.log(err);
                    setButtonDisabled(false);
                })
        } else {
            setButtonDisabled(false);
        }
    }

    return (
        <Form onSubmit={handleSubmit} >
            <Grid container component='main' className={classes.root}>
                <CssBaseline />
                <Container component={Paper} elevation={5} maxWidth='xs' className={classes.container}>
                    <div className={classes.div}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component='h1' variant='h5'>
                            Iniciar Sesion
                        </Typography>
                        <Controls.Input
                            fullWidth
                            autoFocus
                            color='Primary'
                            margin='normal'
                            variant='outlined'
                            onChange={handleInputChange}
                            name="txtUsuario"
                            label="Email"
                            value={values.txtUsuario}
                            error={errors.txtUsuario}
                        />

                        <Controls.Input
                            fullWidth
                            type='password'
                            color='Primary'
                            margin='normal'
                            variant='outlined'
                            onChange={handleInputChange}
                            name="txtClave"
                            label="Clave"
                            value={values.txtClave}
                            error={errors.txtClave}
                        />

                        <Controls.Button
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.button}
                            disabled={ButtonDisabled}
                            type="submit"
                            text="Conectar"
                        />
                    </div>
                    <div className="p-mb-3 p-text-center">Olvidé <Link to='/olvide_clave'>Usuario / Contraseña?</Link></div>
										<div className="p-mb-3 p-text-center">Regístrate <Link to='/registro'>aquí</Link></div>
                </Container>
            </Grid>
            <script language="JavaScript" type="text/javascript">TrustLogo("https://micuenta.donweb.com/img/sectigo_positive_md.png", "CL1", "none");</script><a href="https://donweb.com/es-ar/certificados-ssl" id="comodoTL" title="Certificados SSL Argentina">Certificados SSL Argentina</a>
        </Form>
    );
}

