import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData } from '../../service/AuthHelperMethods';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import ShowLoading from "../../components/ShowLoading/ShowLoading";

const initialFValues = {
    cboMonedaO: '',
    cboMonedaD: '',
    txtFechaTasa: '',
    txtTasa: '',
    txtContribuyente: '',
    nIdUsuario_aux: '',
    caso: 0,
    id: '', 
};

export default function AdmTasaCambio() {
    let emptyTproducto = {
        cboMonedaO: '',
        cboMonedaD: '',
        txtFechaTasa: '',
        txtTasa: ''
    };

    const toast = useRef(null);
    const [tCambioDialog, setTCambioDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [userData] = useState(getUserData() || null);
    const [httpService] = useState(new HttpService());
    const [tasas, setTasas] = useState([]);
    const [caso, setCaso] = useState(true);
    const [monedaOrigenOptions, setMonedaOrigenOptions] = useState([]);
    const [monedaDestinoOptions, setMonedaDestinoOptions] = useState([]);
	const [loadingDialog, setLoadingDialog] = useState(false);

    useEffect(() => {
		setLoadingDialog(true);
        httpService.getOptionSelect("lista_NoBolivar").then(data => setMonedaOrigenOptions(data));
        httpService.getOptionSelect("lista_SiBolivar").then(data => setMonedaDestinoOptions(data));
        httpService.getTableSelect("tabla_tasas", userData.contribuyente, '')
		.then(data => { setTasas(data); setLoadingDialog(false); })
		.catch(err => setLoadingDialog(false));
    }, [httpService, userData]);

    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboMonedaO" in fieldValues) temp.cboMonedaO = fieldValues.cboMonedaO ? "" : "Campo Requerido.";
        if ("cboMonedaD" in fieldValues) temp.cboMonedaD = fieldValues.cboMonedaD ? "" : "Campo Requerido.";
        if ("txtFechaTasa" in fieldValues) temp.txtFechaTasa = fieldValues.txtFechaTasa ? "" : "Campo Requerido.";
        if ("txtTasa" in fieldValues) temp.txtTasa = fieldValues.txtTasa ? "" : "Campo Requerido.";
        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

    const handleSubmit = async (e) => {
        setSubmitted(true);
        e.preventDefault();
        if (validate()) {
            setButtonDisabled(true);
            values.txtContribuyente = userData.contribuyente;
            values.nIdUsuario_aux = userData.idUsuario;
            values.caso = caso;
            httpService.registrarTasaConversion(values)
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
                        let temp = { ...errors };
                        setErrors({
                            ...temp,
                        });
                        toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                    } else {
                        //registro exitoso
                        toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                        setTCambioDialog(false);
                        resetForm();
                        httpService.getTableSelect("tabla_tasas", userData.contribuyente, "").then(data => setTasas(data));
                    }
                })
                .catch((err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
                    setButtonDisabled(false);
                });
        }
    };
    const openNew = () => {
        setCaso(0);
        setValues(emptyTproducto);
        setSubmitted(false);
        setTCambioDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setTCambioDialog(false);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedTasas || !selectedTasas.length} />
            </React.Fragment>
        )
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Administrador de Tasas de Conversión</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const tasasDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" disabled={ButtonDisabled} onClick={handleSubmit} />
        </>
    );

    const fechaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha Tasa</span>
                {rowData.txtFecha_aux}
            </>
        );
    }

    const MonedaOBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">De</span>
                {rowData.txtMonedaO_aux}
            </>
        );
    }

    const MonedaDBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">A</span>
                {rowData.txtMonedaD_aux}
            </>
        );
    }

    const TasaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tasa</span>
                {rowData.txtTasa_aux}
            </>
        );
    }


    const UsuarioBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Creador</span>
                {rowData.txtUsuario}
            </>
        );
    }

    const [deleteTasasDialog, setDeleteTasasDialog] = useState(false);

    const [selectedTasas, setSelectedTasas] = useState(null);

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);



    const hideDeleteTasasDialog = () => {
        setDeleteTasasDialog(false);
    }


    const confirmDeleteSelected = () => {
        setDeleteTasasDialog(true);
    }

    const deleteSelectedTasas = async (e) => {
               
        e.preventDefault();
        httpService.deleteTasas(selectedTasas)
            .then((res) => {
                if (res.error) {
                    let temp = { ...errors };
                    setErrors({
                        ...temp,
                    });
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                } else {
                    //Eliminacion exitosa
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                    setDeleteTasasDialog(false);
                    setSelectedTasas(null);
                    httpService.getTableSelect("tabla_tasas", userData.contribuyente, "").then(data => setTasas(data));
                }
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
            });
    }

    const deleteTasasDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTasasDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedTasas} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>

                    <DataTable  ref={dt} value={tasas} selection={selectedTasas} onSelectionChange={(e) => setSelectedTasas(e.value)}
                        dataKey="id" paginator rows={10} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} tipos de productos"
                        globalFilter={globalFilter} emptyMessage="No existen Tasas." header={header} >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="txtFecha_aux" header="Fecha Tasa" sortable body={fechaBodyTemplate}></Column>
                        <Column field="txtMonedaO_aux" header="De" sortable body={MonedaOBodyTemplate}></Column>
                        <Column field="txtMonedaD_aux" header="A" sortable body={MonedaDBodyTemplate}    ></Column>
                        <Column field="txtTasa_aux" header="A" sortable body={TasaBodyTemplate}    ></Column>
                        <Column field="txtUsuario" header="Creador" sortable body={UsuarioBodyTemplate}    ></Column>
                    </DataTable>

                    <Dialog visible={tCambioDialog} style={{ width: '550px' }}  header="Detalles del Tipo Producto" modal className="p-fluid" footer={tasasDialogFooter} onHide={hideDialog}>
                        <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtFechaTasa">Fecha de la Tasa</label>
                                <Calendar showIcon showButtonBar dateFormat="dd/m/yy" locale="es" id="txtFechaTasa" name="txtFechaTasa" value={values.txtFechaTasa} onChange={handleInputChange} monthNavigator yearNavigator yearRange="2010:2030" className={classNames({ 'p-invalid': submitted && !values.txtFechaTasa })} />
                                {submitted && !values.txtFechaTasa && <small className="p-invalid">{errors.txtFechaTasa}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="cboMonedaO">De</label>
                                <Dropdown id="cboMonedaO" name="cboMonedaO" value={values.cboMonedaO} autoFocus onChange={handleInputChange} optionValue="id" optionLabel="title" options={monedaOrigenOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboMonedaO })}></Dropdown>
                                {submitted && !values.cboMonedaO && <small className="p-invalid">{errors.cboMonedaO}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="cboMonedaD">A</label>
                                <Dropdown id="cboMonedaD" name="cboMonedaD" value={values.cboMonedaD} autoFocus onChange={handleInputChange} optionValue="id" optionLabel="title" options={monedaDestinoOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboMonedaD })}></Dropdown>
                                {submitted && !values.cboMonedaD && <small className="p-invalid">{errors.cboMonedaD}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                            <label htmlFor="txtTasa">Tasa</label>
                                <InputNumber name="txtTasa" mode="decimal" minFractionDigits={2} value={values.txtTasa} onValueChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && !values.txtTasa })} />
                                {submitted && !values.txtTasa && <small className="p-invalid">{errors.txtTasa}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                            </div>
                        </div>

                    </Dialog>


                    <Dialog visible={deleteTasasDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteTasasDialogFooter} onHide={hideDeleteTasasDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {<span>Esta Seguro de Eliminar la Tasa seleccionado?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
}
