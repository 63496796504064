import React, { useState, useEffect, useRef } from "react";
import classNames from 'classnames';
import { useForm, Form } from "../../components/useForm";
import { useHistory } from "react-router-dom";
import { HttpService } from '../../service/HttpService';
import { getUserData } from '../../service/AuthHelperMethods';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from  'primereact/inputtext';
import { InputNumber } from  'primereact/inputnumber';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from "primereact/autocomplete";
import { TabView, TabPanel } from 'primereact/tabview';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import ShowLoading from "../../components/ShowLoading/ShowLoading";

const	initialFValues = {
		txtNroDocRL: '',
		txtNombreRL: '',
		txtRazonSocial: '',
		txtRif: '',
		txtDireccion: '',
		txtTelefono1: '',
		txtTelefono2: '',
		txtEmail: '',
		cboTipoContrib: '',
		cboUsaMaquina: "",
		txtCantidadMF : "",
		cboPeriodoFiscal: '',
		fechaCierrePF: null,
		cboTipoActividadComercial: '',
		txtSubActividadComercial: '',
};

const initialFValuesMF = {
	txtSerial : '',
	txtDescripcion : '',
	txtFechaAdquisicion : null,
	caso: null,
	id: '',
	txtContribuyente: '',
	arr_mf: {}
	
};

export const CambioDatos = (props) => {
	const toast = useRef();
	const toast2 = useRef();
	const toast3 = useRef();
	const toast4 = useRef();
	const history = useHistory();

	const [httpService] = useState(new HttpService());
	const [userData] = useState(getUserData() || null);
	const [datosContribuyente, setDatosContribuyente] = useState([]);
	const [submitted, setSubmitted] = useState(false);
	const [submitted2, setSubmitted2] = useState(false);
	const [submitted3, setSubmitted3] = useState(false);
	const [fechaVencimientoRif, setFechaVencimientoRif] = useState(null);
	const [errorFechavctoRif, setErrorFechavctoRif] = useState("");	
	const [loadingDialog, setLoadingDialog] = useState(false);
	const [tabMFvisible, setTabMFvisible] = useState(false);
	const setInitialFValues = (datosContribuyente) => {
		initialFValues.txtNroDocRL = datosContribuyente.sNroDocRepresentanteLegal;
		initialFValues.txtNombreRL = datosContribuyente.sRepresentantLegal;
		initialFValues.txtRazonSocial = datosContribuyente.sRazonSocial;
		initialFValues.txtRif = datosContribuyente.sRif;
		initialFValues.txtDireccion = datosContribuyente.sDireccion;
		initialFValues.txtTelefono1 = datosContribuyente.sTelefono1;
		initialFValues.txtTelefono2 = datosContribuyente.sTelefono2;
		initialFValues.txtEmail = datosContribuyente.sEmail;
		initialFValues.cboTipoContrib = datosContribuyente.nIdTipoContribuyente;
		initialFValues.cboUsaMaquina = datosContribuyente.nUsaMaquinaFiscal;
		if (datosContribuyente.nUsaMaquinaFiscal ===1 ) {
			setTextMaquinafiscal("Si");
			setTabMFvisible(true);
		}
		else {
			setTextMaquinafiscal("No");
			setTabMFvisible(false);
		}
		initialFValues.txtCantidadMF = datosContribuyente.nCantidadMF;

		initialFValues.cboPeriodoFiscal = datosContribuyente.nIdPeriodoFiscal;
		let partFC = (datosContribuyente.dFechaCierrePeriodoFiscal === undefined || datosContribuyente.dFechaCierrePeriodoFiscal === null) ? "" : datosContribuyente.dFechaCierrePeriodoFiscal.split('-');
		initialFValues.fechaCierrePF = new Date(partFC[0], partFC[1] - 1, partFC[2]);
		initialFValues.cboTipoActividadComercial = datosContribuyente.nIdTipoActividadComercial;
		initialFValues.txtSubActividadComercial = datosContribuyente.nIdTipoSubActividadComercial + " - " + datosContribuyente.sTipoSubActComercial;
		setLogoNew(datosContribuyente.sPathLogo);
		
		if (datosContribuyente.dFechaVctoRif === undefined || datosContribuyente.dFechaVctoRif === null){
		} else {
			let partFVR =  datosContribuyente.dFechaVctoRif.split('-');
			let fvr_new = new Date(partFVR[0], partFVR[1] - 1, partFVR[2])
			setFechaVencimientoRif(fvr_new);
		}
	}

	const [caso, setCaso] = useState(null);
	const [activeIndex, setActiveIndex] = useState(0);
	
	const [ButtonDisabled, setButtonDisabled] = useState(false);
	const [tengoDatos, setTengodatos] = useState(false);
	const [tipoContribOptions, seTtipoContribOptions] = useState([]);
	const [tipoActComercialOptions, setTipoActComercialOptions] = useState([]);
	const [periodoFiscalOptions, setPeriodoFiscalOptions] = useState([]);
	const [subActComercialOptions, setSubActComercialOptions] = useState([]);
	const [subActComercialFiltered, setSubActComercialFiltered] = useState(null);
	const [logo, setLogo] = useState(null);
	const [logoNew, setLogoNew] = useState(null);
	const [maquinaFiscal, setMaquinaFiscal] = useState([]);
	const [maquinasFiscales, setMaquinasFiscales] = useState([]);
	const [maquinaFiscalDialog, setMaquinaFiscalDialog] = useState(false);

	const [UptEstatusMFDialog, setUptEstatusMFDialog] = useState(false);
	const [deleteMFDialog, setDeleteMFDialog] = useState(false);
	
	let emptyMaquinaFiscal = {
		txtSerial : '',
		txtDescripcion : '',
		txtFechaAdquisicion : null,
    };

	const [valuesMF, setValuesMF] = useState(initialFValuesMF);
	const [errorsMF,  setErrorsMF] = useState({});
	
	const onValuesMFChange = (e) => {
		const { name, value } = e.target
        setValuesMF({
            ...valuesMF,
            [name]: value
        })
	}

	const usaMaquinaOptions = [
		{ id: 1, title: "Si" }, { id: 0, title: "No" }
	];

	useEffect(() => {
		setLoadingDialog(true);
		httpService.getGeneralData("datos_contribuyente", userData.contribuyente)
		.then(data => { setDatosContribuyente(data); setTengodatos(true); setLoadingDialog(false); })
		.catch(err => setLoadingDialog(false));
		
	}, [httpService, userData]);

	useEffect(() => {
		httpService.getOptionSelect("tipo_contribuyente").then(data => seTtipoContribOptions(data));
		httpService.getOptionSelect("actividad_comercial").then(data => setTipoActComercialOptions(data));
		httpService.getOptionSelect("periodo_fiscal").then(data => setPeriodoFiscalOptions(data));
		httpService.getOptionSelect("sub_actividad_comercial").then(data => setSubActComercialOptions(data));
		httpService.getTableSelect("tabla_maquinas_fiscales", userData.contribuyente).then(data => setMaquinasFiscales(data));
	}, [httpService, userData]);

	useEffect(() => {
		if (tengoDatos) {
			setInitialFValues(datosContribuyente);
		}
	}, [datosContribuyente, tengoDatos])
	
	const validate = (fieldValues = values) => {
		let temp = { ...errors };
		if ("txtNroDocRL" in fieldValues) temp.txtNroDocRL = (fieldValues.txtNroDocRL.trim() !== "" && fieldValues.txtNroDocRL.trim() !== "-") ? "" : "Campo Requerido.";
		if ("txtNombreRL" in fieldValues) temp.txtNombreRL = fieldValues.txtNombreRL ? "" : "Campo Requerido.";
		if ("txtRazonSocial" in fieldValues) temp.txtRazonSocial = fieldValues.txtRazonSocial ? "" : "Campo Requerido.";
		if ("txtRif" in fieldValues) temp.txtRif = (fieldValues.txtRif.trim() !== "-        -" && fieldValues.txtRif.trim() !== "") ? "" : "Campo Requerido.";
		if ("txtDireccion" in fieldValues) temp.txtDireccion = fieldValues.txtDireccion ? "" : "Campo Requerido.";

		if ("txtTelefono1" in fieldValues) temp.txtTelefono1 = (fieldValues.txtTelefono1.trim() !== "+" && fieldValues.txtTelefono1.trim() !== "") ? "" : "Campo Requerido.";
		if ("txtTelefono1" in fieldValues) temp.txtTelefono1 = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(fieldValues.txtTelefono1) ? "" : "Teléfono no valido.";
		if ("txtTelefono2" in fieldValues){
			temp.txtTelefono2 = (fieldValues.txtTelefono2 === "" || fieldValues.txtTelefono2 === null || /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(fieldValues.txtTelefono2)) ? "" : "Teléfono no valido.";	
		} 
		if ("txtEmail" in fieldValues) {
			if (fieldValues.txtEmail === "") {
				temp.txtEmail = "Campor Requerido.";
			} else if (!(/$^|.+@.+..+/.test(fieldValues.txtEmail))) {
				temp.txtEmail = "Email no valido.";
			} else {
				temp.txtEmail = "";
			}
		}
		if ("cboTipoContrib" in fieldValues) temp.cboTipoContrib = fieldValues.cboTipoContrib ? "" : "Campo Requerido.";
		if ("cboTipoActividadComercial" in fieldValues) temp.cboTipoActividadComercial = fieldValues.cboTipoActividadComercial ? "" : "Campo Requerido.";
		if ("cboPeriodoFiscal" in fieldValues) temp.cboPeriodoFiscal = fieldValues.cboPeriodoFiscal ? "" : "Campo Requerido.";
		if ("txtSubActividadComercial" in fieldValues) {
			temp.txtSubActividadComercial = fieldValues.txtSubActividadComercial ? "" : "Campo requerido.";
		}
		if ("cboUsaMaquina" in fieldValues) temp.cboUsaMaquina = fieldValues.cboUsaMaquina ? "" : "Campo Requerido.";
		if ("txtCantidadMF" in fieldValues) temp.txtCantidadMF = fieldValues.txtCantidadMF ? "" : "Campo Requerido.";
		if ("fechaCierrePF" in fieldValues) temp.fechaCierrePF = fieldValues.fechaCierrePF ? "" : "Campo Requerido.";

		setErrors({
			...temp,
		});

		if (fieldValues === values) return Object.values(temp).every((x) => x === "");
	};

	const validateMF = (fieldValues = valuesMF) => {
		let temp = { ...errorsMF };
		if ("txtSerial" in fieldValues) temp.txtSerial = fieldValues.txtSerial ? "" : "Campo Requerido.";
		if ("txtDescripcion" in fieldValues) temp.txtDescripcion = fieldValues.txtDescripcion ? "" : "Campo Requerido.";
		if ("txtFechaAdquisicion" in fieldValues) temp.txtFechaAdquisicion = fieldValues.txtFechaAdquisicion ? "" : "Campo Requerido.";
		
		setErrorsMF({
			...temp,
		});

		if (fieldValues === valuesMF) return Object.values(temp).every((x) => x === "");
	};

	const { values, errors, setErrors, handleInputChange, handleAutoCompleteInputChange, resetForm } = useForm(initialFValues, true, validate);

	const handleSubmit = async (e) => {
		setSubmitted(true);
		e.preventDefault();
		if (validate()) {
			setLoadingDialog(true);
			setButtonDisabled(true);
			values.txtNroDocRL = values.txtNroDocRL.trim();
			values.txtTelefono1 = values.txtTelefono1.trim();
			values.txtTelefono2 = values.txtTelefono2 ? values.txtTelefono2.trim() : null;
			httpService.editarRegistroContribuyente(userData.contribuyente, values)
				.then((res) => {
					
					setButtonDisabled(false);
					if (res.error) {
						setLoadingDialog(false);
						let temp = { ...errors };
						if (res.message.indexOf("Rif") >= 0) {
							temp.txtRif = res.message;
						} else {
							temp.txtEmail = res.message;
						}
						setErrors({
							...temp,
						});
					} else {
						setDatosContribuyente([]);
						resetForm();
						setLoadingDialog(false);
						setSubmitted(false);
						toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
						setTimeout(() => {
							history.push("/dashboard");
						}, 3100);
					}
				})
				.catch((err) => {
					setLoadingDialog(false);
					toast.current.show({ severity: 'error', summary: 'Error', detail: err, life: 3000 });
					setButtonDisabled(false);
				});
		}
	};

	

	const handleFechaVctoChange = e => {
        const { value } = e.target
		setFechaVencimientoRif(value);

    }

	const handleSubmitRif = async (e) => {
		setSubmitted2(true);
		e.preventDefault();
		let valido;
		if (fechaVencimientoRif === "" || fechaVencimientoRif === null){
			setErrorFechavctoRif("Campo requerido.");
			valido = false;
		} else {
			setErrorFechavctoRif("");
			valido = true;
		}

		if (valido){
			setLoadingDialog(true);
			setButtonDisabled(true);
			let valores = {
				"fechaVctoRif" : fechaVencimientoRif
			}
			httpService.udpateRif(userData.contribuyente, valores)
				.then((res2) => {
					setButtonDisabled(false);
					setLoadingDialog(false);
					if (res2.error) {
						toast2.current.show({ severity: 'error', summary: 'Error', detail: res2.message, life: 3000 });
					} else {
						toast2.current.show({ severity: 'success', summary: 'Exito', detail: res2.message, life: 3000 });
						setSubmitted2(false);
						setTimeout(() => {
							history.push("/Principal");
						}, 3100);
					}
				})
				.catch((err2) => {
					setButtonDisabled(false);
					setLoadingDialog(false);
					toast2.current.show({ severity: 'error', summary: 'Error', detail: err2, life: 3000 });
				});
		} else {
			console.log("HAY errores, NO submit");
		}

	};

	const handleSubmitLogo = async (e) => {
		e.preventDefault();
		if (logo){      
			setButtonDisabled(true);                  
			setLoadingDialog(true);
			const f = new FormData();
			f.append("idContribuyente", userData.contribuyente);
			
			for (let index = 0; index<logo.length; index++){
				f.append("logo", logo[index]);
			} 

			httpService.registrarLogoContribuyente(f)
			.then((res) => {
				setButtonDisabled(false);
				setLoadingDialog(false);
				if (res.error) {
					toast3.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
				}else{
					setSubmitted2(false);
					setLogoNew(res.data);
					toast3.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
				}
			})
			.catch((err) => {
				setLoadingDialog(false);
				setButtonDisabled(false);
				toast3.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
			});
		} else {
			toast3.current.show({ severity: 'error', summary: 'Error', detail: "No hay imagen para cargar", life: 3000 });
		}

	};

	const handleSubmitMF = async (e) => {
		setSubmitted3(true);
		e.preventDefault();
		if (caso === 1){
			setButtonDisabled(true);
			setLoadingDialog(true);
			valuesMF.arr_mf = selectedMaquinasFiscales;
			valuesMF.caso = caso;
			valuesMF.txtContribuyente = userData.contribuyente;
			httpService.registrarMF(valuesMF)
				.then((res2) => {
					setButtonDisabled(false);
					if (res2.error) {
						setLoadingDialog(false);
						toast4.current.show({ severity: 'error', summary: 'Error', detail: res2.message, life: 3000 });
					} else {
						setSubmitted3(false);
						setDeleteMFDialog(false);
						httpService.getTableSelect("tabla_maquinas_fiscales", userData.contribuyente)
						.then(data => { 
							setMaquinasFiscales(data); 
							setLoadingDialog(false); 
							toast4.current.show({ severity: 'success', summary: 'Exito', detail: res2.message, life: 3000 });
						})
						.catch(err => {
							setLoadingDialog(false);
							toast4.current.show({ severity: 'success', summary: 'Exito', detail: res2.message, life: 3000 });
						});
						
					}
				})
				.catch((err2) => {
					setLoadingDialog(false);
					setButtonDisabled(false);
					toast4.current.show({ severity: 'error', summary: 'Error', detail: err2, life: 3000 });
				});

		} else if (validateMF()){
			setButtonDisabled(true);
			setLoadingDialog(true);
			if (caso === 2) valuesMF.id = maquinaFiscal.id;
			valuesMF.caso = caso;
			valuesMF.txtContribuyente = userData.contribuyente;
			httpService.registrarMF(valuesMF)
				.then((res2) => {
					setButtonDisabled(false);
					if (res2.error) {
						setLoadingDialog(false);
						toast4.current.show({ severity: 'error', summary: 'Error', detail: res2.message, life: 3000 });
					} else {
						setSubmitted3(false);
						setMaquinaFiscalDialog(false);
						setUptEstatusMFDialog(false);
						httpService.getTableSelect("tabla_maquinas_fiscales", userData.contribuyente)
						.then(data => {
							setMaquinasFiscales(data); 
							setLoadingDialog(false);
							toast4.current.show({ severity: 'success', summary: 'Exito', detail: res2.message, life: 3000 });
						 })
						.catch(err => {
							setLoadingDialog(false);
							toast4.current.show({ severity: 'success', summary: 'Exito', detail: res2.message, life: 3000 });
						});
						
					}
				})
				.catch((err2) => {
					toast4.current.show({ severity: 'error', summary: 'Error', detail: err2, life: 3000 });
					setButtonDisabled(false);
				});
		} else {
			console.log("HAY errores, NO submit");
		}

	};

	const subirArchivos = e=>{
        setLogo(e);
    }

	const searchSubActCom = (event) => {
		const filtered = [];
		const query = event.query;
		for (let i = 0; i < subActComercialOptions.length; i++) {
				const sac = subActComercialOptions[i];
				if (sac.title.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
						filtered.push(sac);
				}
		}
		setSubActComercialFiltered(filtered);
	};

	const [textUsaMaquina, setTextMaquinafiscal] = useState("");
	const setTextMaquinafiscal2 = (e) => {
		let texto = e.originalEvent.nativeEvent.target.innerText;
		setTextMaquinafiscal(texto);
	}

	const [textPeriodoFiscal] = useState("");

	const monthNavigatorTemplate = (options) => {
	}

	const openNew = () => {
		setCaso(0);
        setValuesMF(emptyMaquinaFiscal);
		setButtonDisabled(false);
        setSubmitted3(false);
        setMaquinaFiscalDialog(true);
    }

	const onHide = (methodState) => {
		methodState(false);
	}

	const confirmDeleteSelected = () => {
		setCaso(1);
        setDeleteMFDialog(true);
    }

	const confirmUptEstatusMF = (maquinaFiscal) => {
		setCaso(2);
        setMaquinaFiscal(maquinaFiscal);
		setValuesMF(maquinaFiscal);
        setUptEstatusMFDialog(true);
    }

	const updEstatusMFDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => onHide(setUptEstatusMFDialog)} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" disabled={ButtonDisabled} onClick={handleSubmitMF} />
        </>
    );

	const deleteMFDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => onHide(setDeleteMFDialog)} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" disabled={ButtonDisabled} onClick={handleSubmitMF} />
        </>
    );

	const maquinasFiscalesDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => onHide(setMaquinaFiscalDialog)} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" disabled={ButtonDisabled} onClick={handleSubmitMF} />
        </>
    );

	const header = (
        <div className="table-header">
            {/* <h5 className="p-m-1"></h5> */}
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

	const [selectedMaquinasFiscales, setSelectedMaquinasFiscales] = useState([]);
	const [globalFilter, setGlobalFilter] = useState(null);
	const dt = useRef(null);

	const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedMaquinasFiscales || !selectedMaquinasFiscales.length} />
            </React.Fragment>
        )
    }

	const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon={rowData.sEstatus === "A" ? "pi pi-check-circle" : "pi pi-times-circle"} className={rowData.sEstatus === "A" ? "p-button-rounded p-button-success" : "p-button-rounded p-button-danger"} tooltip={rowData.sEstatus === "A" ? "Desactivar" : "Activar"} onClick={() => confirmUptEstatusMF(rowData)} />
            </div>
        );
    };
	
	const estatusBodyTemplate = (rowData) => {
		return (
			<>
			   <span className="p-column-title"></span>
			   <span className={`product-badge ${rowData.sEstilo}`}>{rowData.txtEstatus}</span>
		   </>
	   );
    };

	const updateDatos = (index) => {
		setActiveIndex(index);
		if (index===0){
			setLoadingDialog(true);
			httpService.getGeneralData("datos_contribuyente", userData.contribuyente)
			.then(data => { setDatosContribuyente(data); setTengodatos(true); setLoadingDialog(false); })
			.catch(err => setLoadingDialog(false));
		}
	}

	return (
		<div className="p-grid">
			<div className="p-col-12">
				<div className="card">
				<h5>Actualización de Datos</h5>
				<TabView activeIndex={activeIndex} onTabChange={(e) => updateDatos(e.index)}>
					<TabPanel header="Datos básicos">
						<Form onSubmit={handleSubmit} id="formEditRegistro">
						<Toast ref={toast} />
							<div className="p-fluid p-formgrid p-grid p-col-12">
								<div className="p-field p-col-12 p-md-6">
									<label htmlFor="txtNroDocRL">Documento de Identidad del Representante Legal</label>
									<InputText onChange={handleInputChange} id="txtNroDocRL" name="txtNroDocRL" value={values.txtNroDocRL} className={classNames({ 'p-invalid': submitted && !values.txtNroDocRL })} />
									{submitted && !values.txtNroDocRL && <small className="p-invalid">{errors.txtNroDocRL}</small>}
								</div>
								<div className="p-field p-col-12 p-md-6">
									<label htmlFor="txtNombreRL">Nombre y Apellido del Representante Legal</label>
									<InputText onChange={handleInputChange} id="txtNombreRL" name="txtNombreRL" value={values.txtNombreRL} className={classNames({ 'p-invalid': submitted && !values.txtNombreRL })} />
									{submitted && !values.txtNombreRL && <small className="p-invalid">{errors.txtNombreRL}</small>}
								</div>
							
								<div className="p-field p-col-12 p-md-6">
									<label htmlFor="txtRazonSocial">Razón Social</label>
									<InputText onChange={handleInputChange} id="txtRazonSocial" name="txtRazonSocial" value={values.txtRazonSocial} className={classNames({ 'p-invalid': submitted && !values.txtRazonSocial })}  />
									{submitted && !values.txtRazonSocial && <small className="p-invalid">{errors.txtRazonSocial}</small>}
								</div>
								<div className="p-field p-col-12 p-md-6">
									<label htmlFor="txtRif">RIF</label>
									<InputText onChange={handleInputChange} id="txtRif" name="txtRif" value={values.txtRif} disabled />
									{submitted && !values.txtRif && <small className="p-invalid">{errors.txtRif}</small>}
								</div>

								<div className="p-field p-col-12">
									<label htmlFor="txtDireccion">Dirección</label>
									<InputText onChange={handleInputChange} id="txtDireccion" name="txtDireccion" value={values.txtDireccion} className={classNames({ 'p-invalid': submitted && !values.txtDireccion })} />
									{submitted && !values.txtDireccion && <small className="p-invalid">{errors.txtDireccion}</small>}
								</div>

								<div className="p-field p-col-12 p-md-6">
									<label htmlFor="txtTelefono1">Teléfono</label>
									<InputText onChange={handleInputChange} id="txtTelefono1" name="txtTelefono1" value={values.txtTelefono1} className={classNames({ 'p-invalid': submitted && !values.txtTelefono1 })} />
									{submitted && !values.txtTelefono1 && <small className="p-invalid">{errors.txtTelefono1}</small>}
								</div>
								<div className="p-field p-col-12 p-md-6">
									<label htmlFor="txtTelefono2">Teléfono 2</label>
									<InputText onChange={handleInputChange} id="txtTelefono2" name="txtTelefono2" value={values.txtTelefono2} placeholder="+589999999" className={classNames({ 'p-invalid': values.txtTelefono2!=="" && errors.txtTelefono2 })} />
									{values.txtTelefono2!=="" && errors.txtTelefono2 && <small className="p-invalid">{errors.txtTelefono2}</small>}
								</div>

								<div className="p-field p-col-12 p-md-6">
									<label htmlFor="txtEmail">Email</label>
									<InputText onChange={handleInputChange} id="txtEmail" name="txtEmail" value={values.txtEmail} disabled />
									{submitted && !values.txtEmail && <small className="p-invalid">{errors.txtEmail}</small>}
								</div>
								<div className="p-field p-col-12 p-md-6">
									<label htmlFor="cboTipoContrib">Tipo de Contribuyente</label>
									<Dropdown id="cboTipoContrib"  name="cboTipoContrib" value={values.cboTipoContrib} onChange={handleInputChange} optionLabel="title" optionValue="id" options={tipoContribOptions} placeholder="Selecciona uno" disabled />
									{submitted && !values.cboTipoContrib && <small className="p-invalid">{errors.cboTipoContrib}</small>}
								</div>

								<div className="p-field p-col-12 p-md-3" >
									<label htmlFor="cboUsaMaquina">Usa máquina fiscal</label>
									<Dropdown id="cboUsaMaquina"  name="cboUsaMaquina" value={values.cboUsaMaquina} onChange={(e) => {handleInputChange(e); setTextMaquinafiscal2(e)}} optionLabel="title" optionValue="id" options={usaMaquinaOptions} disabled placeholder="Selecciona uno" />
									{submitted && !values.cboUsaMaquina && <small className="p-invalid">{errors.cboUsaMaquina}</small>}
								</div>
								<div className="p-field p-col-12 p-md-3">
									<label htmlFor="txtCantidadMF">¿Cantidad?</label>
									<InputNumber name="txtCantidadMF" showButtons value={values.txtCantidadMF} onValueChange={handleInputChange} required disabled={textUsaMaquina==="" || textUsaMaquina==="No" ? true : null} className={classNames({ 'p-invalid': submitted && !values.txtCantidadMF })} />
									
									{submitted && values.txtCantidadMF==="" && <small className="p-invalid">{errors.txtCantidadMF}</small>}
								</div> 

								<div className="p-field p-col-12 p-md-3">
									<label htmlFor="cboPeriodoFiscal">Periodo Fiscal</label>
									<Dropdown id="cboPeriodoFiscal"  name="cboPeriodoFiscal" value={values.cboPeriodoFiscal} onChange={handleInputChange} optionLabel="title" optionValue="id" options={periodoFiscalOptions} placeholder="Selecciona uno" disabled/>
									{submitted && !values.cboPeriodoFiscal && <small className="p-invalid">{errors.cboPeriodoFiscal}</small>}
								</div>
								<div className="p-field p-col-12 p-md-3">
									<label htmlFor="fechaCierrePF">Fecha de Cierre Ejercicio Contable</label>
									<Calendar showIcon showButtonBar locale="es" dateFormat="d/m/yy" id="fechaCierrePF" name="fechaCierrePF" value={values.fechaCierrePF} onChange={handleInputChange} disabled={textPeriodoFiscal==="" || textPeriodoFiscal==="Regular" ? true : null} ></Calendar>
									{submitted && values.fechaCierrePF==="" && <small className="p-invalid">{errors.fechaCierrePF}</small>}
								</div>

								<div className="p-field p-col-12 p-md-6">
									<label htmlFor="cboTipoActividadComercial">Actividad Comercial</label>
									<Dropdown id="cboTipoActividadComercial"  name="cboTipoActividadComercial" value={values.cboTipoActividadComercial} onChange={handleInputChange} optionLabel="title" optionValue="id" options={tipoActComercialOptions} placeholder="Selecciona uno" disabled />
									
								</div>
								<div className="p-field p-col-12 p-md-6">
									<label htmlFor="txtSubActividadComercial">Sub-Actividad Comercial</label>
									<AutoComplete 
										id="txtSubActividadComercial" 
										value={values.txtSubActividadComercial} 
										onChange={(e) => handleAutoCompleteInputChange("txtSubActividadComercial", e.value)} 
										suggestions={subActComercialFiltered} 
										completeMethod={searchSubActCom} 
										field="combinado" 
										disabled >
										</AutoComplete>
								</div>
								
								{/* Boton */}
								<div className="p-field p-col-12 p-md-4" />
								<div className="p-field p-col-12 p-md-4">
									<Button label="Actualizar Datos" type="submit" disabled={ButtonDisabled} />
								</div>
								<div className="p-field p-col-12 p-md-4" />
							</div>
						</Form>
					</TabPanel>
					<TabPanel header="Datos RIF">
						<Form onSubmit={handleSubmitRif} id="formEditRIF">
						<Toast ref={toast2} />
							<div className="p-fluid p-formgrid p-grid p-col-12">
								<div className="p-field p-col-12 p-md-6">
									<label htmlFor="txtRif">RIF</label>
									<InputText id="txtRif2" name="txtRif" value={values.txtRif} disabled />
								</div>
								<div className="p-field p-col-12 p-md-6">
									<label htmlFor="fechaVencimientoRif">Fecha de Vencimiento</label>
									{/* <Calendar showIcon showButtonBar dateFormat="dd/m/yy" id="fechaVencimientoRIF" name="fechaVencimientoRIF" onChange={handleFechaVctoChange} value={fechaVencimientoRif}></Calendar> */}
									<Calendar showIcon showButtonBar locale="es" dateFormat="dd/m/yy" id="fechaVencimientoRIF" name="fechaVencimientoRIF" value={fechaVencimientoRif} onChange={handleFechaVctoChange} monthNavigator yearNavigator yearRange="2010:2030" monthNavigatorTemplate={monthNavigatorTemplate} className={classNames({ 'p-invalid': (fechaVencimientoRif!=="" || fechaVencimientoRif!==null) && errorFechavctoRif })} />
									{submitted2 && (fechaVencimientoRif === "" || fechaVencimientoRif === null) && <small className="p-invalid">{errorFechavctoRif}</small>}
								</div>
								{/* Boton */}
								<div className="p-field p-col-12 p-md-4" />
								<div className="p-field p-col-12 p-md-4">
									<Button label="Actualizar RIF" type="submit" disabled={ButtonDisabled} />
								</div>
								<div className="p-field p-col-12 p-md-4" />
							</div>
						</Form>
                    </TabPanel>
					<TabPanel  disabled={!tabMFvisible} header="Máquinas Fiscales">
						<Toast ref={toast4} />
						<Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>
						<DataTable
                        ref={dt}
                        value={maquinasFiscales}
                        selection={selectedMaquinasFiscales}
                        onSelectionChange={(e) => setSelectedMaquinasFiscales(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Máquinas Fiscales"
                        globalFilter={globalFilter}
                        emptyMessage="No existen Máquinas Fiscales registradas."
                        header={header}
                    >
							<Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
							<Column field="id" header="Id" sortable></Column>
							<Column field="txtSerial" header="Serial" sortable></Column>
							<Column field="txtDescripcion" header="Descripción" sortable></Column>
							<Column field="txtFechaAdquisicion" header="Fecha de adquisición" headerStyle={{textAlign:"center"}} bodyStyle={{textAlign:"center"}} sortable></Column>
							<Column field="txtEstatus" header="Estatus" headerStyle={{textAlign:"center"}} body={estatusBodyTemplate} bodyStyle={{textAlign:"center"}} sortable></Column>
							<Column headerStyle={{ width: "15%" }} body={actionBodyTemplate} bodyStyle={{textAlign:"center"}}></Column>
						</DataTable>

						<Dialog visible={maquinaFiscalDialog} style={{ width: '550px' }}  header="Máquina Fiscal" modal className="p-fluid" footer={maquinasFiscalesDialogFooter} onHide={() => onHide(setMaquinaFiscalDialog)}>
							<InputText type="hidden" name="id" value={valuesMF.id} onChange={handleInputChange} />
							<div className="p-formgrid p-grid">
								<div className="p-field p-col">
									<label htmlFor="txtSerial">Serial</label>
									<InputText name="txtSerial" value={valuesMF.txtSerial} onChange={onValuesMFChange}  className={classNames({ 'p-invalid': submitted3 && !valuesMF.txtSerial })} />
									{submitted3 && !valuesMF.txtSerial && <small className="p-invalid">{errorsMF.txtSerial}</small>}
								</div>
							</div>
							<div className="p-formgrid p-grid">
								<div className="p-field p-col">
									<label htmlFor="txtDescripcion">Descripción</label>
									<InputText name="txtDescripcion" value={valuesMF.txtDescripcion} onChange={onValuesMFChange} className={classNames({ 'p-invalid': submitted3 && !valuesMF.txtDescripcion })} />
									{submitted3 && !valuesMF.txtDescripcion && <small className="p-invalid">{errorsMF.txtDescripcion}</small>}
								</div>
							</div>
							<div className="p-formgrid p-grid">
								<div className="p-field p-col">
									<label htmlFor="txtFechaAdquisicion">Fecha de adquisición</label>
									<div className="p-inputgroup">
										<InputMask id="txtFechaAdquisicion" name="txtFechaAdquisicion" mask="99/99/9999" value={valuesMF.txtFechaAdquisicion} slotChar="dd/mm/yyyy" onChange={onValuesMFChange} className={classNames({ 'p-invalid': submitted3 && !valuesMF.txtFechaAdquisicion })}></InputMask> 
										<span className="p-inputgroup-addon"><i className="pi pi-calendar"></i></span>
									</div>
									{submitted3 && !valuesMF.txtFechaAdquisicion && <small className="p-invalid">{errorsMF.txtFechaAdquisicion}</small>}
								</div>
							</div>
						</Dialog>
						<Dialog visible={UptEstatusMFDialog} style={{ width: '450px' }} header="Confirmar" modal footer={updEstatusMFDialogFooter} onHide={() => onHide(setUptEstatusMFDialog)}>
							<div className="confirmation-content">
								<i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
								{maquinaFiscal && <span>Esta seguro de cambiarle el Estatus a  <b>{maquinaFiscal.txtSerial }</b>?</span>}
							</div>
						</Dialog>

						<Dialog visible={deleteMFDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteMFDialogFooter} onHide={() => onHide(setDeleteMFDialog)}>
							<div className="confirmation-content">
								<i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
								{maquinaFiscal && <span>¿Esta Seguro de Eliminar la Máquina Fiscal?</span>}
							</div>
						</Dialog>
                    </TabPanel>
					<TabPanel header="Logo">
						<Form onSubmit={handleSubmitLogo} id="formEditLogo">
						<Toast ref={toast3} />
							<div className="p-fluid p-formgrid p-grid p-col-6">
								<div className="p-field p-col-12 p-md-6">
									<label htmlFor="txtFotos">Logo</label>
									<input name="txtFotos" type="file" className="form-control" onChange={(e)=>subirArchivos(e.target.files)}/>
								</div>
								{/* Boton */}
								<div className="p-field p-col-12 p-md-4">
									<Button label="Actualizar" type="submit" disabled={ButtonDisabled} />
								</div>
								<div className="p-field p-col-12 p-md-6">
									<img width="300px" src={`assets/images/logos/${logoNew}`} alt={logoNew} /* className="product-image" */ />
								</div>
							</div>
						</Form>
                    </TabPanel>
				</TabView>
				</div>
			</div>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
		</div>
	);
}
