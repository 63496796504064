import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputMask } from "primereact/inputmask";
import { Dropdown } from 'primereact/dropdown';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData } from '../../service/AuthHelperMethods';
import ShowLoading from "../../components/ShowLoading/ShowLoading";

const initialFValues = {
    txtNombre: '',
    txtApellido: '',
    txtEmail: '',
    txtTelefono: '',
    cboTipoUsuario: '',
    cboPerfil: '',
    txtContribuyente: '',
    caso: 0,
    idUser: '', 
};

export default function AdmUsuario() {
    let emptyUsuario = {
        txtNombre: '',
        txtApellido: '',
        txtEmail: '',
        txtTelefono: '',
        cboTipoUsuario: '',
        cboPerfil: '',
        idUser : '',
    };

    const toast = useRef(null);
    const [usuarioDialog, setUsuarioDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [valiEmail, setValiEmail] = useState(false);
    const [userData] = useState(getUserData() || null);
    const [httpService] = useState(new HttpService());
    const [tipoUsuarioOptions, setTipoUsuarioOptions] = useState([]);
    const [perfilOptions, setPerfilOptions] = useState([]);
    const [usuarios, setUsuarios] = useState(null);
    const [usuario, setUsuario] = useState(emptyUsuario);
    const [disabled, setDisabled] = useState(true);
    const [caso, setCaso] = useState(true);
	const [loadingDialog, setLoadingDialog] = useState(false);

    useEffect(() => {
		setLoadingDialog(true);
        httpService.getOptionSelect("tipo_usuario").then(data => setTipoUsuarioOptions(data));
        httpService.getOptionSelect_WithIdContri("perfil_usuario", userData.contribuyente).then(data => setPerfilOptions(data));
        httpService.getTableSelect("tabla_usuarios", userData.contribuyente, "USER_EJECUTIVO")
		.then(data => { setUsuarios(data); setLoadingDialog(false); })
		.catch(err => setLoadingDialog(false));
    }, [httpService, userData]);

    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("txtNombre" in fieldValues) temp.txtNombre = fieldValues.txtNombre ? "" : "Campo Requerido.";
        if ("txtApellido" in fieldValues) temp.txtApellido = fieldValues.txtApellido ? "" : "Campo Requerido.";
        if ("txtEmail" in fieldValues) temp.txtEmail = fieldValues.txtEmail ? "" : "Campo Requerido.";
        if ("txtTelefono" in fieldValues) temp.txtTelefono = fieldValues.txtTelefono ? "" : "Campo Requerido.";
        if ("cboTipoUsuario" in fieldValues) temp.cboTipoUsuario = fieldValues.cboTipoUsuario ? "" : "Campo Requerido.";
        if ("cboPerfil" in fieldValues) temp.cboPerfil = fieldValues.cboPerfil ? "" : "Campo Requerido.";
        if ("txtEmail" in fieldValues) {
            if (fieldValues.txtEmail === "") {
                setValiEmail(true)
                temp.txtEmail = "Campo Requerido.";
            } else if (!(/$^|.+@.+..+/.test(fieldValues.txtEmail))) {
                setValiEmail(true)
                temp.txtEmail = "Email no valido.";
            } else {
                setValiEmail(false)
                temp.txtEmail = "";
            }
        }

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

    const handleSubmit = async (e) => {
        setSubmitted(true);
        e.preventDefault();

        if (validate()) {
            setButtonDisabled(true);
            values.txtContribuyente = userData.contribuyente;
            values.caso = caso;
            httpService.registrarNuevoUsuario(values)
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
                        let temp = { ...errors };
                        if (res.message.indexOf("Email") >= 0) {
                            temp.txtEmail = res.message;
                        }
                        setErrors({
                            ...temp,
                        });
                        toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                    } else {
                        //registro exitoso
                        toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                        setUsuarioDialog(false);
                        resetForm();
                        httpService.getTableSelect("tabla_usuarios", userData.contribuyente, "USER_EJECUTIVO").then(data => setUsuarios(data));
                    }
                })
                .catch((err) => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
                    setButtonDisabled(false);
                });
        }
    };
    const openNew = () => {
        setCaso(0);
        setDisabled(false);
        setValues(emptyUsuario);
        setSubmitted(false);
        setUsuarioDialog(true);
    }

    const editProduct = (usuario) => {
        setCaso(1);
        setDisabled(true);
        setValues(usuario);
        setUsuarioDialog(true);
        console.log(initialFValues);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setUsuarioDialog(false);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo Usuario" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
                <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsuarios || !selectedUsuarios.length} />
            </React.Fragment>
        )
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Administrador de Usuarios</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText id="search" type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const usuarioDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" disabled={ButtonDisabled} onClick={handleSubmit} />
        </>
    );

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.txtNombre}
            </>
        );
    }

    const apellidoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Apellido</span>
                {rowData.txtApellido}
            </>
        );
    }

    const usuarioBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Usuario</span>
                {rowData.txtEmail}
            </>
        );
    }

    const tipoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tipo</span>
                {rowData.sTipoUsuario}
            </>
        );
    }

    const perfilBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Perfil</span>
                {rowData.sPerfil}
            </>
        );
    }

    const estatusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Estatus</span>
                {rowData.estatus}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning p-mr-2" onClick={() => editProduct(rowData)} />
                <Button icon={rowData.iconStatus} className={rowData.iconClass} onClick={() => confirmUptEstatusUsuario(rowData)} />
            </div>
        );
    }

    const confirmUptEstatusUsuario = (usuario) => {
        setUsuario(usuario);
        setUptEstatusUsuarioDialog(true);
    }


    const updEstatusUsuario = async (e) => {
        e.preventDefault();
        values.txtContribuyente = userData.contribuyente;
        values.idUser = usuario.id
        console.log(values);

        httpService.updateEstatusUsuario(values)
            .then((res) => {
                if (res.error) {
                    let temp = { ...errors };
                    if (res.message.indexOf("Email") >= 0) {
                        temp.txtEmail = res.message;
                    }
                    setErrors({
                        ...temp,
                    });
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                } else {
                    //Actualizacion exitosa
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                    setUptEstatusUsuarioDialog(false);
                    setUsuario(emptyUsuario);
                    resetForm();
                    httpService.getTableSelect("tabla_usuarios", userData.contribuyente, "USER_EJECUTIVO").then(data => setUsuarios(data));
                }
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
            });
    };


    const [UptEstatusUsuarioDialog, setUptEstatusUsuarioDialog] = useState(false);

    const hideUpdEstatusUsuarioDialog = () => {
        setUptEstatusUsuarioDialog(false);
        console.log("adiosss")
    }
    const updEstatusUsuarioDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideUpdEstatusUsuarioDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={updEstatusUsuario} />
        </>
    );

    const [deleteUsuariosDialog, setDeleteUsuariosDialog] = useState(false);

    const [selectedUsuarios, setSelectedUsuarios] = useState(null);

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);



    const hideDeleteUsuariosDialog = () => {
        setDeleteUsuariosDialog(false);
    }


    const confirmDeleteSelected = () => {
        setDeleteUsuariosDialog(true);
    }

    const deleteSelectedUsuarios = async (e) => {
        
        console.log(selectedUsuarios);
        
        e.preventDefault();
        httpService.deleteUsuarios(selectedUsuarios)
            .then((res) => {
                if (res.error) {
                    let temp = { ...errors };
                    if (res.message.indexOf("Email") >= 0) {
                        temp.txtEmail = res.message;
                    }
                    setErrors({
                        ...temp,
                    });
                    toast.current.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                } else {
                    //Eliminacion exitosa
                    toast.current.show({ severity: 'success', summary: 'Exito', detail: res.message, life: 3000 });
                    setDeleteUsuariosDialog(false);
                    setSelectedUsuarios(null);
                    httpService.getTableSelect("tabla_usuarios", userData.contribuyente, "USER_EJECUTIVO").then(data => setUsuarios(data));
                }
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: err.message, life: 3000 });
            });
    }

    const deleteUsuariosDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUsuariosDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedUsuarios} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>

                    <DataTable  ref={dt} value={usuarios} selection={selectedUsuarios} onSelectionChange={(e) => setSelectedUsuarios(e.value)}
                        dataKey="id" paginator rows={10} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} usuarios"
                        globalFilter={globalFilter} emptyMessage="No existen Usuarios." header={header} >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="txtNombre" header="Nombre" sortable body={nombreBodyTemplate}></Column>
                        <Column field="txtApellido" header="Apellido" sortable body={apellidoBodyTemplate}></Column>
                        <Column field="txtEmail" header="Usuario" style={{width:'30%'}} sortable body={usuarioBodyTemplate} ></Column>
                        <Column field="sTipoUsuario" header="Tipo" sortable body={tipoBodyTemplate}></Column>
                        <Column field="sPerfil" header="Perfil" sortable body={perfilBodyTemplate}></Column>
                        <Column field="estatus" header="Estatus" sortable body={estatusBodyTemplate}    ></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={usuarioDialog} style={{ width: '450px' }} header="Detalles del Usuario" modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideDialog}>
                        {usuario.image && <img src={`assets/demo/images/product/${usuario.image}`} alt={usuario.image} className="product-image" />}
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtNombre">Nombre</label>
                                <InputText id="txtNombre" name="txtNombre" value={values.txtNombre} onChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && !values.txtNombre })} />
                                {submitted && !values.txtNombre && <small className="p-invalid">{errors.txtNombre}</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="txtApellido">Apellido</label>
                                <InputText id="txtApellido" name="txtApellido" value={values.txtApellido} onChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && !values.txtApellido })} />
                                {submitted && !values.txtApellido && <small className="p-invalid">{errors.txtApellido}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="cboTipoUsuario">Tipo de Usuario</label>
                                <Dropdown id="cboTipoUsuario" name="cboTipoUsuario" value={values.cboTipoUsuario} onChange={handleInputChange} optionValue="id" optionLabel="title" options={tipoUsuarioOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboTipoUsuario })}></Dropdown>
                                {submitted && !values.cboTipoUsuario && <small className="p-invalid">{errors.cboTipoUsuario}</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="cboPerfil">Perfil</label>
                                <Dropdown id="cboPerfil" name="cboPerfil" value={values.cboPerfil} onChange={handleInputChange} optionValue="id" optionLabel="title" options={perfilOptions} placeholder="Selecciona uno" required className={classNames({ 'p-invalid': submitted && !values.cboPerfil })}></Dropdown>
                                {submitted && !values.cboPerfil && <small className="p-invalid">{errors.cboPerfil}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtEmail">Email</label>
                                <InputText id="txtEmail" name="txtEmail" disabled={disabled} value={values.txtEmail} onChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && valiEmail })} />
                                {submitted && valiEmail && <small className="p-invalid">{errors.txtEmail}</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="txtTelefono">Telefono</label>
                                <InputMask id="txtTelefono" name="txtTelefono" mask="+999999999999"  value={values.txtTelefono} onChange={handleInputChange} autoFocus required className={classNames({ 'p-invalid': submitted && !values.txtTelefono })} />
                                {submitted && !values.txtTelefono && <small className="p-invalid">{errors.txtTelefono}</small>}
                            </div>
                        </div>
                    </Dialog>


                    <Dialog visible={UptEstatusUsuarioDialog} style={{ width: '450px' }} header="Confirm" modal footer={updEstatusUsuarioDialogFooter} onHide={hideUpdEstatusUsuarioDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {usuario && <span>Esta seguro de cambiarle el Estatus a  <b>{usuario.txtNombre + ' ' + usuario.txtApellido}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteUsuariosDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteUsuariosDialogFooter} onHide={hideDeleteUsuariosDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {usuario && <span>Esta Seguro de Eliminar el usuario seleccionado?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
}
