import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData } from '../../service/AuthHelperMethods';
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import {Tooltip} from 'primereact/tooltip';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import ShowLoading from "../../components/ShowLoading/ShowLoading";
import Swal from "sweetalert2";


const initialFValues = {
    txtFechaDesde: '',
    txtFechaHasta: '',
    txtContribuyente: '',
    id: ''
};

export const LibroDiario = () => {

	const toast = useRef(null);
	const [cuentasAsiento, setCuentasAsiento] = useState([]);
    const httpService = new HttpService();
    const userData = getUserData() || null;
    const [submitted, /* setSubmitted */] = useState(false);
    const [fdesde, setFDesde] = useState("");
    const [fhasta, setFHasta] = useState("");
    const [displayLibro, setDisplayLibro] = useState(false);
	const [totalDebe, setTotalDebe] = useState(0);
	const [totalHaber, setTotalHaber] = useState(0);
	const [loadingDialog, setLoadingDialog] = useState(false);

    let razon_social_aux = userData.razon_social;
    let direccion_aux = userData.direccion;
    let rif_aux = userData.rif;

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboProducto" in fieldValues) temp.cboProducto = fieldValues.cboProducto ? "" : "Campo Requerido.";
        if ("cboUoM" in fieldValues) temp.cboUoM = fieldValues.cboUoM ? "" : "Campo Requerido.";

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);
	
    const cols = [
        { field : 'dtFechaAsiento', header: 'Fecha' },
		{ field : 'sCodigoCta', header : 'Código Cuenta'},
		{ field : 'sNombre', header : 'Descripción de la Cuenta'},
		{ field : 'debe', header : 'Debe'},
		{ field : 'haber', header : 'Haber'}
    ];

    const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));

	const formatLibroDiario = () => {
		var libroDiario = [];
		let arr = [];
		let asi_ant = '';
		let debe = "";
		let haber = "";

		cuentasAsiento.forEach(asi => {
			if (asi.nNroAsiento !== asi_ant){
				arr = {
					"dtFechaAsiento" : "Asiento N° " + asi.nNroAsiento ,
					"sCodigoCta" : "P/R: " + asi.sConcepto,
					"sNombre" : "",
					"deMontoMovCont" : ""
				}
				libroDiario.push(arr);
			}
			if (asi.cTipoRegMov === "D") {
				debe = parseFloatBs(asi.deMontoMovCont);
				haber = "";
			} else {
				debe = "";
				haber = parseFloatBs(asi.deMontoMovCont);
			}
			let  info = asi.dtFechaAsiento.split('-');
			let fecha = info[2] + '/' + info[1] + '/' + info[0];
			arr = {
				"dtFechaAsiento" : fecha,
				"sCodigoCta" : asi.sCodigoCta,
				"sNombre" : asi.sNombre,
				"debe" : debe,
				"haber" : haber
			}
			libroDiario.push(arr);
			asi_ant = asi.nNroAsiento;
						
		});
		return libroDiario;
	}

    const exportPdf = () => {
		const doc = new jsPDF();
		doc.setFontSize(10);
		doc.setFont("helvetica", "bold");
		doc.text(userData.razon_social, 15, 10);
		doc.setFont("helvetica", "normal");
		doc.text(userData.direccion, 15, 15);
		doc.text("RIF: "+ userData.rif, 15, 20);
		doc.setFont("helvetica", "bold");
		doc.text("LIBRO DIARIO", 15, 30);
		doc.text(`DEL ${fdesde} AL ${fhasta}`, 15, 35);
		
		let total_d = parseFloatBs(totalDebe);
		let total_h = parseFloatBs(totalHaber);
		let libroD = [];
		libroD = formatLibroDiario();
		
		doc.autoTable({
			margin: { top: 40 },
			pageBreak: 'auto',
			columns: exportColumns,
			body: libroD,
			foot:  [["", "", 'TOTAL DIARIO GENERAL', total_d, total_h]],
			headStyles: {
				fontSize: 10,
				halign: 'center'
			},
			bodyStyles: {
				fontSize: 9,
				halign: 'center',
			},
			footStyles: {
				fontSize: 9,
				halign: 'center',
				fillColor: '#F9F9F9',
				textColor: "black" 
			},
			columnStyles: {
				sNombre: { halign: 'left', },
				debe : { halign: 'center', },
				haber: { halign: 'center', },
			},
		});
		doc.save('libro_diario.pdf');
    }

    const exportExcel = () => {
		let libroD = [];
		libroD = formatLibroDiario();

        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(libroD);
            const workbook = { Sheets: { 'libro_diario': worksheet }, SheetNames: ['libro_diario'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'libro_diario');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" tooltipOptions={{position: 'top'}} data-pr-tooltip="Expotar a XLS" />
                <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-danger p-mr-2" tooltipOptions={{position: 'top'}} data-pr-tooltip="Exportar a PDF" />
            </React.Fragment>
        )
    }

	const fechaBodyTemplate = (rowData) => {
		var info = rowData.dtFechaAsiento.split('-');
		var fecha =  info[2] + '/' + info[1] + '/' + info[0];
        return fecha;
    }
	const codigoCuentaBodyTemplate = (rowData) => {
        return rowData.sCodigoCta;
    }
	const descripcionBodyTemplate = (rowData) => {
        return rowData.sNombre;
    }
	const debeBodyTemplate = (rowData) => {
		let monto = rowData.cTipoRegMov === "D" ? parseFloatBs(rowData.deMontoMovCont) : "";
        return monto;
    }
	const haberBodyTemplate = (rowData) => {
        let monto = rowData.cTipoRegMov === "H" ? parseFloatBs(rowData.deMontoMovCont) : "";
        return monto;
    }

	const parseFloatBs = (number) =>{
		const options = { minimumFractionDigits: 2}
		const numberFormat1 = new Intl.NumberFormat('es-VE' , options);
		const numero = numberFormat1.format(number);
		return numero;
	}

	const notifyAlert = (textIn) => {
		Swal.fire({
		  title: "Aviso!",
		  text: textIn,
		  icon: "info",
		  confirmButtonText: "Cerrar",
		});
	  };

    const GenerarLibroDiario = () => {
		if (values.txtFechaDesde === "" || values.txtFechaHasta === "") {
			notifyAlert("Debe indicar el periodo a consultar");
		} else if (values.txtFechaDesde > values.txtFechaHasta) {
			notifyAlert("La fecha inicial no puede ser mayor a la fecha final de consulta");
		} else {
			setLoadingDialog(true);
			var fd = new Date(values.txtFechaDesde);
			setFDesde(`${(fd.getDate())}`.padStart(2,'0') + "/"+ `${(fd.getMonth()+1)}`.padStart(2,'0')+ "/" + fd.getFullYear());

			var fh = new Date(values.txtFechaHasta);
			setFHasta(`${(fh.getDate())}`.padStart(2,'0') + "/"+ `${(fh.getMonth()+1)}`.padStart(2,'0')+ "/" + fh.getFullYear());

			httpService.genearLibroDiario(userData.contribuyente, values.txtFechaDesde, values.txtFechaHasta)
			.then(data => {
				// setLibroDiario(data.data.asientos);
				setCuentasAsiento(data.data.asientos);
				setTotalDebe(data.data.totalD);
				setTotalHaber(data.data.totalH);
						
				setLoadingDialog(false);
				setDisplayLibro(true);
			})
			.catch(err => {
				console.log(err);
				setLoadingDialog(false);
			});
		}
    };

	let footerGroup = <ColumnGroup>
		<Row>
			<Column colSpan={3} footer="TOTAL DIARIO GENERAL:" footerStyle={{ textAlign: 'right' }} />
			<Column footer={parseFloatBs(totalDebe)} footerStyle={{ textAlign: 'center' }}/>
			<Column footer={parseFloatBs(totalHaber)} footerStyle={{ textAlign: 'center' }}/>
		</Row>
    </ColumnGroup>;

	const headerRowGroup = (data) => {
		return (
			<>
			<span style={{ verticalAlign: 'middle' }}><center><strong>Asiento N° {data.nNroAsiento}</strong></center></span>
			</>
		);
	};
	
	const footerRowGroup = (data) => {
        return (
            <>
				<td colSpan="1"></td>
                <td colSpan="4"><strong>P/R: {data.sConcepto}</strong></td>
            </>
        )
    };

	return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <h5>Libro Diario</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="txtFechaDesde">Fecha Desde</label>
                            <Calendar showIcon showButtonBar locale="es" dateFormat="dd/m/yy" id="txtFechaDesde" name="txtFechaDesde" value={values.txtFechaDesde} onChange={handleInputChange} monthNavigator yearNavigator yearRange="2010:2030" className={classNames({ 'p-invalid': submitted && !values.txtFechaDesde })} />
                            {submitted && !values.txtFechaDesde && <small className="p-invalid">{errors.txtFechaDesde}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="txtFechaHasta">Fecha Hasta</label>
                            <Calendar showIcon showButtonBar locale="es" dateFormat="dd/m/yy" id="txtFechaHasta" name="txtFechaHasta" value={values.txtFechaHasta} onChange={handleInputChange} monthNavigator yearNavigator yearRange="2010:2030" className={classNames({ 'p-invalid': submitted && !values.txtFechaHasta })} />
                            {submitted && !values.txtFechaHasta && <small className="p-invalid">{errors.txtFechaHasta}</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <React.Fragment>
                                <Button label="Consultar" icon="pi pi-add" className="p-button-info p-mr-2" onClick={GenerarLibroDiario} />
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12">
                <div className="card"  hidden={!displayLibro}>
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <Tooltip target=".export-buttons>button" position="bottom" />
					<div className="card">
						<div className="p-col-12" style={{ textAlign: 'center', fontSize: "15px" }}>
                            <span style={{ fontWeight: "bold" }}>{razon_social_aux.toUpperCase()}</span><br />
							<span>{direccion_aux.toUpperCase()}</span><br />
							<span>{'RIF: ' + rif_aux.toUpperCase()}</span><br />
							<span style={{ fontStyle: "italic", fontSize: "14px" }}>{'LIBRO DIARIO DEL ' + fdesde + ' AL ' + fhasta}</span><br />
						</div>
					</div>
                    <div className="card">
						<DataTable value={cuentasAsiento} rowGroupMode="subheader" groupField="nNroAsiento" sortMode="single" sortField="nNroAsiento" sortOrder={1}
							rowGroupHeaderTemplate={headerRowGroup} rowGroupFooterTemplate={footerRowGroup} footerColumnGroup={footerGroup}>
							<Column field="dtFechaAsiento" header="Fecha" body={fechaBodyTemplate} headerStyle={{width: "20%", textAlign: "center"}} bodyStyle={{textAlign: "center"}}></Column>
							<Column field="sCodigoCta" header="Código Cuenta" body={codigoCuentaBodyTemplate} headerStyle={{width: "20%", textAlign: "center"}} bodyStyle={{textAlign: "center"}}></Column>
							<Column field="sNombre" header="Descripción de la Cuenta"  body={descripcionBodyTemplate} headerStyle={{width: "40%", textAlign: "left"}} bodyStyle={{textAlign: "left"}}></Column>
							<Column field="deMontoMovCont" header="Debe"  body={debeBodyTemplate} headerStyle={{width: "20%", textAlign: "center"}} bodyStyle={{textAlign: "center"}}></Column>
							<Column field="deMontoMovCont" header="Haber"  body={haberBodyTemplate} headerStyle={{width: "20%", textAlign: "center"}} bodyStyle={{textAlign: "center"}}></Column>
						</DataTable>
						
					</div>
                </div>
            </div>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
};
