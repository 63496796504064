import React, { useState, useEffect, useRef } from "react";
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from 'primereact/inputnumber';
import { FileUpload } from 'primereact/fileupload';
import { useForm } from "../../components/useForm";
import { HttpService } from "../../service/HttpService";
import { getUserData, configPermisosRW } from "../../service/AuthHelperMethods";
import { Dropdown } from "primereact/dropdown";
import { TabView, TabPanel } from "primereact/tabview";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import { InputMask } from 'primereact/inputmask';
import { PDFViewer } from "@react-pdf/renderer";
import Factura from "../../components/FacturaTemplate/Factura";
import ShowLoading from "../../components/ShowLoading/ShowLoading";
import Constantes from '../../Constantes';
import { Calendar } from 'primereact/calendar';

const initialFValues = {
    txtFechaFactura: "",
    cboMoneda: "",
    cboCliente: "",
    txtObservacion: "",
    txtContribuyente: "",
    txtEstatus: "",
    caso: 0,
    id: "",
    cboFormaDePago: "",
    cboCondicionDePago: "",
    txtNroFactura: "",
    txtNroControl: "",
    nIdCliente_aux: 0,
    nIdUsuario_aux: 0,
	txtTotalImpuesto: 0,
	txtNroComprobanteRet: "",
	txtFechaRetencion: "",
	cboPctRetencion: "",
	txtMontoRetenido: "",
};


export default function ConsultaDeVentas() {
    /* let emptyReporteZ = {
        txtFechaReporteZ: "",
        cboMonedaReporteZ: '',
        txtNroReporteRZ: '',
        cboMaquinaFiscalRZ: '',
        txtFacturaDesdeRZ: '',
        txtFacturaHastaRZ: '',
        txtTotalBIRZ: '',
        txtImpuestoRZ: '',
        txtTotalImpuestoRZ: '',
        id: '', 
    }; */

    let emptyFactura = {
        idFactura: "",
        txtNroFactura: "",
        txtNroControl: "",
        txtFechaFactura: "",
        cboCliente: "",
        txtCliente: "",
        cboMoneda: "",
        cboFormaDePago: "",
        cboCondicionDePago: "",
        txtObservacion: "",
        txtEstatus: "",
        txtContribuyente: "",
        nIdCliente_aux: 0,
        nIdUsuario_aux: 0,
		/** Para impuesto retenido */
		txtTotalImpuesto: 0,
        txtNroComprobanteRet: "",
        txtFechaRetencion: "",
        cboPctRetencion: "",
        txtMontoRetenido: "",
    };

	let emptyRetencion = {
        idFactura: "",
        txtTotalImpuesto: null,
        txtNroComprobanteRet: "",
        txtFechaRetencion: "",
        cboPctRetencion: "",
        txtMontoRetenido: "",
        txtContribuyente: "",
        nIdUsuario_aux: 0,
    };

	let location = useLocation();
	const [acceso, setAcceso] = useState("");

    const [MonedaOptions, setMonedaOptions] = useState([]);
    const [MonedaBsOptions, setMonedaBsOptions] = useState([]);
    const [/* ClienteOptions */, setClienteOptions] = useState([]);
    const [/* uoMOptions */, setUoMOptions] = useState([]);
    const [impuestosOptions, setImpuestosOptions] = useState([]);
    const [/* productoOptions */, setProductoOptions] = useState([]);
	const [pctRetencionIvaOptions, setPctRetencionIvaOptions] = useState([]);
    const [facturaDialog, setFacturaDialog] = useState(false);
	const [facturaData, setFacturaData] = useState([]);
	const [tipoFacturaImp, setTipoFacturaImp] = useState("");
	const [margenFactura, setMargenFactura] = useState([]);
	const [margenFacturaFL, setMargenFacturaFL] = useState([]);
	const [margenFacturaFD, setMargenFacturaFD] = useState([]);
	const [deleteVentasDialog, setDeleteVentasDialog] = useState(false);
	const [rutaTemplateCarga, setRutaTemplateCarga] = useState([]);
    const [/* rutaTemplateReporteZ */, setRutaTemplateReporteZ] = useState([]);
	const [montoRetenido, setMontoRetenido] = useState(null);

    const [VentaDialog, setVentaDialog] = useState(false);
    const [comprobanteRetDialog, setComprobanteRetDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [userData] = useState(getUserData() || null);
    
    const [httpService] = useState(new HttpService());
    const [ventas, setVentas] = useState([]);
    const [venta, /* setVenta */] = useState(emptyFactura);
    const [/* disabled */, setDisabled] = useState(true);
    const [caso, setCaso] = useState(true);
    const [productos, setProductos] = useState([]);
    const [pagos, setPagos] = useState([]);
    const [formasDePagoOptions, setFormasDePagoOptions] = useState([]);
    const [maquinasFiscalesOptions, setMaquinasFiscalesOptions] = useState([]);
    const [condicionesDePagoOptions, setCondicionesDePagoOptions] = useState([]);
	const [tipoFacturaDialog, setTipoFacturaDialog] = useState(false);
	const [loadingDialog, setLoadingDialog] = useState(false);
    const history = useHistory();

	const [/* tasaCambioToBs */, setTasaCambioToBs] = useState([]);
	const [IGTF, setIGTF] = useState([]);

    const [ReporteZDialog, setReporteZDialog] = useState(false);
		
    const [selectedVentas, setSelectedVentas] = useState(null);

	useEffect(() => {
        let acc = configPermisosRW(location, userData);
		setAcceso(acc);
		setLoadingDialog(true);
        httpService.getTableSelect("tabla_ventas", userData.contribuyente).then((data) => {setVentas(data); setLoadingDialog(false);});
        httpService.getOptionSelect("lista_UoM").then((data) => setUoMOptions(data));
        httpService.getOptionSelect("lista_productos_venta", userData.contribuyente).then((data) => setProductoOptions(data));
        httpService.getOptionSelect("lista_Monedas").then((data) => setMonedaOptions(data));
        httpService.getOptionSelect("lista_SiBolivar").then((data) => setMonedaBsOptions(data));
        httpService.getOptionSelect("lista_clientes", userData.contribuyente).then((data) => setClienteOptions(data));
        httpService.getOptionSelect("lista_impuestos").then((data) => setImpuestosOptions(data));
        httpService.getOptionSelect("lista_formasDePago").then((data) => setFormasDePagoOptions(data));
        httpService.getOptionSelect("lista_maquinasFiscales", userData.contribuyente).then(data => {setMaquinasFiscalesOptions(data);}).catch(err => {});
        httpService.getOptionSelect("lista_condicionesDePago").then((data) => setCondicionesDePagoOptions(data));
        httpService.getOptionSelect("lista_pct_retencion").then((data) => setPctRetencionIvaOptions(data));
		httpService.getGeneralData("margenes_factura_FL").then((data) => setMargenFacturaFL(data));
		httpService.getGeneralData("margenes_factura_FD").then((data) => setMargenFacturaFD(data));
		httpService.getGeneralData("variable_sistema", "RutaTemplateVentasLote").then((data) => setRutaTemplateCarga(data));
        httpService.getGeneralData("variable_sistema", "RutaTemplateReporteZ").then((data) => setRutaTemplateReporteZ(data));
		httpService.getGeneralData("tasa_cambio_to_BS", {"txtContribuyente": userData.contribuyente, "monedaF": 0}).then(data => { setTasaCambioToBs(data) })
    }, [httpService, userData, location]);

    const [ButtonDisabled, setButtonDisabled] = useState(false);
    
	const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("txtFechaRetencion" in fieldValues) temp.txtFechaRetencion = fieldValues.txtFechaRetencion ? "" : "Campo Requerido.";
        if ("txtNroComprobanteRet" in fieldValues) temp.txtNroComprobanteRet = fieldValues.txtNroComprobanteRet ? "" : "Campo Requerido.";
        if ("txtTotalImpuesto" in fieldValues) temp.txtTotalImpuesto = fieldValues.txtTotalImpuesto ? "" : "Campo Requerido.";
		if ("cboPctRetencion" in fieldValues) temp.cboPctRetencion = fieldValues.cboPctRetencion ? "" : "Campo Requerido.";
        if ("txtMontoRetenido" in fieldValues) temp.txtMontoRetenido = fieldValues.txtMontoRetenido ? "" : "Campo Requerido.";
        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

    const guardarRetencion = async (e) => {
        setSubmitted(true);
        e.preventDefault();
		values.txtContribuyente = userData.contribuyente;
		values.txtMontoRetenido = montoRetenido;
		let impSelect = pctRetencionIvaOptions.find((imp) => imp.id === values.cboPctRetencion);
        let impSelectValor = impSelect.valor;
		values.sPctRetenido = impSelectValor;
		if (validate()) {
			setButtonDisabled(true);
			httpService.agregarRetencionVenta(values).then((res) => {
				setButtonDisabled(false);
				if (res.error) {
					swal({ title: "Ups...", text: res.message, icon: "error" });
				} else {
					//registro exitoso
					swal({ title: "Éxito", text: res.message, icon: "success" });
					setComprobanteRetDialog(false);
					httpService.getTableSelect("tabla_ventas", userData.contribuyente).then((data) => {setVentas(data); });
				}
			})
			.catch((err) => {
				swal({ title: "Ups...", text: err.message, icon: "error" });
				setButtonDisabled(false);
			});

		}         
    };

    const handleSubmit = async (e) => {
        setSubmitted(true);
        e.preventDefault();
            setButtonDisabled(true);
            values.txtContribuyente = userData.contribuyente;
            values.txtusuario = userData.idUsuario;
            values.caso = caso;
            httpService.registrarItemsreporteZ(values)
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
                        let temp = { ...errors };
                        setErrors({
                            ...temp,
                        });
                        swal({ title: "Ups...", text: res.message, icon: "error" });
                    } else {
                        //registro exitoso
                        swal({ title: "Éxito", text: res.message, icon: "success" });
                        setReporteZDialog(false);
                        resetForm();
                    }
                })
                .catch((err) => {
                    swal({ title: "Ups...", text: err.message, icon: "error" });
                    setButtonDisabled(false);
                });
    };

    const detalleVenta = (venta) => {
        setCaso(1);
        setDisabled(true);
        setValues(venta);
        httpService.getTableSelect("tabla_item_facturaC", userData.contribuyente, venta.id).then((data) => setProductos(data));
		httpService.getTableSelect("tabla_pagos_facturaC", userData.contribuyente, venta.id).then((data) => {
			let a_pagos = data;
			let a_igtf = [];
			let igtf = 0;
			let igtf_con = 0;
			a_pagos.forEach(p => {
				if (p.pctIGTF !== "0.00") {
					igtf +=  parseFloat(p.totalIGTF);
					igtf_con += parseFloat(p.totalIGTF_con);
					a_igtf[p.moneda] = { "tot": igtf, "tot_con": igtf_con, "pct": parseInt(p.pctIGTF)} ;
				}
				
			});
			
			setIGTF(a_igtf);
			setPagos(data);
			
		});
        setVentaDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setVentaDialog(false);
    };
    const hideReporteZDialog = () => {
        setReporteZDialog(false);
    };

	/* const openNewPagoVenta = () => {
    } */

    const linkFacturar = () => {
        history.push({
			pathname: '/registro_venta',
			state: ""
		  });
		
    };
    const linkFacturarProps = (data) => {
        history.push({
			pathname: '/registro_venta',
			state: { data }
		  });
		
    };

	const deleteSelectedVentas = (e) => {
        e.preventDefault();
        httpService.anularVentas(selectedVentas)
            .then((res) => {
                if (res.error) {
                    setDeleteVentasDialog(false);
                    swal({ title: "Ups...", text: res.message, icon: "error" });
                } else {
                    //Eliminacion exitosa
                    swal({ title: "Éxito", text: res.message, icon: "success" });
                    setDeleteVentasDialog(false);
                    setSelectedVentas([]);
                    httpService.getTableSelect("tabla_ventas", userData.contribuyente).then(data => setVentas(data));
                }
            })
            .catch((err) => {
                swal({ text: err.message, icon: "error", timer: "3000" });
            });
    }

	const onUploadFile = (e) => {
		
		const xhr = e.xhr;
		const res = JSON.parse(xhr.response);
		const status = xhr.status;
		
		if (status === 200){
			if (!res.error) {
				swal({ title: 'Exito', text: res.message, icon: "success" });
				httpService.getTableSelect("tabla_ventas", userData.contribuyente).then((data) => setVentas(data));
			} else {
				swal({ title: 'Ups...', text: res.message, icon: "error" });	
			}
		} else {
			swal({ title: 'Ups...', text: 'Error de comunicación', icon: "error" });
		}
    }
	
	const submitFile = (e) => {
	}

    /* const openNew = () => {
        setCaso(0);
        setValues(emptyReporteZ);
        setSubmitted(false);
        setReporteZDialog(true);
    } */
	
    /* const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Carga Reporte Z" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} disabled={ acceso && acceso === "W" ? false : true } />
            </React.Fragment>
        )
    } */
    
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
				<a target="_blank" href={rutaTemplateCarga.sObservacion} rel="noreferrer" className="p-mr-5">Descargar plantilla  <i className="pi pi-download"></i>&nbsp;&nbsp;</a>
				<FileUpload mode="basic" accept="*" name="ventas_lote" url={`${Constantes.RUTA_API}/importar_ventas_lote.php?idC=${userData.contribuyente}&idU=${userData.idUsuario}`} maxFileSize={1000000} label="Cargar Ventas" chooseLabel="Cargar Ventas" onUpload={onUploadFile} uploadHandler={submitFile} className="p-mr-2 p-d-inline-block" disabled={ acceso && acceso === "W" ? false : true } />
            </React.Fragment>
        );
    };

	/* const leftToolbarTemplateP = () => {
        return (
            <React.Fragment>
                <Button label="Agregar" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={openNewPagoVenta} disabled={ acceso && acceso === "W" ? false : true } />
            </React.Fragment>
        )
    } */

    const confirmDeleteSelected = () => {
        setDeleteVentasDialog(true);
    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <React.Fragment>
                    <Button label="Nueva Factura" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={linkFacturar} disabled={ acceso && acceso === "W" ? false : true } />
                    <Button label="Anular" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={ acceso && acceso === "W" ? (!selectedVentas || !selectedVentas.length) : true} />
                </React.Fragment>
            </span>
            <h5 className="p-m-0">Consulta de Ventas/Facturas</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const VentasDialogFooter = (
        <>
            {/* <Button label="Confirmar Orden" icon="pi pi-add" className="p-button-success p-mr-2" onClick={confirmConfirmarVenta} /> */}
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

	const deleteFacturaDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => onHide(setDeleteVentasDialog)} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedVentas} />
        </>
    );

    const ReporteZDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideReporteZDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" disabled={ButtonDisabled} onClick={handleSubmit} />
        </>
    );

    const ProductoBodyTemplate = (rowData) => {
        return <>{rowData.sProducto}</>;
    };

    const CantidadBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtCantidad)}`;
    };

    const UoMBodyTemplate = (rowData) => {
        return <>{rowData.sUoM}</>;
    };

    const PrecioUnitBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtPrecioUnit)} ${rowData.sMoneda}`;
    };

    const ImpuestoBodyTemplate = (rowData) => {
        return <>{rowData.sImpuesto}</>;
    };

    const DescuentoBodyTemplate = (rowData) => {
        return <>{rowData.dePctDescuento + " %"}</>;
    };

    const SubTotalBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.txtSubTotal)} ${rowData.sMoneda}`;
    };

    const MonedaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Moneda</span>
                {rowData.txtMoneda}
            </>
        );
    };

    const TotalBodyTemplate = (rowData) => {
		let total_igtf = 0;
		if (rowData.cboMoneda === 27){
			total_igtf = rowData.txtTotalIGTF_con === null ? 0 : rowData.txtTotalIGTF_con;
		} else {
			total_igtf = rowData.txtTotalIGTF === null ? 0 : rowData.txtTotalIGTF;
		}
		let total = parseFloat(rowData.txtTotal) + parseFloat(total_igtf);
		return `${formatCurrency(total)}`;

    };

    const formatCurrency = function (number) {
		const options = { minimumFractionDigits: 2, maximumFractionDigits: 2}
		const numberFormat1 = new Intl.NumberFormat('es-VE' , options);
		const numero = numberFormat1.format(number);
		return numero;
    };

    const NumeroBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Numero</span>
                { rowData.txtNroFactura=== "" ? "-" : rowData.txtNroFactura}
            </>
        );
    };

    const FechaVentaTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Fecha</span>
                {rowData.txtFechaFactura}
            </>
        );
    };

    const ClienteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cliente</span>
                {rowData.txtCliente}
            </>
        );
    };
    const ObservacionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Observ.</span>
                {rowData.txtObservacion}
            </>
        );
    };

    const EstatusBodyTemplate = (rowData) => {
        let estilo = "";
        let texto = "";
        switch (rowData.txtEstatus) {
            case "P":
                estilo = "status-instock";
                texto = "PAGADA";
                break;
            case "PC":
                estilo = "status-outofstock";
                texto = "POR COBRAR";
                break;
            case "V":
                estilo = "status-outofstock";
                texto = "VENCIDA";
                break;
            case "A":
                estilo = "status-outofstock";
                texto = "ANULADA";
                break;
			case "PF":
				estilo = "status-outofstock";
				texto = "PRE-FACTURA";
				break;

            default:
                break;
        }
        return (
            <>
                <span className="p-column-title"></span>
                <span className={`product-badge ${estilo}`}>{texto}</span>
            </>
        );
    };

	const printFactura = (venta) => {
		//console.log(venta)
		let usaMaquinaFiscal = userData.usaMF;
		if ( usaMaquinaFiscal===1 ) {
			swal({ title: "Ups...", text: "Factura Fiscal no disponible. Recuerde que, al usar máquina fiscal, solo puede usar su talonario alternativo como otro medio de facturación.", icon: "warning" });
		}
		else {
			httpService.getTableSelect("tabla_item_facturaC", userData.contribuyente, venta.id)
			.then((data) => {
				setProductos(data)
				venta.items = data;
				setFacturaData(venta);
				setTipoFacturaDialog(true)
				// setFacturaDialog(true);
			});
		}
	}
	
	const editFactura = (venta) => {
		setLoadingDialog(true);
		httpService.getTableSelect("tabla_item_facturaC", userData.contribuyente, venta.id)
		.then((data) => {
			setProductos(data)
			venta.items = data;
			httpService.getTableSelect("tabla_pagos_facturaC", userData.contribuyente, venta.id).then((dataP) => {
				let a_pagos = dataP;
				let a_igtf = [];
				let igtf = 0;
				let igtf_con = 0;
				a_pagos.forEach(p => {
					if (p.pctIGTF !== "0.00") {
						igtf +=  parseFloat(p.totalIGTF);
						igtf_con += parseFloat(p.totalIGTF_con);
						a_igtf[p.moneda] = { "tot": igtf, "tot_con": igtf_con, "pct": parseInt(p.pctIGTF)} ;
					}
					
				});
				venta.IGTF = a_igtf;
				// setIGTF(a_igtf);
				// console.log(IGTF)
				venta.pagos = dataP;
				// console.log(data);
				setFacturaData(venta);
				setLoadingDialog(false);
				linkFacturarProps(venta);
			});

			
			// setTipoFacturaDialog(true)
			// setFacturaDialog(true);
		});
	}

	const showRetencionDialog = (venta) => {
		setSubmitted(false);
		setMontoRetenido(null);
		setValues(emptyRetencion);
        setValues(venta);
		httpService.getTableSelect("tabla_item_facturaC", userData.contribuyente, venta.id)
		.then((data) => {
			setProductos(data)
			
		});
		setComprobanteRetDialog(true);
	}

	const MontoPagoBodyTemplate = (rowData) => {
        return `${formatCurrency(rowData.deMontoPago)} ${rowData.sMoneda}`;
    }
	
	const actionBodyTemplate = (rowData) => {
		let disablePrint = (rowData.esPorLote || rowData.sEstatus === "PF") ? "none" : "";
		let disableEdit = (rowData.sEstatus !== "PF") ? "none" : "";
		let disableRet = (rowData.sEstatus === "A" || rowData.sEstatus === "PF" || rowData.txtNroComprobanteRet !== null) ? true : false;
        return (
            <div className="actions">
                <Button icon="pi pi-search" className="p-button-rounded p-button-success p-button-sm" tooltip={"Ver detalles"} tooltipOptions={{position: 'top'}} onClick={() => detalleVenta(rowData)} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning p-button-sm" tooltip={"Ver detalles"} tooltipOptions={{position: 'top'}} onClick={() => editFactura(rowData)} style={{ display: disableEdit}} disabled={ acceso && acceso === "W" ? false : true } />
                <Button icon="pi pi-print" className="p-button-rounded p-button-info p-button-sm" tooltip={"Imprimir Factura"} tooltipOptions={{position: 'top'}} onClick={() => printFactura(rowData)} style={{ display: disablePrint}} disabled={ acceso && acceso === "W" ? false : true } />
                <Button icon="pi pi-plus-circle" className="p-button-rounded p-button-help p-button-sm" tooltip={"Agregar Retención IVA"} tooltipOptions={{position: 'top'}} onClick={() => showRetencionDialog(rowData)} disabled={acceso && acceso === "W" ? disableRet : true} />
				
            </div>
        );
    };

    

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const Nsi = () => {
        let total = 0;
        let sMoneda = "";
        for (let mont of productos) {
            total += parseFloat(mont.txtSubTotal);
            sMoneda = mont.sMoneda;
        }
        return formatCurrency(total) + " " + sMoneda;
    };

    var groupBy = function (miarray, prop) {
        return miarray.reduce(function (groups, item) {
            var val = item[prop];
            groups[val] = groups[val] || { sImpuesto: item.sImpuesto, deImpuesto: 0, deBaseImpon: 0 };
            groups[val].deImpuesto += parseFloat(item.deImpuesto);
            groups[val].sMoneda = item.sMoneda;
            groups[val].deBaseImpon += parseFloat(item.txtSubTotal);
            return groups;
        }, {});
    };

    const grupoImpuesto = () => {
        /* let grupo = [
            {
                sImpuesto: "Alicuota",
                deImpuest: 0,
            },
        ]; */
        let grImp = groupBy(productos, "sImpuesto");
        let refinado = Object.values(grImp).filter((i) => i.sImpuesto !== "Exento");

        return refinado;
    };

	const monedaToText = (moneda)=> {
		let rs_moneda = MonedaOptions.find( m => m.id === moneda );
		return rs_moneda.title;
	}

    const Total = () => {
        let total = 0;
        let sMoneda = "";
        for (let mont of productos) {
            total += parseFloat(mont.txtSubTotal) + parseFloat(mont.deImpuesto);
            sMoneda = mont.sMoneda;
        }
		for (let pago of pagos) {
			if (parseInt(pago.pctIGTF) > 0)
				total += (values.cboMoneda === 27) ? parseFloat(pago.totalIGTF_con) : parseFloat(pago.totalIGTF);
		}

		// total += values.txtTotalIGTF !==undefined ? parseFloat(values.txtTotalIGTF) : 0;
        return formatCurrency(total) + " " + sMoneda;
    };

	/* const TotalPagos_con = () => {
		let result = '';
		let tot_mon = [];
		MonedaOptions.forEach(mon => {
			tot_mon[mon.id] = 0;
		});

		for (let pago of pagos) {
			let monA = pago.moneda;
			MonedaOptions.forEach(mon => {
				if (monA === mon.id) {
					tot_mon[mon.id] += parseFloat(pago.deMontoPago);
				} else {
					if (monA === 27) {
						let tasaC = tasaCambioToBs.find( tc => tc.moneda === mon.id);
						if ( tasaC !== undefined ){
							tot_mon[mon.id] += parseFloat(pago.deMontoPago) / tasaC.tasa;
						}
					} else {
						if (mon.id === 27) {
							let tasaC = tasaCambioToBs.find( tc => tc.moneda === monA);
							if ( tasaC !== undefined ){
								tot_mon[mon.id] += parseFloat(pago.deMontoPago) * tasaC.tasa;
							}
						} else {
							let tasaA = tasaCambioToBs.find( tc => tc.moneda === monA);
							let tasaB = tasaCambioToBs.find( tc => tc.moneda === mon.id);
							if ((tasaA !== undefined && (tasaA.tasa !== 0 && (!isNaN(tasaA.tasa)))) && (tasaB !== undefined && (tasaB.tasaM !== 0 && (!isNaN(tasaB.tasaM))))) {
								tot_mon[mon.id] += parseFloat(pago.deMontoPago) * tasaA.tasa / tasaB.tasaM;
							}
						}
					}
				}
			}); 
		}
		tot_mon.forEach((p, i) => {
			let m = MonedaOptions.find( m => m.id === i );
			result += formatCurrency(p) + ' ' + m.title + ' / '; 
		});
		//setTotalPagos(tot_mon);
		result = result.substring(0, result.length - 2);
        return result;
    } */

	const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Subtotal:" colSpan={6} footerStyle={{ textAlign: "right" }} />
                <Column footer={Nsi} footerStyle={{ textAlign: "right" }} />
            </Row>

            {grupoImpuesto().map((post) => (
                <Row>
                    <Column footer={`${post.sImpuesto} (B.I. = ${formatCurrency(post.deBaseImpon)} ${post.sMoneda}): `} colSpan={6} footerStyle={{ textAlign: "right" }} />
                    <Column footer={formatCurrency(post.deImpuesto) + " " + post.sMoneda} footerStyle={{ textAlign: "right" }} />
                </Row>
            ))}
			{ IGTF.map( (imp, idx) => (
				<Row>
					<Column footer={`IGTF ${imp.pct}%`} colSpan={6} footerStyle={{ textAlign: 'right' }} />
					<Column footer={values.cboMoneda === 27 ? formatCurrency(imp.tot_con) + " " + values.txtMoneda : formatCurrency(imp.tot) + " " + monedaToText(idx)} footerStyle={{ textAlign: 'right' }} />
				</Row> 
			))}
            <Row>
                <Column footer="TOTAL:" colSpan={6} footerStyle={{ textAlign: "right" }} />
                <Column footer={Total} footerStyle={{ textAlign: "right" }} />
            </Row>
        </ColumnGroup>
    );

	/* let footerGroupPagos = <ColumnGroup>
        <Row>
            <Column footer="Total Pagos:" colSpan={3} footerStyle={{ textAlign: 'right' }} />
			<Column footer={`${TotalPagos_con()}`} footerStyle={{ textAlign: 'center' }}/>
        </Row>
    </ColumnGroup>; */

	const hideFacturaDialog = () => {
        setFacturaDialog(false);
    };

    const comprobanteRetDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={() => onHide(setComprobanteRetDialog)} />
            <Button label="Agregar" icon="pi pi-check" className="p-button-text" onClick={guardarRetencion} />
        </>
    );
	
	const onShow = (stateMethod, tipoImp) => {
		setTipoFacturaImp(tipoImp);
		if (tipoImp === 'FL'){
			let margen = { sup : margenFacturaFL.MargenSupFormaLibre, inf : margenFacturaFL.MargenInfFormaLibre	}
			setMargenFactura(margen);
		} else {
			let margen = { sup : margenFacturaFD.MargenSupFacturaDigital, inf : margenFacturaFD.MargenInfFacturaDigital	}
			setMargenFactura(margen);
		}
		stateMethod(true);
        
    }

	const onHide = (stateMethod) => {
        stateMethod(false);
    }
	
	const renderFooter = (stateMethod) => {
        return (
            <div>
                <Button label="Factura Digital" icon="pi pi-file-o" onClick={() => { onHide(stateMethod); onShow(setFacturaDialog, 'FD')  }} />
                <Button label="Factura Forma Libre" icon="pi pi-print" onClick={() => { onHide(stateMethod); onShow(setFacturaDialog, 'FL') }} className="p-button-secondary"/>
            </div>
        );
    }

	const cargarRetenido = (e) => {
        let combo = e.target.name;
        let impSelect = "";
        let val = { ...values };
        let total = 0;
        let impSelectValor = 0;
		
        if (combo === "txtTotalImpuesto") {
            impSelect = val.cboPctRetencion;
            let precioActual = e.target.value;
            if (impSelect === "" || impSelect === undefined || impSelect === 0) {
                impSelectValor = 0;
                total = parseFloat(precioActual);
            } else {
                let isVal = pctRetencionIvaOptions.find((imp) => imp.id === impSelect);
                impSelectValor = isVal.valor;
                total = (parseFloat(precioActual) * parseFloat(impSelectValor)) / 100;
            }
        } else {
            let idImpuesto = e.target.value;
            let precio = val.txtTotalImpuesto;
            impSelect = pctRetencionIvaOptions.find((imp) => imp.id === idImpuesto);
            impSelectValor = impSelect.valor;
            total = (parseFloat(precio) * parseFloat(impSelectValor)) / 100;
        }
        val.txtMontoRetenido = total;
        setMontoRetenido(val.txtMontoRetenido);
    };

	const sortDate = (arr, e) => {
		var valores = arr;
		valores.sort(function(a,b){
			var aFecha1 = b[e.field].split("/");
			var aFecha2 = a[e.field].split("/");
			var fecha1 = aFecha1[2] + "-" +   aFecha1[1] + "-" + aFecha1[0];
			var fecha2 = aFecha2[2] + "-" +   aFecha2[1] + "-" + aFecha2[0];
			if (e.order < 0) {
				return new Date(fecha1) - new Date(fecha2)
			} else {
				return new Date(fecha2) - new Date(fecha1);
			}
		  });
		return valores;
	}

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toolbar className="p-mb-4" right={rightToolbarTemplate}></Toolbar>
                    <DataTable
                        ref={dt}
                        value={ventas}
                        selection={selectedVentas}
                        onSelectionChange={(e) => setSelectedVentas(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} ventas"
                        globalFilter={globalFilter}
                        emptyMessage="No existen ventas registradas."
                        header={header}
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3%" }}></Column>
                        <Column field="txtNroFactura" header="Fact."  sortable headerStyle={{ width: "10%", textAlign: "center" }} body={NumeroBodyTemplate}></Column>
                        <Column field="txtFechaFactura" sortable sortFunction={(e) => sortDate(ventas, e)} header="Fecha" headerStyle={{ width: "11%", textAlign: "center" }} body={FechaVentaTemplate}></Column>
                        <Column field="txtCliente" header="Cliente" sortable headerStyle={{ width: "16%" }} body={ClienteBodyTemplate}></Column>
                        <Column field="txtMoneda" header="Mon." sortable headerStyle={{ width: "10%", textAlign: "center" }} bodyStyle={{ textAlign: "center" }} body={MonedaBodyTemplate}></Column>
                        <Column field="txtTotal" header="Total" headerStyle={{ width: "10%", textAlign: "center" }} bodyStyle={{ textAlign: "center" }} body={TotalBodyTemplate}></Column>
                        <Column field="txtNroComprobanteRet" header="Comp. Ret. IVA" headerStyle={{ width: "10%", textAlign: "center" }} bodyStyle={{ textAlign: "center" }}></Column>
                        <Column field="txtMontoRetenido" header="Monto Retenido" headerStyle={{ width: "10%", textAlign: "center" }} bodyStyle={{ textAlign: "center" }}></Column>
                        <Column field="txtObservacion" header="Observ. / Identificador" sortable headerStyle={{ width: "10%", textAlign: "center" }} body={ObservacionBodyTemplate}></Column>
                        <Column field="txtEstatus" header="Estatus" sortable headerStyle={{ width: "10%", textAlign: "center" }} body={EstatusBodyTemplate}></Column>
                        <Column headerStyle={{ width: "15%" }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={VentaDialog} maximizable modal style={{ width: "60vw" }} header="Detalles de la Factura" className="p-fluid" footer={VentasDialogFooter} onHide={hideDialog}>
                        <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
                        <TabView className="tabview-custom">
                            <TabPanel header="Datos Básicos" leftIcon="pi pi-user">
                                <div className="p-fluid p-formgrid p-grid">
                                    <div className="p-field p-col-3">
                                        <label htmlFor="txtNroControl">N° Control</label>
                                        <InputText size="small" name="txtNroControl" value={values.txtNroControl} onChange={handleInputChange} disabled />
                                    </div>
                                    <div className="p-field p-col-3">
                                        <label htmlFor="txtNroFactura">N° Factura</label>
                                        <InputText size="small" name="txtNroFactura" value={values.txtNroFactura} onChange={handleInputChange} disabled />
                                    </div>
                                    <div className="p-field p-col-6">
                                        <label htmlFor="txtFechaFactura">Fecha</label>
                                        <InputText size="small" name="txtFechaFactura" value={values.txtFechaFactura} onChange={handleInputChange} disabled />
                                    </div>
                                    {/*  */}
                                    <div className="p-field p-col-6">
                                        <label htmlFor="cboCliente">Cliente</label>
                                        <InputText size="small" name="txtCliente" value={values.txtCliente} onChange={handleInputChange} disabled />
                                    </div>
                                    <div className="p-field p-col-6">
                                        <label htmlFor="txtRifCliente">RIF/DNI</label>
                                        <InputText name="txtRifCliente" value={values.txtRifCliente} disabled />
                                    </div>
                                    {/*  */}
                                    <div className="p-field p-col-6">
                                        <label htmlFor="txtDireccionCliente">Dirección</label>
                                        <InputText name="txtDireccionCliente" value={values.txtDireccionCliente} disabled />
                                    </div>
                                    <div className="p-field p-col-6">
                                        <label htmlFor="txtTelefonoCliente">Teléfono</label>
                                        <InputText name="txtTelefonoCliente" value={values.txtTelefonoCliente} disabled />
                                    </div>
                                    {/*  */}
                                    <div className="p-field p-col-6">
                                        <label htmlFor="cbo">Condición de Pago</label>
                                        <Dropdown id="cboCondicionDePago" name="cboCondicionDePago" value={values.cboCondicionDePago} onChange={handleInputChange} optionValue="id" optionLabel="title" options={condicionesDePagoOptions} placeholder="Selecciona uno" disabled></Dropdown>
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <label htmlFor="cboFormaDePago">Forma de Pago</label>
                                        <Dropdown id="cboFormaDePago" name="cboFormaDePago" value={values.cboFormaDePago} onChange={handleInputChange} optionValue="id" optionLabel="title" options={formasDePagoOptions} placeholder="Selecciona uno" disabled></Dropdown>
                                    </div>
                                    {/*  */}
                                    <div className="p-field p-col-12 p-md-6">
                                        <label htmlFor="cboMoneda">Moneda</label>
                                        <Dropdown id="cboMoneda" name="cboMoneda" value={values.cboMoneda} onChange={handleInputChange} optionValue="id" optionLabel="title" options={MonedaOptions} placeholder="Selecciona uno" disabled></Dropdown>
                                    </div>
                                    <div className="p-field p-col-12 p-md-6">
                                        <label htmlFor="txtObservacion">Observación</label>
                                        <InputTextarea name="txtObservacion" value={values.txtObservacion} onChange={handleInputChange} disabled />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Items de Venta" leftIcon="pi pi-dollar">
                                <div className="p-grid crud-demo">
                                    <div className="p-col-12">
                                        <div className="card">
                                            <DataTable ref={dt} value={productos} dataKey="id" footerColumnGroup={footerGroup}>
                                                <Column field="txtCantidad" header="Cant." headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "center" }} body={CantidadBodyTemplate}></Column>
                                                <Column field="cboUoM" header="UoM" headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "center" }} body={UoMBodyTemplate}></Column>
                                                <Column field="sProducto" header="Descripción" body={ProductoBodyTemplate}></Column>
                                                <Column field="txtPrecioUnit" header="Precio Unit." headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "center" }} body={PrecioUnitBodyTemplate}></Column>
                                                <Column field="txtPctDescuento" header="% Dcto." headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "center" }} body={DescuentoBodyTemplate}></Column>
                                                <Column field="cboImpuesto" header="Alicuota" headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "center" }} body={ImpuestoBodyTemplate}></Column>
                                                <Column field="txtSubTotal" header="Importe" headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "center" }} body={SubTotalBodyTemplate}></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
							<TabPanel header="Pagos" leftIcon="pi pi-dollar">
                                <div className="p-grid crud-demo">
                                    <div className="p-col-12">
                                        <div className="card">
                                            {/* <Toolbar className="p-mb-4" left={leftToolbarTemplateP} ></Toolbar> */}
                                            <DataTable value={pagos} dataKey="id" width="100%">
												<Column field="sFormaDePago" header="Forma Pago" headerStyle={{ width:'25%', textAlign: 'center'}} bodyStyle={{ textAlign: 'center' }}  ></Column>
												<Column field="sNroSoporte" header="Nro Soporte" headerStyle={{ width:'25%', textAlign: 'center'}} bodyStyle={{ textAlign: 'center' }}  ></Column>
												<Column field="sMoneda" header="Moneda" headerStyle={{ width:'25%', textAlign: 'center'}} bodyStyle={{ textAlign: 'center' }} ></Column>
												<Column field="deMontoPago" header="Monto" headerStyle={{ width:'25%', textAlign: 'center'}} body={MontoPagoBodyTemplate} bodyStyle={{ textAlign: 'center' }} ></Column>
											</DataTable>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </TabView>
                    </Dialog>

					<Dialog visible={facturaDialog} onHide={hideFacturaDialog} position="top" headerStyle={{ height: "1px" }} contentStyle={{maxHeight: "100vh", height: "100vh"}} resizable={true}>
						<PDFViewer  width="1000" height={"99%"} className="app" >
							<Factura 
								data={facturaData} 
								tipoImp={tipoFacturaImp} 
								margenes={margenFactura} 
								datosContrib={{ nombreC: userData.razon_social, rifC: userData.rif, direccionC: userData.direccion, telefonoC: userData.telfContribuyente, usuario: userData.nombre_completo, logo: userData.pathLogo }} />
						</PDFViewer>
					</Dialog>

					<Dialog header="Tipo de Factura" visible={tipoFacturaDialog} style={{width: '50vw'}} onHide={() => onHide(setTipoFacturaDialog)} footer={renderFooter(setTipoFacturaDialog)}>
                		<p>Seleccione el Tipo de Factura que desea Imprimir: </p>
            		</Dialog>

					<Dialog visible={deleteVentasDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteFacturaDialogFooter} onHide={() => onHide(setDeleteVentasDialog)}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {venta && <span>¿Está Seguro de eliminar la(s) venta(s) seleccionada(s)?</span>}
                        </div>
                    </Dialog>

					<Dialog visible={comprobanteRetDialog} style={{ width: "40vw"}} header="Agregar Comprobante de Retención" className="p-fluid" footer={comprobanteRetDialogFooter} onHide={() => onHide(setComprobanteRetDialog)}>
                        <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
						<div className="p-fluid p-formgrid p-grid">
							<div className="p-field p-col-3">
								<label htmlFor="txtNroFactura">N° Factura</label>
								<InputText size="small" name="txtNroFactura" value={values.txtNroFactura} onChange={handleInputChange} disabled />
							</div>
							<div className="p-field p-col-3">
								<label htmlFor="txtNroControl">N° Control</label>
								<InputText size="small" name="txtNroControl" value={values.txtNroControl} onChange={handleInputChange} disabled />
							</div>
							<div className="p-field p-col-6">
								<label htmlFor="txtFechaFactura">Fecha de Factura</label>
								<InputText size="small" name="txtFechaFactura" value={values.txtFechaFactura} onChange={handleInputChange} disabled />
							</div>
							{/*  */}
							<div className="p-field p-col-6">
								<label htmlFor="txtFechaRetencion">Fecha de Retención</label>
								<InputMask id="txtFechaRetencion" name="txtFechaRetencion" mask="99/99/9999" value={values.txtFechaRetencion} placeholder="99/99/9999" slotChar="dd/mm/yyyy" onChange={handleInputChange} className={classNames({ 'p-invalid': submitted && !values.txtFechaRetencion })}></InputMask> 
								{submitted && !values.txtFechaRetencion && <small className="p-invalid">{errors.txtFechaRetencion}</small>}
							</div>
							<div className="p-field p-col-6">
								<label htmlFor="txtFechaRetencion">Nro. de Comprobante de Retención</label>
								<InputText size="small" name="txtNroComprobanteRet" value={values.txtNroComprobanteRet} onChange={handleInputChange} className={classNames({ 'p-invalid': submitted && !values.txtNroComprobanteRet })}/>
								{submitted && !values.txtNroComprobanteRet && <small className="p-invalid">{errors.txtNroComprobanteRet}</small>}
							</div>
							{/*  */}
							<div className="p-field p-col-6">
								<label htmlFor="txtTotalImpuesto">Total IVA</label>
								<InputNumber name="txtTotalImpuesto" mode="decimal" minFractionDigits={2} value={values.txtTotalImpuesto} onValueChange={(e) => { handleInputChange(e); cargarRetenido(e); }} required className={classNames({ 'p-invalid': submitted && !values.txtTotalImpuesto })} />
								{submitted && !values.txtTotalImpuesto && <small className="p-invalid">{errors.txtTotalImpuesto}</small>}
							</div>
							<div className="p-field p-col-12 p-md-6">
								<label htmlFor="cboPctRetencion">% de Retencion IVA</label>
								<Dropdown id="cboPctRetencion" name="cboPctRetencion" value={values.cboPctRetencion} onChange={(e) => { handleInputChange(e); cargarRetenido(e); }} optionValue="id" optionLabel="title" options={pctRetencionIvaOptions} placeholder="Selecciona uno" className={classNames({ 'p-invalid': submitted && !values.cboPctRetencion })}></Dropdown>
								{submitted && !values.cboPctRetencion && <small className="p-invalid">{errors.cboPctRetencion}</small>}
							</div>
							<div className="p-field p-col-6">
								<label htmlFor="txtMontoRetenido">Monto Retenido</label>
								<InputText size="small" name="txtMontoRetenido" value={montoRetenido} onChange={handleInputChange} className={classNames({ 'p-invalid': submitted && !montoRetenido })} />
								{submitted && !montoRetenido && <small className="p-invalid">{errors.txtMontoRetenido}</small>}
							</div>
							{/*  */} 
						</div>
                    </Dialog>
                    <Dialog visible={ReporteZDialog} maximizable modal style={{ width: '50vw' }} header="Carga Reporte Z"  className="p-fluid" footer={ReporteZDialogFooter} onHide={hideReporteZDialog}>
                        <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtFechaReporteZ">Fecha</label>
                                <Calendar showIcon locale="es" dateFormat="dd/m/yy" id="txtFechaReporteZ" name="txtFechaReporteZ" value={values.txtFechaReporteZ} onChange={handleInputChange}   className={classNames({ 'p-invalid': submitted && !values.txtFechaReporteZ })} />
                                {submitted && !values.txtFechaReporteZ && <small className="p-invalid">{errors.txtFechaReporteZ}</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="cboMonedaReporteZ">Moneda</label>
                                <Dropdown id="cboMonedaReporteZ" name="cboMonedaReporteZ" value={values.cboMonedaReporteZ} onChange={handleInputChange} optionValue="id" optionLabel="title" options={MonedaBsOptions} placeholder="Selecciona uno"></Dropdown>
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtNroReporteRZ">Nro Reporte</label>
								<InputText size="small" name="txtNroReporteRZ" value={values.txtNroReporteRZ} onChange={handleInputChange} className={classNames({ 'p-invalid': submitted && !values.txtNroReporteRZ })}/>
								{submitted && !values.txtNroReporteRZ && <small className="p-invalid">{errors.txtNroReporteRZ}</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="cboMaquinaFiscalRZ">Máquina Fiscal</label>
                                <Dropdown id="cboMaquinaFiscalRZ" name="cboMaquinaFiscalRZ" value={values.cboMaquinaFiscalRZ} onChange={handleInputChange} optionValue="id" optionLabel="title" options={maquinasFiscalesOptions} placeholder="Selecciona uno" disabled={userData.usaMF ? false : true} className={classNames({ 'p-invalid': submitted && userData.usaMF && !values.cboMaquinaFiscalRZ })}></Dropdown>
                                {submitted && !values.cboMaquinaFiscalRZ && <small className="p-invalid">{errors.cboMaquinaFiscalRZ==="0" ? "" : errors.cboMaquinaFiscalRZ}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtFacturaDesdeRZ">Factura Desde</label>
                                <InputNumber name="txtFacturaDesdeRZ" mode="decimal" minFractionDigits={0} value={values.txtFacturaDesdeRZ} onValueChange={handleInputChange} required className={classNames({ 'p-invalid': submitted && !values.txtFacturaDesdeRZ })} />
                                {submitted && !values.txtFacturaDesdeRZ && <small className="p-invalid">{errors.txtFacturaDesdeRZ}</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="txtFacturaHastaRZ">Factura Hasta</label>
                                <InputNumber name="txtFacturaHastaRZ" mode="decimal" minFractionDigits={0} value={values.txtFacturaHastaRZ} onValueChange={handleInputChange} required className={classNames({ 'p-invalid': submitted && !values.txtFacturaHastaRZ })} />
                                {submitted && !values.txtFacturaHastaRZ && <small className="p-invalid">{errors.txtFacturaHastaRZ}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtTotalBIRZ">Total Base Imponible</label>
                                <InputNumber name="txtTotalBIRZ" mode="decimal" minFractionDigits={2} value={values.txtTotalBIRZ} onValueChange={handleInputChange} required className={classNames({ 'p-invalid': submitted && !values.txtTotalBIRZ })} />
                                {submitted && !values.txtTotalBIRZ && <small className="p-invalid">{errors.txtTotalBIRZ}</small>}
                            </div>
                        </div>
                        <div className="p-formgrid p-grid">
                            <div className="p-field p-col">
                                <label htmlFor="txtImpuestoRZ">Impuesto</label>
                                <Dropdown id="cboImpuestoRZ" name="cboImpuestoRZ" value={values.cboImpuestoRZ} onChange={handleInputChange} optionValue="id" optionLabel="title" options={impuestosOptions} placeholder="Selecciona uno" disabled={userData.usaMF ? false : true} className={classNames({ 'p-invalid': submitted && userData.usaMF && !values.cboImpuestoRZ })}></Dropdown>
                                {submitted && !values.cboImpuestoRZ && <small className="p-invalid">{errors.cboImpuestoRZ==="0" ? "" : errors.cboImpuestoRZ}</small>}
                            </div>
                            <div className="p-field p-col">
                                <label htmlFor="txtTotalImpuestoRZ">Total Impuesto</label>
                                <InputNumber name="txtTotalImpuestoRZ" mode="decimal" minFractionDigits={2} value={values.txtTotalImpuestoRZ} onValueChange={handleInputChange} required className={classNames({ 'p-invalid': submitted && !values.txtTotalImpuestoRZ })} />
                                {submitted && !values.txtTotalImpuestoRZ && <small className="p-invalid">{errors.txtTotalImpuestoRZ}</small>}
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
}
