import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { useForm } from "../../components/useForm";
import { HttpService } from '../../service/HttpService';
import { getUserData } from '../../service/AuthHelperMethods';
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import {Tooltip} from 'primereact/tooltip';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import ShowLoading from "../../components/ShowLoading/ShowLoading";


const initialFValues = {
    txtFechaDesde: '',
    txtFechaHasta: '',
    txtContribuyente: '',
    id: ''
};

export const ResumenVentas = () => {

    const toast = useRef(null);
    const [ventas, setVentas] = useState([]);
    const [resumenVentas, setResumenVentas] = useState([]);
    
    const [httpService] = useState(new HttpService());
    const userData = getUserData() || null;
    const [submitted, /* setSubmitted */] = useState(false);
    const [fdesde, setFDesde] = useState("");
    const [fhasta, setFHasta] = useState("");
    const [displayLibro, setDisplayLibro] = useState(false);
	const [impuestosOptions, setImpuestosOptions] = useState([]);
	/* const [impuestos, setImpuestos] = useState([]); */
	const [loadingDialog, setLoadingDialog] = useState(false);

    let razon_social_aux = userData.razon_social;
    let direccion_aux = userData.direccion;
    let rif_aux = userData.rif;

    useEffect(() => {
		httpService.getOptionSelect("lista_impuestos").then(data => {
			setImpuestosOptions(data)
		});

    }, [httpService]);

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("cboProducto" in fieldValues) temp.cboProducto = fieldValues.cboProducto ? "" : "Campo Requerido.";
        if ("cboUoM" in fieldValues) temp.cboUoM = fieldValues.cboUoM ? "" : "Campo Requerido.";

        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate);

    const cols = [
        { field : 'descripcion', header: 'Descripción' },
		{ field : 'baseImponible', header : 'Base Imponible'},
		{ field : 'debitoFiscal', header : 'Debito Fiscal'},
		
    ];

    const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));

    const exportPdf = () => {
		const doc = new jsPDF();
		doc.setFontSize(10);
		doc.setFont("helvetica", "bold");
		doc.text(userData.razon_social, 15, 10);
		doc.setFont("helvetica", "normal");
		doc.text(userData.direccion, 15, 15);
		doc.text("RIF: "+ userData.rif, 15, 20);
		// doc.text("This is right aligned text", 200, 100, null, null, "right");
		doc.setFont("helvetica", "bold");
		doc.text("RESUMEN DE VENTAS", 15, 30);
		doc.text(`DEL ${fdesde} AL ${fhasta}`, 15, 35);
		
		// var footer = '\n' + `TOTAL VENTAS INCLUYENDO EL IVA = ${TotalConImp()}` + '\n' +
        //     '\n' +  `VENTAS INTERNAS NO GRAVADAS = ${SinDeCret()}` + '\n'
		
		// impuestos.map((imp) => (
	    // 	footer += '\n' +  `${imp.m_header.toUpperCase()} = ${Sumar(imp)}` + '\n' 
		// ))
          
        // footer += '\n' +  `TOTAL IVA RETENIDO = ${TotalIvaRetenido()}`;

		// var body = [...resumenVentas, 
		// [{content: footer, colSpan: exportColumns.length-1, 
		// 	styles: { fontStyle: 'bold', halign: 'right' }
		// }]]
		let total_bi = Sumar(resumenVentas, "baseImponible");
		let total_df = Sumar(resumenVentas, "debitoFiscal");
		let total_ret = TotalIvaRetenido();

		doc.autoTable({
			margin: { top: 40 },
			pageBreak: 'auto',
			columns: exportColumns,
			body: resumenVentas,
			foot:  [['TOTALES', total_bi, total_df], ['TOTAL IVA RETENIDO', total_ret, ""]],
			headStyles: {
				fontSize: 10,
				halign: 'center'
			},
			bodyStyles: {
				fontSize: 9,
				halign: 'right',
			},
			footStyles: {
				fontSize: 9,
				halign: 'center',
				fillColor: '#F9F9F9',
				textColor: "black" 
			},
			columnStyles: {
				descripcion: { halign: 'left', },
				baseImponible : { halign: 'center', },
				debitoFiscal: { halign: 'center', },
			},
		});

		doc.save('resumen_ventas.pdf');
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(ventas);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'ventas');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="p-mr-2" data-pr-tooltip="CSV" /> */}
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success p-mr-2" tooltipOptions={{position: 'top'}} data-pr-tooltip="Exportar a XLS" />
                <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-danger p-mr-2" tooltipOptions={{position: 'top'}} data-pr-tooltip="Exportar a PDF" />
            </React.Fragment>
        )
    }

	const parseBsFloat = (number) =>{
		return parseFloat(number.replaceAll(".","").replaceAll(",", "."));
	}

	const parseFloatBs = (number) =>{
		const options = { minimumFractionDigits: 2}
		const numberFormat1 = new Intl.NumberFormat('es-VE' , options);
		const numero = numberFormat1.format(number);
		return numero;
	}

    const SinDeCret = (ventas) => {
        let total = 0;
        for (let mont of ventas) {
			let tot = mont.total_txtSinDeCret === null ? 0 : parseBsFloat(mont.total_txtSinDeCret);
            total += tot;
        }
        return parseFloatBs(total);
    }

    const TotalIvaRetenido = () => {
        let total = 0;
        for (let mont of ventas) {
			let tot = mont.total_txtImpuestoRetenido === null ? 0 : parseBsFloat(mont.total_txtImpuestoRetenido);
            total += tot;
        }
        return parseFloatBs(total);
    }
	const Sumar = (ventas, m_field) => {
        let total = 0;
        for (let mont of ventas) {
			let tot = mont[m_field] === null ? 0 : parseBsFloat(mont[m_field]);
            total += tot;
        }
        return parseFloatBs(total);
    }

    const GenerarResumenVentas = () => {
		// crearColumnas();
		setLoadingDialog(true);
        var fd = new Date(values.txtFechaDesde);
        setFDesde(`${(fd.getDate())}`.padStart(2,'0') + "/"+ `${(fd.getMonth()+1)}`.padStart(2,'0')+ "/" + fd.getFullYear());

        var fh = new Date(values.txtFechaHasta);
        setFHasta(`${(fh.getDate())}`.padStart(2,'0') + "/"+ `${(fh.getMonth()+1)}`.padStart(2,'0')+ "/" + fh.getFullYear());

        httpService.getTableSelect("libro_de_ventas", userData.contribuyente, values.txtFechaDesde, values.txtFechaHasta)
		.then(data => {
			setVentas(data);
			//console.log(ventas);
			let resume = [
				{ "descripcion": "Total: Ventas Internas No Gravadas", "baseImponible": SinDeCret(data), "debitoFiscal": null },
			];
			let arr = [];
			let arr2 = [];
			
			impuestosOptions.map((imp) => {
				if (imp.valor!==0) {
					arr = {
						"m_header" : `Total: Ventas Internas con Alicuota (${imp.valor}%)`,
						"m_field_bi" : `total_txtBaseImponible_${imp.valor}`,
						"m_field_im" : `total_txtImpuesto_${imp.valor}`
					}
					arr2.push(arr);
				}
				return arr2; 
			});

			arr2.map((imp) => {
				let total_b = Sumar(data, imp.m_field_bi);
				let total_i = Sumar(data, imp.m_field_im);
				arr = {
					"descripcion" : imp.m_header,
					"baseImponible" : total_b,
					"debitoFiscal" : total_i
				};
				resume.push(arr);
				return arr;
			});
			setLoadingDialog(false);
			setResumenVentas(resume);
			setDisplayLibro(true);
		})
		.catch(err => {
			setLoadingDialog(false);
		});
    };

	let footerGroup = <ColumnGroup>
    <Row>
        <Column footer="TOTALES:" footerStyle={{ textAlign: 'right' }} />
        <Column footer={Sumar(resumenVentas, "baseImponible")} footerStyle={{ textAlign: 'center' }}/>
        <Column footer={Sumar(resumenVentas, "debitoFiscal")} footerStyle={{ textAlign: 'center' }}/>
        
		{/* <Column footer={TotalIvaRetenido} footerStyle={{ textAlign: 'right' }}/> */}
    </Row>
	<Row>
        <Column footer="TOTAL IVA RETENIDO:" footerStyle={{ textAlign: 'right' }} />
        <Column footer={TotalIvaRetenido} footerStyle={{ textAlign: 'center' }}/>
        <Column footer="" footerStyle={{ textAlign: 'center' }}/>        
    </Row>
    </ColumnGroup>;

	/* const crearColumnas = () => {
		let arr = [];
		let arr2 = [];
		let c_arr = [];
		impuestosOptions.map((imp) => {
			if (imp.valor !== 0) {
				arr = {
					"m_header" : `Base Imponible (${imp.valor}%)`,
					"m_field" : `total_txtBaseImponible_${imp.valor}`
				}
				arr2.push(arr);
				arr = {
					"m_header" : `Impuesto IVA (${imp.valor}%)`,
					"m_field" : `total_txtImpuesto_${imp.valor}`
				}
				arr2.push(arr);
				c_arr = {
					field : `total_txtBaseImponible_${imp.valor}`,
					header : `Base Imponible (${imp.valor}%)`
				}
				cols.push(c_arr);
				c_arr = {
					field : `total_txtImpuesto_${imp.valor}`,
					header : `Impuesto IVA (${imp.valor}%)`
				}
        		cols.push(c_arr);
			}
			return arr
		});
		c_arr = {
			field : "total_txtImpuestoRetenido",
			header : "IVA Retenido"
		}
		cols.push(c_arr);
		setImpuestos(arr2)
	} */

	/* const dinamicColumns = impuestos.map((imp) => {
		return (
       		<Column header={imp.m_header} field={imp.m_field} headerStyle={{textAlign: "center"}} bodyStyle={{ textAlign: 'right' }}></Column>
		);
	}); */


    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <h5>Resumen de Ventas</h5>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="txtFechaDesde">Fecha desde</label>
                            <Calendar showIcon showButtonBar locale="es" dateFormat="dd/m/yy" id="txtFechaDesde" name="txtFechaDesde" value={values.txtFechaDesde} onChange={handleInputChange} monthNavigator yearNavigator yearRange="2010:2030" className={classNames({ 'p-invalid': submitted && !values.txtFechaDesde })} />
                            {submitted && !values.txtFechaDesde && <small className="p-invalid">{errors.txtFechaDesde}</small>}
                        </div>
                        <div className="p-field p-col-12 p-md-6">
                            <label htmlFor="txtFechaHasta">Fecha Hasta</label>
                            <Calendar showIcon showButtonBar locale="es" dateFormat="dd/m/yy" id="txtFechaHasta" name="txtFechaHasta" value={values.txtFechaHasta} onChange={handleInputChange} monthNavigator yearNavigator yearRange="2010:2030" className={classNames({ 'p-invalid': submitted && !values.txtFechaHasta })} />
                            {submitted && !values.txtFechaHasta && <small className="p-invalid">{errors.txtFechaHasta}</small>}
                        </div>
                        <div className="p-field p-col-12">
                            <React.Fragment>
                                <Button label="Consultar" icon="pi pi-add" className="p-button-info p-mr-2" onClick={GenerarResumenVentas} />
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-col-12">
                <div className="card"  hidden={!displayLibro}>
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <Tooltip target=".export-buttons>button" position="bottom" />
					<div className="card">
						<div className="p-col-12" style={{ textAlign: 'center', fontSize: "15px" }}>
                            <span style={{ fontWeight: "bold" }}>{razon_social_aux.toUpperCase()}</span><br />
							<span>{direccion_aux.toUpperCase()}</span><br />
							<span>{'RIF: ' + rif_aux.toUpperCase()}</span><br />
							<span style={{ fontStyle: "italic", fontSize: "14px" }}>{'RESUMEN DE VENTAS DEL: ' + fdesde + ' AL ' + fhasta}</span><br />
						</div>
					</div>
                    <div className="card">
					<DataTable dataKey="id" value={resumenVentas} footerColumnGroup={footerGroup} >
                        <Column field='descripcion' header="Descripción" ></Column>
                        <Column field='baseImponible' header="Base Imponible" headerStyle={{width: "20%", textAlign: "center"}} bodyStyle={{textAlign: "center"}}></Column>
                        <Column field='debitoFiscal' header="Débito Fiscal" headerStyle={{width: "20%", textAlign: "center"}} bodyStyle={{textAlign: "center"}}></Column>
					</DataTable>
						
					</div>
                </div>
            </div>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
};
