import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useLocation } from 'react-router-dom';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useForm } from "../../components/useForm";
import { HttpService } from "../../service/HttpService";
import { getUserData, configPermisosRW } from "../../service/AuthHelperMethods";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { TabView, TabPanel } from "primereact/tabview";
import ShowLoading from "../../components/ShowLoading/ShowLoading";

const initialFValues = {
    cboTipoProducto: "",
    txtNombre: "",
    cboUoM: "",
    cboImpuesto: "",
    txtCantidad: 0,
    txtDescripcion: "",
    txtContribuyente: "",
    cboMoneda: "",
    txtPrecio: 0,
    txtPrecioTotal: 0,
    txtFotos: "",
    swParaVenta: false,
    swInventariable: true,
    caso: 0,
    id: "",
};

export default function AdmProductos() {
    let emptyProducto = {
        cboTipoProducto: "",
        txtNombre: "",
        cboUoM: "",
        cboImpuesto: "",
        txtCantidad: 0,
        txtDescripcion: "",
        txtFotos: "",
        swParaVenta: false,
        swInventariable: true,
        cboMoneda: "",
        txtPrecio: 0,
        txtPrecioTotal: 0,
        id: "",
        inventoryStatus: "INSTOCK",
    };
	
	let location = useLocation();
	const [acceso, setAcceso] = useState("");

    const toast = useRef(null);
    const [ProductoDialog, setProductoDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [userData] = useState(getUserData() || null);
    const [httpService] = useState(new HttpService());
    const [productos, setProductos] = useState([]);
    const [producto, setProducto] = useState(emptyProducto);
    const [caso, setCaso] = useState(true);
    const [tipoProductoOptions, setTipoProductoOptions] = useState([]);
    const [uoMOptions, setUoMOptions] = useState([]);
    const [MonedaOptions, setMonedaOptions] = useState([]);
    const [fotos, setFotos] = useState(null);
    const [impuestosOptions, setImpuestosOptions] = useState([]);
    const [precioTotal, setPrecioTotal] = useState(0);
	const [loadingDialog, setLoadingDialog] = useState(false);

    const subirArchivos = (e) => {
        setFotos(e);
    };

	useEffect(() => {
		let acc = configPermisosRW(location, userData);
		setAcceso(acc);
	}, [location, userData]);

    useEffect(() => {
		setLoadingDialog(true);
        httpService.getOptionSelect("lista_UoM").then((data) => setUoMOptions(data));
        httpService.getOptionSelect("lista_Monedas").then((data) => setMonedaOptions(data));
        httpService.getOptionSelect("tipo_productos", userData.contribuyente, "").then((data) => setTipoProductoOptions(data));
        httpService.getOptionSelect("lista_impuestos").then((data) => setImpuestosOptions(data));
        httpService.getTableSelect("tabla_productos", userData.contribuyente, "")
		.then((data) => {setProductos(data); setLoadingDialog(false); })
		.catch(err => setLoadingDialog(false));
    }, [httpService, userData]);

    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        if ("txtNombre" in fieldValues) temp.txtNombre = fieldValues.txtNombre ? "" : "Campo Requerido.";
        if ("cboUoM" in fieldValues) temp.cboUoM = fieldValues.cboUoM ? "" : "Campo Requerido.";
		if (fieldValues.swInventariable) {
			if ("txtCantidad" in fieldValues) temp.txtCantidad = (fieldValues.txtCantidad === 0 || fieldValues.txtCantidad === "0.00") ? "Campo Requerido" : "";
		} else {
			temp.txtCantidad = "";
		}

		if (fieldValues.swParaVenta){
			if ("txtPrecio" in fieldValues) temp.txtPrecio = (fieldValues.txtPrecio === 0 || fieldValues.txtPrecio === "0.00") ? "Campo Requerido" : "";
			if ("cboMoneda" in fieldValues) temp.cboMoneda = fieldValues.cboMoneda ? "" : "Campo Requerido.";
			if ("cboImpuesto" in fieldValues) temp.cboImpuesto = fieldValues.cboImpuesto ? "" : "Campo Requerido.";
		} else {
			temp.txtPrecio = "";
			temp.cboMoneda = "";
			temp.cboImpuesto = "";
		}

        if ("txtDescripcion" in fieldValues) temp.txtDescripcion = fieldValues.txtDescripcion ? "" : "Campo Requerido.";

        setErrors({
            ...temp,
        });
        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };
    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

    const handleSubmit = async (e) => {
        setSubmitted(true);
        e.preventDefault();

        if (validate()) {
            setButtonDisabled(true);
            values.txtContribuyente = userData.contribuyente;
            values.caso = caso;
            values.txtPrecioTotal = precioTotal;

            httpService
                .registrarNuevoProducto(values)
                .then((res) => {
                    setButtonDisabled(false);
                    if (res.error) {
                        let temp = { ...errors };
                        if (res.message.indexOf("Email") >= 0) {
                            temp.txtEmail = res.message;
                        }
                        setErrors({
                            ...temp,
                        });
                        toast.current.show({ severity: "error", summary: "Error", detail: res.message, life: 3000 });
                    } else {
                        //Registramos La foto del producto
                        if (fotos) {
                            const f = new FormData();
                            f.append("idContribuyente", userData.contribuyente);
                            f.append("idProducto", res.nIdProducto_db);
                            for (let index = 0; index < fotos.length; index++) {
                                f.append("fotos", fotos[index]);
                            }

                            httpService
                                .registrarFotoProducto(f)
                                .then((res) => {
                                    setButtonDisabled(false);
                                    if (res.error) {
                                        let temp = { ...errors };

                                        setErrors({
                                            ...temp,
                                        });
                                        toast.current.show({ severity: "error", summary: "Error", detail: res.message, life: 3000 });
                                    } else {
                                        toast.current.show({ severity: "success", summary: "Exito", detail: res.message, life: 3000 });
                                    }
                                })
                                .catch((err) => {
                                    toast.current.show({ severity: "error", summary: "Error", detail: err.message, life: 3000 });
                                    setButtonDisabled(false);
                                });
                        }
                        //registro exitoso
                        toast.current.show({ severity: "success", summary: "Exito", detail: res.message, life: 3000 });
                        setProductoDialog(false);
                        resetForm();
                        setFotos("");
                    }
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Error", detail: err.message, life: 3000 });
                    setButtonDisabled(false);
                });
        }
        httpService.getTableSelect("tabla_productos", userData.contribuyente, "").then((data) => setProductos(data));
    };
    const openNew = () => {
        setCaso(0);
        setValues(emptyProducto);
        setPrecioTotal("");
        setSubmitted(false);
        setProductoDialog(true);
    };

    const editProduct = (producto) => {
        setCaso(1);
		producto.swInventariable = producto.swInventariable === 0 ? false : true;
		producto.swParaVenta = producto.swParaVenta === 0 ? false : true;
        setValues(producto);
        setPrecioTotal(producto.txtPrecioTotal);
        setProductoDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProductoDialog(false);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} disabled={ acceso && acceso === "W" ? false : true } />
                <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={ acceso && acceso === "W" ? (!selectedProductos || !selectedProductos.length) : true } />
            </React.Fragment>
        );
    };

    const header = (
        <div className="table-header">
            <h5 className="p-m-0">Administrador de Productos</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const ProductosDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Guardar" icon="pi pi-check" className="p-button-text" disabled={ButtonDisabled} onClick={handleSubmit} />
        </>
    );

    const TipoProductoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tipo Producto</span>
                {rowData.sTipoProducto}
            </>
        );
    };

    const nombreBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre</span>
                {rowData.txtNombre}
            </>
        );
    };

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Foto</span>
                <img width="60px" src={`${rowData.txtImagen}`} alt={rowData.txtImagen} /* className="product-image" */ />
            </>
        );
    };

    const UoMBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">UoM</span>
                {rowData.sUoM}
            </>
        );
    };

    const CantidadBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cant.</span>
                {rowData.txtCantidad}
            </>
        );
    };

    const estatusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Estatus</span>
                {rowData.estatus}
            </>
        );
    };

    const inventoryStatusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"></span>
                <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-warning p-mr-2" onClick={() => editProduct(rowData)} disabled={ acceso && acceso === "W" ? false : true } />
                <Button icon={rowData.iconStatus} className={rowData.iconClass} onClick={() => confirmUptEstatusProducto(rowData)} disabled={ acceso && acceso === "W" ? false : true } />
            </div>
        );
    };

    const confirmUptEstatusProducto = (producto) => {
        setProducto(producto);
        setUptEstatusProductoDialog(true);
    };

    const updEstatusProducto = async (e) => {
        e.preventDefault();
        values.txtContribuyente = userData.contribuyente;
        values.idProducto = producto.id;

        httpService
            .updateEstatusProducto(values)
            .then((res) => {
                if (res.error) {
                    let temp = { ...errors };
                    if (res.message.indexOf("Email") >= 0) {
                        temp.txtEmail = res.message;
                    }
                    setErrors({
                        ...temp,
                    });
                    toast.current.show({ severity: "error", summary: "Error", detail: res.message, life: 3000 });
                } else {
                    //Actualizacion exitosa
                    toast.current.show({ severity: "success", summary: "Exito", detail: res.message, life: 3000 });
                    setUptEstatusProductoDialog(false);
                    setProducto(emptyProducto);
                    resetForm();
                    httpService.getTableSelect("tabla_productos", userData.contribuyente, "").then((data) => setProductos(data));
                }
            })
            .catch((err) => {
                toast.current.show({ severity: "error", summary: "Error", detail: err.message, life: 3000 });
            });
    };

    const [UptEstatusProductoDialog, setUptEstatusProductoDialog] = useState(false);

    const hideUpdEstatusProductosDialog = () => {
        setUptEstatusProductoDialog(false);
    };
    const updEstatusProductoDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideUpdEstatusProductosDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={updEstatusProducto} />
        </>
    );

    const [deleteProductosDialog, setDeleteProductosDialog] = useState(false);

    const [selectedProductos, setSelectedProductos] = useState(null);

    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    const hideDeleteProductosDialog = () => {
        setDeleteProductosDialog(false);
    };

    const confirmDeleteSelected = () => {
        setDeleteProductosDialog(true);
    };

    const deleteSelectedProductos = async (e) => {
        e.preventDefault();
        httpService
            .deleteProductos(selectedProductos)
            .then((res) => {
                if (res.error) {
                    let temp = { ...errors };
                    if (res.message.indexOf("Email") >= 0) {
                        temp.txtEmail = res.message;
                    }
                    setErrors({
                        ...temp,
                    });
                    toast.current.show({ severity: "error", summary: "Error", detail: res.message, life: 3000 });
                } else {
                    //Eliminacion exitosa
                    toast.current.show({ severity: "success", summary: "Exito", detail: res.message, life: 3000 });
                    setDeleteProductosDialog(false);
                    setSelectedProductos(null);
                    httpService.getTableSelect("tabla_productos", userData.contribuyente, "").then((data) => setProductos(data));
                }
            })
            .catch((err) => {
                toast.current.show({ severity: "error", summary: "Error", detail: err.message, life: 3000 });
            });
    };

    const deleteProductosDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductosDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProductos} />
        </>
    );

    const cargarTotal = (e) => {
        let combo = e.target.name;
        let impSelect = "";
        let val = { ...values };
        let total = 0;
        let impSelectValor = 0;

        if (combo === "txtPrecio") {
            impSelect = val.cboImpuesto;
            let precioActual = e.target.value;
            if (impSelect === "" || impSelect === undefined || impSelect === 0) {
                impSelectValor = 0;
                total = parseFloat(precioActual);
            } else {
                let isVal = impuestosOptions.find((imp) => imp.id === impSelect);
                impSelectValor = isVal.valor;
                total = parseFloat(precioActual) + (parseFloat(precioActual) * parseFloat(impSelectValor)) / 100;
            }
        } else {
            let idImpuesto = e.target.value;
            let precio = val.txtPrecio;
            impSelect = impuestosOptions.find((imp) => imp.id === idImpuesto);
            impSelectValor = impSelect.valor;
            total = parseFloat(precio) + (parseFloat(precio) * parseFloat(impSelectValor)) / 100;
        }

        //console.log(total)
        val.txtPrecioTotal = total;
        //setValues(val);
        setPrecioTotal(val.txtPrecioTotal);
    };

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={productos}
                        selection={selectedProductos}
                        onSelectionChange={(e) => setSelectedProductos(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} de productos"
                        globalFilter={globalFilter}
                        emptyMessage="No existen Productos."
                        header={header}
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                        <Column field="sTipoProducto" header="Tipo Producto" sortable body={TipoProductoBodyTemplate}></Column>
                        <Column field="txtNombre" header="Nombre" sortable body={nombreBodyTemplate}></Column>
                        <Column field="txtImagen" header="Foto" body={imageBodyTemplate}></Column>
                        <Column field="sUoM" header="UoM" sortable body={UoMBodyTemplate}></Column>
                        <Column field="txtCantidad" header="Cant." sortable body={CantidadBodyTemplate}></Column>
                        <Column field="estatus" header="Estatus" sortable body={estatusBodyTemplate}></Column>
                        <Column field="inventoryStatus" header="" body={inventoryStatusBodyTemplate} sortable></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={ProductoDialog} style={{ width: "50%", outerHeight: "90vh" }} modal header="Detalles del Producto" className="p-fluid" footer={ProductosDialogFooter} onHide={hideDialog} blockScroll>
					    <InputText type="hidden" name="id" value={values.id} onChange={handleInputChange} />
                        <TabView className="tabview-custom">
                            <TabPanel header="Datos Básicos">
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="cboTipoProducto">Tipo de Producto</label>
                                        <Dropdown
                                            id="cboTipoProducto"
                                            name="cboTipoProducto"
                                            value={values.cboTipoProducto}
                                            autoFocus
                                            onChange={handleInputChange}
                                            optionValue="id"
                                            optionLabel="title"
                                            options={tipoProductoOptions}
                                            placeholder="Selecciona uno"
                                            required
                                            className={classNames({ "p-invalid": submitted && !values.cboTipoProducto })}
                                        ></Dropdown>
                                        {submitted && !values.cboTipoProducto && <small className="p-invalid">{errors.cboTipoProducto}</small>}
                                    </div>
                                    {/* </div>
                        <div className="p-formgrid p-grid"> */}
                                    <div className="p-field p-col">
                                        <label htmlFor="txtNombre">Nombre</label>
                                        <InputText name="txtNombre" value={values.txtNombre} onChange={handleInputChange} required className={classNames({ "p-invalid": submitted && !values.txtNombre })} />
                                        {submitted && !values.txtNombre && <small className="p-invalid">{errors.txtNombre}</small>}
                                    </div>
                                </div>

                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-grid p-col-6">
                                        <label htmlFor="swInventariable" className="p-col-fixed">
                                            Producto Inventarible:{" "}
                                        </label>
                                        <div className="p-col">
                                            <InputSwitch id="swInventariable" name="swInventariable" checked={values.swInventariable} onChange={handleInputChange} className={classNames({ "p-invalid": submitted && !values.swInventariable })} />
                                        </div>
                                        {submitted && !values.swInventariable && <small className="p-invalid">{errors.swInventariable}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="cboUoM">UoM</label>
                                        <Dropdown
                                            id="cboUoM"
                                            name="cboUoM"
                                            value={values.cboUoM}
                                            onChange={handleInputChange}
                                            optionValue="id"
                                            optionLabel="title"
                                            options={uoMOptions}
                                            placeholder="Selecciona uno"
                                            required
                                            className={classNames({ "p-invalid": submitted && !values.cboUoM })}
                                        ></Dropdown>
                                        {submitted && !values.cboUoM && <small className="p-invalid">{errors.cboUoM}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="txtCantidad">Cantidad</label>
                                        <InputNumber name="txtCantidad" mode="decimal" minFractionDigits={2} value={values.txtCantidad} onValueChange={handleInputChange} required className={classNames({ "p-invalid": submitted && values.swInventariable && (values.txtCantidad === 0 || values.txtCantidad === "0.00") })} />
                                        {submitted && values.swInventariable && (values.txtCantidad === 0 || values.txtCantidad === "0.00") && <small className="p-invalid">{errors.txtCantidad}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtFotos">Foto - Opcional</label>
                                        <input name="txtFotos" type="file" className="form-control" onChange={(e) => subirArchivos(e.target.files)} />
                                        <label>(Tamaño 60px X 60px)</label>
                                    </div>
                                </div>
                                {/*                         <div visible={false}>
                            <p>Cargando espere por favor...</p>      
                            <ProgressBar mode="indeterminate"/>
                        </div> */}
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtDescripcion">Descripción</label>
                                        <InputTextarea name="txtDescripcion" value={values.txtDescripcion} onChange={handleInputChange} required className={classNames({ "p-invalid": submitted && !values.txtDescripcion })} />
                                        {submitted && !values.txtDescripcion && <small className="p-invalid">{errors.txtDescripcion}</small>}
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Datos para Venta">
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-grid p-col-6">
                                        <label htmlFor="swParaVenta">Disponible para la Venta: </label>
                                        <div className="p-col">
                                            <InputSwitch id="swParaVenta" name="swParaVenta" checked={values.swParaVenta} onChange={handleInputChange} className={classNames({ "p-invalid": submitted && !values.swParaVenta })} />
                                        </div>
                                        {submitted && !values.swParaVenta && <small className="p-invalid">{errors.swParaVenta}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="txtPrecio">Precio sin Impuesto</label>
                                        <InputNumber
                                            name="txtPrecio"
                                            mode="decimal"
                                            minFractionDigits={2}
                                            value={values.txtPrecio}
                                            onValueChange={(e) => {
                                                handleInputChange(e);
                                                cargarTotal(e);
                                            }}
                                            className={classNames({ "p-invalid": submitted && values.swParaVenta && (values.txtPrecio===0 || values.txtPrecio==="0.00") })}
                                        />
										{submitted && values.swParaVenta && (values.txtPrecio===0 || values.txtPrecio==="0.00") && <small className="p-invalid">{errors.txtPrecio}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="cboMoneda">Moneda</label>
                                        <Dropdown id="cboMoneda" name="cboMoneda" value={values.cboMoneda} onChange={handleInputChange} optionValue="id" optionLabel="title" options={MonedaOptions} className={classNames({ "p-invalid": submitted && values.swParaVenta && !values.cboMoneda })}></Dropdown>
										{submitted && values.swParaVenta && !values.cboMoneda && <small className="p-invalid">{errors.cboMoneda}</small>}
                                    </div>
                                </div>
                                <div className="p-formgrid p-grid">
                                    <div className="p-field p-col">
                                        <label htmlFor="cboImpuesto">Impuesto</label>
                                        <Dropdown
                                            id="cboImpuesto"
                                            name="cboImpuesto"
                                            value={values.cboImpuesto}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                cargarTotal(e);
                                            }}
                                            optionValue="id"
                                            optionLabel="title"
                                            options={impuestosOptions}
											className={classNames({ "p-invalid": submitted && values.swParaVenta && !values.cboImpuesto })}
                                        ></Dropdown>
										{submitted && values.swParaVenta && !values.cboImpuesto && <small className="p-invalid">{errors.cboImpuesto}</small>}
                                    </div>
                                    <div className="p-field p-col">
                                        <label htmlFor="txtPrecioTotal">Precio Total de Venta</label>
                                        {/* <InputText name="txtPrecioTotal" value={values.txtPrecioTotal} onChange={handleInputChange} disabled className={classNames({ 'p-invalid': submitted && !values.txtPrecioTotal })} /> */}
                                        <InputNumber name="txtPrecioTotal" mode="decimal" minFractionDigits={2} value={precioTotal} onChange={handleInputChange} disabled />
                                    </div>
                                </div>
                            </TabPanel>
                        </TabView>
                        {/* <div className="p-formgrid p-grid">
							<div className="p-field p-col">
							&nbsp;
							</div>
						</div> */}
                    </Dialog>

                    <Dialog visible={UptEstatusProductoDialog} style={{ width: "450px" }} header="Confirm" modal footer={updEstatusProductoDialogFooter} onHide={hideUpdEstatusProductosDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                            {producto && (
                                <span>
                                    Esta seguro de cambiarle el Estatus a <b>{producto.txtNombre}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductosDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductosDialogFooter} onHide={hideDeleteProductosDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                            {producto && <span>Esta Seguro de Eliminar el producto seleccionado?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
			<div className="p-col-12">
                <div>
                    <ShowLoading data={loadingDialog} />
                </div>
            </div>
        </div>
    );
}
