import React from "react";
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';

export default function ShowLoading(props) {
	let visible = props.data;
    return (
        <Dialog 
            visible={visible} 
            contentStyle={{ color: "#FFFFFF", backgroundColor: "transparent"}} 
            style={{boxShadow: "none", border: "none", width: '300px', textAlign: "center" }} 
            blockScroll={true} 
            focusOnShow={false} 
            showHeader={false} 
            closable={false} 
            closeOnEscape={false}
            onHide={() => {if (!visible) return; visible = false; }}
        >
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-12">
                    <h3>Procesando...</h3>
                    <ProgressSpinner />
                </div>
            </div>
        </Dialog>
    );
}
